import { RouteRecordRaw } from 'vue-router'
import OrdersView from '../views/OrdersView.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/orders',
    name: 'OrdersView',
    component: OrdersView,
    meta: {
      requiresAuth: true,
      inAuthMenu: true,
      module: 'orders',
      icon: 'mdi-receipt-text'
    }
  }
]