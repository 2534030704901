<script setup lang="ts">

</script>

<template>
  <v-btn
    icon
    flat
  >
    <v-icon>
      mdi-close
    </v-icon>
  </v-btn>
</template>

<style scoped lang="scss">

</style>