<template>
  <v-form
    v-model="valid"
    class="pa-5"
    ref="retrieveAccountForm"
  >
    <p class="accountEmail">
      {{ $t('signInForm.retrieveAccount.emailSent') }}<br/>
      <span>{{ $store.getters.getRetrieveAccountEmail }}</span>
    </p>
    <p>
      {{ $t('signInForm.retrieveAccount.fillTheToken') }}
    </p>
    <CTextField
      :label="$t('signInForm.retrieveAccount.token.label')"
      :rules="rules.token"
      maxlength="6"
      required
      @onFill="(v) => updateFormData('token', v)"
    />

    <CTextFieldPassword
      :label="$t('signInForm.retrieveAccount.password.label')"
      :rules="rules.password"
      maxlength="255"
      required
      @onFill="(v) => updateFormData('password', v)"
    />

    <v-btn-group
      class="mt-2"
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :label="$t('signInForm.forgotPassword.actions.submit')"
        :enabled="valid"
        :loading="loading"
        @click.prevent="submit"
      />
      <CFormCancelButton
        :label="$t('signInForm.retrieveAccount.actions.cancel')"
        @cancel="$emit('toggleDisplay', false)"
      />
    </v-btn-group>
  </v-form>
</template>

<script>
import CTextField from '@/components/fields/CTextField.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CTextFieldPassword from "@/components/fields/CTextFieldPassword.vue";

export default {
  name: 'RetrieveAccountForm',
  components: { CTextFieldPassword, CButtonFormSubmit, CFormCancelButton, CTextField },
  data () {
    return {
      valid: false,
      loading: false,
      formValues: {
        token: '',
        password: ''
      },
      rules: {
        token: [
          (v) => !!v || this.$t('signInForm.retrieveAccount.token.rules.mandatory')
        ],
        password: [
          (v) => !!v || this.$t('signInForm.retrieveAccount.password.rules.mandatory')
        ]
      }
    }
  },
  computed : {
    retrieveAccountEmail () {
      return this.$store.getters.getRetrieveAccountEmail
    }
  },
  methods: {
    updateFormData (field, value) {
      this.formValues[field] = value
    },
    async submit () {
      this.loading = true

      this.$http.post('/auth/forgot-password/retrieve-account', {
        ...this.formValues,
        ...{ email: this.retrieveAccountEmail }
      })
          .then(response => {
            this.$toast.success(response.data.data)

            this.$store.dispatch('retrieveToken', {
              email: this.retrieveAccountEmail,
              password: this.formValues.password
            })
              .then(() => {
                this.$emit('toggleDisplay', false)
                this.$router.push({ name: 'AffiliateView' })
              })
              .catch(error => {
                this.$toast.error(error.response.data.message)
              })
              .finally(() => { this.loading = false })
          })
          .catch(error => {
              this.$toast.error(error.response.data.message)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.accountEmail {
  line-height: 28px;

  > span {
    font-weight: bold;
  }
}
</style>