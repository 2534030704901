<template>
  <v-container
    class="ct pa-3"
  >
    <v-row
      class=""
    >
      <v-col
        class="pr-0 pb-0 pb-sm-3"
        cols="12"
        sm="3"
      >
        <v-tabs
          v-model="tabs.tab"
          class=""
          color="primary"
          direction="vertical"
        >
          <v-tab
            v-for="(item, index) in tabs.items"
            class=""
            :key="index"
            :value="item.value"
            @click="updateTab(item.value)"
          >
            {{ item.label }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-window
          v-model="tabs.tab"
        >
          <v-window-item
            v-for="(item, i) in tabs.items"
            :key="'w-' + i"
            class="pa-0"
            :value="item.value"
            transition="false"
          >
            <component :is="item.component"/>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import AccountsAdminTab from '@/modules/settings/components/AccountsAdminTab.vue'
import AffiliatesTab from '@/modules/settings/components/AffiliatesTab.vue'
import BankTab from '@/modules/settings/components/BankTab.vue'
import BookingTab from '@/modules/settings/components/BookingTab.vue'
import CreditCardTab from '@/modules/settings/components/CreditCardTab.vue'
import UserProfileTab from '@/modules/settings/components/UserProfileTab.vue'

import roles from '@/helpers/roles'
import subscription from "@/helpers/subscription";

export default {
  name: 'SettingsView',
  components: { BankTab, BookingTab, AccountsAdminTab, UserProfileTab, AffiliatesTab, CreditCardTab },
  data () {
    return {
      tabs: {
        tab: 'profile',
        items: []
      }
    }
  },
  watch: {
    "$route.query.tab": function (newValue) {
      this.tabs.tab = newValue
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
  },
  beforeMount () {
    this.generateTabs(this.user.role.id)

    const tab = this.$route.query.tab

    if (tab && this.tabs.items.find(x => x.value === tab)) {
      this.tabs.tab = tab
    } else {
      this.updateTab('profile')
    }
  },
  methods: {
    async updateTab (tab) {
      await this.$router.push({ query: { tab } })
    },
    generateTabs (roleId) {
      const tabs = [
        {
          label: this.$t('settings.tabs.userProfile'),
          value: 'profile',
          role: null,
          component: 'UserProfileTab'
        },
        {
          label: this.$t('settings.tabs.userAccounts'),
          value: 'accounts',
          role: 'administrator',
          component: 'AccountsAdminTab'
        },
        {
          label: this.$t('settings.tabs.bank'),
          value: 'bank',
          component: 'BankTab',
          subscription: 'affiliate'
        },
        {
          label: this.$t('settings.tabs.card'),
          value: 'card',
          component: 'CreditCardTab'
        },
        {
          label: this.$t('settings.tabs.booking'),
          value: 'booking',
          role: 'creator',
          component: 'BookingTab'
        },
        /*
        {
          label: this.$t('settings.tabs.affiliates'),
          value: 'affiliates',
          role: 'affiliate',
          component: 'AffiliatesTab'
        }
        */
      ]

      for (let i = 0; i < tabs.length; i++) {
        if (!tabs[i].role || roles.hasAccess(roleId, tabs[i].role)) {
          this.tabs.items.push(tabs[i])
        }
      }
    },
    hasAccess (userRoleId, featureRole) {
      return roles.hasAccess(userRoleId, featureRole)
    }
  }
}
</script>

<style lang="scss" scoped>
.ct {
  max-width: 960px;
}

.v-tab {
  justify-content: left;
  text-transform: none;
}

</style>