<script setup>
import { ref, inject, defineEmits } from 'vue'
import CTextField from '@/components/fields/CTextField.vue'
import i18n from '@/i18n'
import CSelectField from '@/components/fields/CSelectField.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'

const http = inject('axios')
const toast = inject('toast')

const rules = {
  email: [
    (v) => !!v || i18n.global.t('settings.userAccounts.inviteUserForm.email.rules.mandatory'),
    (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.global.t('settings.userAccounts.inviteUserForm.email.rules.valid')
  ],
  role: [
    (v) => !!v || i18n.global.t('settings.userAccounts.inviteUserForm.role.rules.mandatory')
  ]
}

let valid = ref(false)
let loading = ref(false)
let values = ref({
  email: null,
  role: null
})
const roleItems = [
  {
    text: 'Affiliate',
    value: 'affiliate'
  },
  {
    text: 'Creator',
    value: 'creator'
  }
]

const emit = defineEmits(['afterSubmit'])

const submit = async () => {
  loading.value = true

  await http.post('/user-invite/invite', values.value)
    .then(() => {
      toast.success(i18n.global.t('settings.userAccounts.inviteUserForm.success'))
      emit('afterSubmit')
    })
    .catch(error => {
      toast.error(error.response.data.message)
      emit('afterSubmit')
    })
    .finally(() => { loading.value = false })
}
</script>

<template>
  <v-form
    v-model="valid"
    class=""
  >
    <CTextField
      v-model="values.email"
      class="pb-2"
      :label="i18n.global.t('settings.userAccounts.inviteUserForm.email.label')"
      :rules="rules.email"
      required
    />
    <CSelectField
      v-model="values.role"
      class="mb-2"
      :items="roleItems"
      :label="i18n.global.t('settings.userAccounts.inviteUserForm.role.label')"
      :rules="rules.role"
      required
    />
    <CButtonFormSubmit
      :enabled="valid"
      :loading="loading"
      :label="i18n.global.t('settings.userAccounts.inviteUserForm.actions.submit')"
      size="large"
      @click.prevent="submit"
    />
  </v-form>
</template>

<style scoped>

</style>