import { Router, RouteRecordRaw } from 'vue-router'
import { ModuleInterface } from '@/modules/core'


class ModuleRegistrar {
  #router: Router

  constructor (router: Router) {
    this.#router = router
  }

  register (module: ModuleInterface) {
    return module
  }

  addRoutes (routes: Array<RouteRecordRaw>) {
    for (const route in routes) {
      this.#router.addRoute(routes[route])
    }
  }

  getRoutes () {
    return this.#router.getRoutes()
  }
}

export { ModuleRegistrar }