<template>
  <v-form
    v-model="valid"
    class="pa-5"
  >
    <CTextFieldPassword
      class="py-2"
      :label="$t('settings.userProfile.changePasswordForm.currentPassword.label')"
      :rules="rules.currentPassword"
      maxlength="255"
      required
      @onFill="(v) => updateFormData('current_password', v)"
    />

    <CTextFieldPassword
      class="py-2"
      :label="$t('settings.userProfile.changePasswordForm.newPassword.label')"
      :rules="rules.newPassword"
      maxlength="255"
      required
      @onFill="(v) => updateFormData('new_password', v)"
    />

    <v-btn-group
      class="mt-5"
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :enabled="valid"
        :loading="loading"
        :label="$t('settings.userProfile.changePasswordForm.actions.submit')"
        @click.prevent="submit"
      />
      <CFormCancelButton
        :label="$t('settings.userProfile.changePasswordForm.actions.cancel')"
        @cancel="$emit('closeModal')"
      />
    </v-btn-group>
  </v-form>
</template>

<script>
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import CTextFieldPassword from '@/components/fields/CTextFieldPassword.vue'
import CButtonFormSubmit from "@/components/buttons/CButtonFormSubmit.vue";

import requestResponses from '@/helpers/requestResponses'

export default {
  name: 'ChangePasswordForm',
  components: { CButtonFormSubmit, CTextFieldPassword, CFormCancelButton },
  data () {
    return {
      valid: false,
      loading: false,
      formValues: {
        current_password: '',
        new_password: ''
      },
      rules: {
        currentPassword: [
          (v) => !!v || this.$t('settings.userProfile.changePasswordForm.currentPassword.rules.mandatory'),
        ],
        newPassword: [
          (v) => !!v || this.$t('settings.userProfile.changePasswordForm.currentPassword.rules.mandatory'),
        ]
      }
    }
  },
  methods: {
    async submit () {
      this.loading = true

      this.$http.post('/user/change-password', this.formValues)
          .then(response => {
            this.$toast.success(response.data.data)
            this.$emit('closeModal')
          })
          .catch(error => {
            this.$toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
          })
          .finally(() => { this.loading = false })
    },
    updateFormData (field, value) {
      this.formValues[field] = value
    }
  }
}
</script>

<style scoped>

</style>