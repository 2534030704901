<script setup lang="ts">
import { ref, computed, inject, onMounted } from 'vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import * as process from 'process'
import requestResponses from '@/helpers/requestResponses'
import AffiliateCustomersList from '@/modules/affiliates/components/AffiliateCustomersList.vue'
import TransferList from '@/components/transfer/TransferList.vue'

const http = inject('axios')
const toast = inject('toast')

onMounted(async () => {
  await fetchStatistics()
})

const user = computed(() => {
  return store.getters.getUser
})
const affiliateLink = computed(() => {
  return process.env.VUE_APP_URL + "/discount/" + user.value.affiliate_token.token
})
const discordInvite = computed(() => {
  return user.value.discord_invite?.url ?? false
})

const copy = (text) => {
  navigator.clipboard.writeText(text)
  toast.info(i18n.global.t('settings.affiliates.links.messages.copied'))
}

const loadingStatistics = ref(false)
const statistics = ref(null)
const fetchStatistics = async () => {
  loadingStatistics.value = true

  await http.get('/affiliate/statistic')
    .then(response => {
      statistics.value = response.data.data
    })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
    .finally(() => { loadingStatistics.value = false })

}

const tab = ref('customers')
const updateTab = (t) => {
  tab.value = t
}
</script>

<template>
  <v-container
    class="wrapper px-5"
  >
    <div
      class="header mb-2"
    >
      <h1 class="mb-2">{{ i18n.global.t('affiliates.header.title') }}</h1>
      <div>
        <p class="mb-0">{{ i18n.global.t('affiliates.content.affiliateLink.title') }}</p>
        <span class="af-link" @click.prevent="copy(affiliateLink)">{{ affiliateLink }}</span>
      </div>
    </div>
    <v-alert
      v-if="!user.bank || !user.paypal_account"
      class="my-2 d-none"
      type="info"
    >
      <p>{{ i18n.global.t('_globals.alerts.bank.unset') }}</p>
      <span
        class="link"
        @click.prevent="router.push({ name: 'SettingsView', query: { tab: 'bank' } })"
      >
        {{ i18n.global.t('_globals.alerts.bank.link') }}
      </span>
    </v-alert>
    <v-row
      class="my-0"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title>{{ i18n.global.t('affiliates.customers.title') }}</v-card-title>
          <div v-if="loadingStatistics" class="loading"><v-progress-circular class="align-self-center justify-center" indeterminate /></div>
          <div
            v-if="statistics && statistics.customers != null"
            class="number"
          >
            <span>{{ statistics.customers }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title>{{ i18n.global.t('affiliates.totalRevenues') }}</v-card-title>
          <div v-if="loadingStatistics" class="loading"><v-progress-circular class="align-self-center justify-center" indeterminate /></div>
          <div
            v-if="statistics && statistics.revenues != null"
            class="number"
          >
            <span>{{ statistics.revenues }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title>{{ i18n.global.t('affiliates.pendingPayouts') }}</v-card-title>
          <div v-if="loadingStatistics" class="loading"><v-progress-circular class="align-self-center justify-center" indeterminate /></div>
          <div
            v-if="statistics && statistics.pending != null"
            class="number"
          >
            <span>{{ statistics.pending }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title>{{ i18n.global.t('affiliates.nextPayout.title') }}</v-card-title>
          <div v-if="loadingStatistics" class="loading"><v-progress-circular class="align-self-center justify-center" indeterminate /></div>
          <div
            v-if="statistics && statistics.payout != null"
            class="number"
          >
            <span>{{ i18n.global.t('affiliates.nextPayout.payout', { time: statistics.payout }) }}</span>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="my-0"
    >
      <v-col
        cols="12"
      >
        <v-card
          class="screen"
        >
          <v-tabs
            class="ma-0"
          >
            <v-tab
              value="customers"
              @click="updateTab('customers')"
            >
              {{ i18n.global.t('affiliates.customers.title') }}
            </v-tab>
            <v-tab
              value="sells"
              @click="updateTab('sells')"
            >
              {{ i18n.global.t('affiliates.sells') }}
            </v-tab>
          </v-tabs>
          <div>
            <v-window
              v-model="tab"
            >
              <v-window-item
                value="customers"
                transition="false"
              >
                <TransferList/>
              </v-window-item>
              <v-window-item
                value="sells"
                transition="false"
              >
                <TransferList/>
              </v-window-item>
            </v-window>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
@import "@/style/breakpoints.scss";

.wrapper {
  height: 75vh;

  @media (max-width: $breakpoint-xs-down) {
    height: inherit;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: $breakpoint-sm-down) {
    flex-direction: column;
    align-items: start;
  }

  .l {
    line-height: 12px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0;
    }

    span {
      font-size: 13px;
    }
  }
}

.screen {
  width: 100%;
  height: 100%;
}

.af-link {
  font-size: 18px;
  font-family: "Tilt Warp", sans-serif;
  word-break: break-word;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.loading {
  width: 100%;
  text-align: center;
  margin: 5px 0 18px 0;
}

.number {
  width: 100%;
  font-size: 25px;
  text-align: center;
  margin: 0 0 17px 0;
}
</style>