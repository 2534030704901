import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import Store from '@/store'
import router from '@/router'
import lang from '@/helpers/lang'

export default function (baseUrl: string) {
    const api = axios.create({
      baseURL: baseUrl,
      timeout: 30000,
      headers: {
        common: {
          'Accept': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Accept-Language': lang.getCurrentLanguage()
        }
      }
    })

  api.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
    const token = localStorage.getItem('accessToken') || null

    if(token && config.headers) {
        config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  }, function (error) {
    return Promise.reject(error)
  })

  api.interceptors.response.use(function (response: AxiosResponse) {
    return response
  }, function (error) {
    // TODO : Find a way to kick out the user has been unauthenticated when the request is made. This solution kicks the users out on every 500
    const toggle = false
    if (error.response.status === 500 && toggle) {
      Store.dispatch('destroyToken')
        .then(() => {
          router.push({
            name: 'HomePage',
          })
        })
    } else {
      throw error
    }
  })

  return api
}