<script setup lang="ts">
import { ref, inject, onMounted } from 'vue'
import requestResponses from '@/helpers/requestResponses'
import currency from '@/helpers/currency'
import store from '@/store'
import timeFormats from '@/helpers/timeFormats'
import i18n from '@/i18n'

const http = inject('axios')
const toast = inject('toast')

onMounted(async () => {
  await fetchTransfers()
})

const loading = ref(true)
const transfers = ref(null)

const fetchTransfers = async () => {
  loading.value = true
  await http.get('/transfer', {
    params: {
      orderBy: [ 'created_at', 'DESC' ]
    }
  })
    .then(response => {
      transfers.value = response.data.data
    })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
    .finally(() => { loading.value = false })
}

const statusIcon = (status) => {
  switch (status) {
    case 'succeeded' : return { icon: 'mdi-check-decagram', text: i18n.global.t('transfers.table.body.statuses.success'), color: 'green-accent-3' }
    case 'pending' : return { icon: 'mdi-receipt-text-clock', text: i18n.global.t('transfers.table.body.statuses.pending'), color: 'white' }
    case 'transferring' : return { icon: 'mdi-swap-horizontal', text: i18n.global.t('transfers.table.body.statuses.transferring'), color: 'blue' }
    default : return { icon: 'mdi-close-box', text: i18n.global.t('transfers.table.body.statuses.failed'), color: 'pink-accent-3' }
  }
}
</script>

<template>
  <v-container
    fluid
  >
    <v-table
      v-if="transfers && transfers.length"
    >
      <thead>
      <tr>
        <th>{{ i18n.global.t('transfers.table.head.date') }}</th>
        <th>{{ i18n.global.t('transfers.table.head.amount') }}</th>
        <th>{{ i18n.global.t('transfers.table.head.status') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(item, i) in transfers"
        :key="'tr-' + i"
      >
        <td>{{ timeFormats.formatDate(item.date) }}</td>
        <td>{{ currency.formatAmount(parseFloat(item.amount), store.getters.checkoutProcessCurrency) }}</td>
        <td>
          <v-icon
            :color="statusIcon(item.status).color"
          >
            {{ statusIcon(item.status).icon }}
          </v-icon>
          <span
            class="status-text"
          >
            {{ statusIcon(item.status).text }}
          </span>
        </td>
      </tr>
      </tbody>
    </v-table>
    <div
      v-else-if="loading"
    >
      <v-skeleton-loader
        type="table-row@2"
      />
    </div>
    <div
      v-else
    >
      {{ i18n.global.t('transfers.noData') }}
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.status-text {
  font-size: 15px;
  margin-left: 7px;
}
</style>