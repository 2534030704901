<script setup lang="ts">
import { ref, inject, computed } from 'vue'
import i18n from '@/i18n'
import store from '@/store'
import PodcastInvitationList from "@/modules/settings/components/AffiliatesTab/PodcastInvitationList.vue";
import timeFormats from '@/helpers/timeFormats'

const toast = inject('toast')

const user = computed(() => {
  return store.getters.getUser
})

const due = ref(0)
const updateDue = (v) => {
  due.value = v
}

const copy = (text) => {
  navigator.clipboard.writeText(text)
  toast.info(i18n.global.t('settings.affiliates.links.messages.copied'))
}

const nextPayment = computed(() => {
  const date = new Date()
  let next = new Date(date);
  let cache = next.getDate(date);
  next.setDate(15);
  if (cache >= 15) {
    next.setMonth(date.getMonth() + 1);
  }
  next.setHours(0, 0, 0, 0);

  return timeFormats.formatDate(next.toString())
})
</script>

<template>
  <v-container>
    <h1 class="pb-5">{{ i18n.global.t('settings.affiliates.title') }}</h1>
    <p class="mb-10">{{ i18n.global.t('settings.affiliates.description') }}</p>

    <h2>{{ i18n.global.t('settings.affiliates.links.title') }}</h2>

    <ul
      class="links"
    >
      <li>
        <span>{{ i18n.global.t('settings.affiliates.links.podcast.title') }}</span>
        <span class="url">{{ i18n.global.t('settings.affiliates.links.podcast.link', { token: user.affiliate_token.token }) }}</span>
        <v-btn
          class="mt-3 pl-3"
          size="small"
          @click="copy(i18n.global.t('settings.affiliates.links.podcast.link', { token: user.affiliate_token.token }))"
        >
          <v-icon
            class="mr-2"
            size="small"
          >
            mdi-content-copy
          </v-icon>
          {{ i18n.global.t('settings.affiliates.links.actions.copy') }}
        </v-btn>
      </li>
    </ul>

    <h2>{{ i18n.global.t('settings.affiliates.podcast.title') }}</h2>

    <div
      class="payment"
    >
      <span>
        {{ i18n.global.t('settings.affiliates.podcast.paymentDate', { date: nextPayment }) }}
      </span>
      <span>
        {{ i18n.global.t('settings.affiliates.podcast.due', { due }) }}
      </span>
    </div>

    <PodcastInvitationList
      @onLoaded="(v) => { updateDue(v) }"
    />
  </v-container>
</template>

<style scoped lang="scss">
  .links {
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 40px;

      span {
        display: block;
        line-height: 26px;

        &.url {
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }

  .payment {
    display: block;
    margin-bottom: 22px;

    span {
      display: block;
      font-size: 18px;
    }
  }
</style>