<script setup lang="ts">
import {computed, onMounted} from 'vue'

onMounted(() => {
  window.scrollTo(0, 0)
})

const email = process.env.VUE_APP_CONTACT_EMAIL

const lang = computed(() => {
  return 'fr'
})
</script>

<template>
  <v-container
    class="wrapper"
  >
    <div
      v-if="lang == 'fr'"
    >
      <h1>Conditions Générales d'Utilisation de Sunset Discounts</h1>

      <p>Ces <strong>Conditions Générales</strong> ("Conditions") régissent votre utilisation de l'application de coaching "Sunset Discounts" (l'"Application") et des services qu'elle propose. En utilisant l'Application, vous acceptez de vous conformer à ces Conditions. Veuillez les lire attentivement.</p>

      <h2>1. Acceptation des Conditions</h2>

      <p>En accédant ou en utilisant l'Application, vous reconnaissez avoir lu, compris et accepté d'être lié par ces Conditions. Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser l'Application.</p>

      <h2>2. Éligibilité</h2>

      <p>Vous devez avoir au moins 18 ans ou obtenir le consentement d'un tuteur légal pour utiliser cette Application. Vous déclarez également et garantissez ne pas être interdit d'utilisation de l'Application en vertu de toute loi applicable.</p>

      <h2>3. Modalités de Paiement</h2>

      <h3>3.1 Produits Basés sur un Abonnement</h3>
      <ul>
        <li>Sunset Discounts propose des produits basés sur un abonnement qui offrent un accès à du contenu premium et à des fonctionnalités. Vous serez facturé(e) selon les frais d'abonnement spécifiés de manière récurrente.</li>
        <li>Vous pouvez annuler votre abonnement à tout moment. L'annulation prendra effet à la fin de la période de facturation en cours.</li>
      </ul>

      <h3>3.2 Produits à Paiement Unique</h3>
      <ul>
        <li>Sunset Discounts peut proposer des produits à paiement unique offrant un accès à un contenu ou à des fonctionnalités spécifiques.</li>
        <li>Les produits à paiement unique ne sont pas remboursables. Veuillez vous assurer d'être satisfait(e) de la description du produit avant d'effectuer un achat.</li>
      </ul>

      <h2>4. Comportement de l'Utilisateur</h2>

      <h3>4.1 Activités Interdites</h3>
      <ul>
        <li>Vous acceptez de ne pas vous engager dans l'une des activités suivantes lors de l'utilisation de l'Application :</li>
        <li>
          <ol>
            <li>Violation de lois ou réglementations applicables.</li>
            <li>Publication, transmission ou distribution de contenu nuisible, offensant ou portant atteinte aux droits d'autrui.</li>
            <li>Tentative d'accès non autorisé à l'Application ou à ses systèmes.</li>
          </ol>
        </li>
      </ul>

      <h3>4.2 Utilisation du Contenu</h3>
      <p>Tout contenu accessible via l'Application est destiné à un usage personnel et ne peut être distribué, reproduit ou partagé sans le consentement écrit préalable de Sunset Discounts.</p>

      <h2>5. Résiliation</h2>
      <p>Sunset Discounts se réserve le droit de résilier ou de suspendre votre compte et l'accès à l'Application à notre discrétion, avec ou sans préavis, en cas de violation de ces Conditions ou pour toute autre raison.</p>

      <h2>6. Avertissement</h2>
      <p>L'Application fournit des conseils et du contenu informatif, mais nous ne garantissons pas de résultats ou de performances. Les informations fournies sont destinées à des conseils généraux seulement.</p>

      <h2>7. Confidentialité</h2>
      <p>Votre utilisation de l'Application est également régie par notre Politique de Confidentialité, qui est intégrée par référence dans ces Conditions.</p>

      <h2>8. Modifications des Conditions</h2>
      <p>Sunset Discounts peut mettre à jour ces Conditions à tout moment. Nous vous informerons de toute modification en publiant les Conditions révisées sur l'Application. La poursuite de l'utilisation de l'Application après de telles modifications constitue votre acceptation des Conditions mises à jour.</p>

      <h2>9. Contactez-nous</h2>
      <p>Si vous avez des questions ou des préoccupations concernant ces Conditions, veuillez nous contacter à <a :href="'mailto:' + email">{{ email }}</a>.</p>
    </div>
    <div
      v-else
    >
      <h1>Terms and Conditions for Sunset Discounts</h1>

      <p>These <strong>Terms and Conditions</strong> ("Terms") govern your use of the "Sunset Discounts" coaching app (the "App") and the services provided by it. By using the App, you agree to abide by these Terms. Please read them carefully.</p>

      <h2>1. Acceptance of Terms</h2>

      <p>By accessing or using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.</p>

      <h2>2. Eligibility</h2>

      <p>You must be at least 18 years old or have the consent of a legal guardian to use this App. You also represent and warrant that you are not barred from using the App under any applicable law.</p>

      <h2>3. Payment Terms</h2>

      <h3>3.1 Subscription-Based Products</h3>
      <ul>
        <li>Sunset Discounts offers subscription-based products that provide access to premium content and features. You will be charged the specified subscription fee on a recurring basis.</li>
        <li>You may cancel your subscription at any time. The cancellation will take effect at the end of the current billing period.</li>
      </ul>

      <h3>3.2 One-Time Payment Products</h3>
      <ul>
        <li>Sunset Discounts may offer one-time payment products that provide access to specific content or features.</li>
        <li>One-time payment products are not refundable. Please ensure you are satisfied with the product's description before making a purchase.</li>
      </ul>

      <h2>4. User Conduct</h2>

      <h3>4.1 Prohibited Activities</h3>
      <ul>
        <li>You agree not to engage in any of the following activities while using the App:</li>
        <li>
          <ol>
            <li>Violating any applicable laws or regulations.</li>
            <li>Posting, transmitting, or distributing content that is harmful, offensive, or infringing on the rights of others.</li>
            <li>Attempting to gain unauthorized access to the App or its systems.</li>
          </ol>
        </li>
      </ul>

      <h3>4.2 Content Usage</h3>
      <p>Any content accessed through the App is for personal use and may not be distributed, reproduced, or shared without Sunset Discounts's prior written consent.</p>

      <h2>5. Termination</h2>
      <p>Sunset Discounts reserves the right to terminate or suspend your account and access to the App at our discretion, with or without notice, for any violation of these Terms or for any other reason.</p>

      <h2>6. Disclaimer</h2>
      <p>The App provides coaching and informational content, but we do not guarantee results or outcomes. The information provided is for general guidance only.</p>

      <h2>7. Privacy</h2>
      <p>Your use of the App is also governed by our Privacy Policy, which is incorporated by reference into these Terms.</p>

      <h2>8. Changes to Terms</h2>
      <p>Sunset Discounts may update these Terms at any time. We will notify you of any changes by posting the revised Terms on the App. Continued use of the App after such changes constitutes your acceptance of the updated Terms.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions or concerns regarding these Terms, please contact us at <a :href="'mailto:' + email">{{ email }}</a>.</p>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

h1, h2, h3 {
  padding-bottom: 15px;
}

h3 {
  font-size: 22px;
  margin: 0;
}

p, em, li {
  color: $lighter-text;
}

ul {
  padding-left: 30px;
  padding-bottom: 15px;
}

ol {
  padding-left: 15px;
  padding-bottom: 15px;
}

li {
  padding-bottom: 2px;
}
</style>