<script setup lang="ts">
import { onMounted, watch } from 'vue'
import router from '@/router'
import Store from '@/store'

onMounted(() => {
  watch(
    () => router.currentRoute.value.params.token,
    (token) => {
      if (token) {
        inviteCheck(token);
      }
    },
    { immediate: true } // Trigger immediately if the value is already available
  );
});

const imageUrl = process.env.VUE_APP_IMAGES_URL

const setRedirectViewName = (label) => {
  switch (label) {
    default : return 'HomePage'
  }
}

const inviteCheck = (token) => {

  Store.dispatch('setDiscount', token)
    .then(response => {
      const redirectLabel = response.data.data.redirect

      redirect(setRedirectViewName(redirectLabel))
    })
    .catch(error => {
      console.log(error)
      redirect('HomePage')
    })
}

const redirect = (routeName) => {
  router.push({ name: routeName })
}
</script>

<template>
  <v-progress-linear color="primary" indeterminate />

  <div
    class="d-flex fill-height align-center justify-center"
  >
    <img :src="imageUrl + '/lb-logo-bw.png'" width="100" >
  </div>
</template>

<style scoped lang="scss">

</style>