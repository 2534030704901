<template>
  <v-text-field
    v-model="value"
    :rules="rules"
    :maxlength="maxlength"
    variant="outlined"
    :readonly="readonly"
    :disabled="disabled"
    :error="error"
    @keyup="parseValue"
  >
    <template #label>
      {{ label }}&nbsp;<span v-if="required" class="red--text"><strong>*</strong></span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'CTextField',
  props: {
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Array,
      required: false
    },
    maxlength: {
      type: String,
      default: '255'
    },
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    required: {
      type: Boolean,
      default: false
    },
    fillValue: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: ''
    }
  },
  mounted() {
    if (this.fillValue) {
      this.value = this.fillValue
    }
  },
  methods: {
    parseValue () {
      this.$emit('onFill', this.value)
    }
  }
}
</script>

<style scoped>

</style>