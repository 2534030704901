export default {
  "_globals": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "noVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video non available"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to homepage"])},
    "actions": {
      "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Cart"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])}
    },
    "alerts": {
      "bank": {
        "unset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bank account is not set. You need to setup your bank account to receive transfers related to your sales."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup my bank account"])}
      }
    },
    "statuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])}
    }
  },
  "discounts": {
    "tenPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-10% pending"])}
  },
  "inclusions": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round trip transportation"])},
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required safety equipment"])},
    "equipment-snorkeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snorkeling equipment"])},
    "equipment-skydiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skydiving equipment"])},
    "equipment-boating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boating equipment"])},
    "equipment-wakeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wakeboard equipment"])},
    "landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beautiful landscapes"])},
    "landscape-beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beaches"])},
    "landscape-beach-cave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beaches and caves"])},
    "landscape-beach-cave-farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beach, cave, and farm"])},
    "landscape-natural-swimming-pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural swimming pool"])},
    "landscape-saona-island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saona Island landscape"])},
    "food-buffet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffet (Barbecue, salad and typical Dominican dishes)"])},
    "food-organic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffet of organic local foods"])},
    "food-open-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beer, rum and soft drinks (open bar)"])},
    "cinema-4d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4D Cinema presentation"])},
    "monuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monuments, museum and architecture"])},
    "recommend-wheelchair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not wheelchair accessible"])},
    "recommend-back-pain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not recommended for travelers with back problems"])},
    "recommend-pregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not recommended for pregnant travelers"])},
    "recommend-heart-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No heart problems or other serious medical conditions"])},
    "fitness-moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travelers must have a moderate fitness level"])}
  },
  "durations": {
    "hour-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour"])},
    "hour-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 hours"])},
    "hour-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 hours"])},
    "hour-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 hours"])},
    "hour-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 hours"])},
    "hour-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 hours"])},
    "hour-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 hours"])},
    "hour-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 hours"])},
    "hour-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9 hours"])},
    "hour-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 hours"])},
    "hour-range-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 3 hours"])},
    "hour-range-3-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 5 hours"])},
    "hour-range-5-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 to 8 hours"])},
    "hour-range-8-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 to 12 hours"])}
  },
  "home": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions & tours at unbeatable prices"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore unforgettable adventures in the Dominican Republic, crafted just for you, with unbeatable prices, trusted service, and memories that last a lifetime."])},
      "go": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])}
      }
    },
    "what": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We take care of everything"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At every step, we ensure a seamless experience. From booking to your return, our team handles the details, so you can relax and enjoy. With trusted partners, transparent pricing, and exceptional service, your satisfaction is our priority. Let us take care of everything while you focus on making memories."])},
      "incentives": {
        "prices": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Price Guaranteed"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We ensure unmatched value with transparent pricing, trusted partners, and the best deals available."])}
        },
        "peace": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peace of mind"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax and enjoy knowing we handle every detail with care, ensuring a worry-free experience."])}
        },
        "team": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportive team"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our dedicated team is here for you, providing guidance and assistance every step of the way."])}
        }
      }
    },
    "where": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exciting experiences across the whole island"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Punta Cana to Las Terrenas, explore stunning landscapes, vibrant culture, and unforgettable adventures crafted just for you."])},
      "destinations": {
        "punta": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break free from resorts and explore unbelievable places"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punta Cana"])}
        },
        "terrenas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the most stunning landscape of the whole island"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Terrenas"])}
        },
        "santo": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive from fast pace streets to beautiful beaches"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santo Domingo"])}
        },
        "sosua": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get lost in the most mesmerizing nature the island has to offer"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosua (soon)"])}
        }
      }
    },
    "why": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why book with Sunset Discounts?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunset Discounts offers unbeatable deals on exciting excursions and tours, ensuring customers get the most value for their money. With a seamless booking process and exceptional service, we make every adventure stress-free, memorable, and affordable."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    }
  },
  "filters": {
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters available soon"])},
    "destination": {
      "punta-cana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Punta Cana"])},
      "las-terrenas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Las Terrenas"])},
      "santo-domingo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Santo Domingo"])}
    },
    "categories": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "options": {
        "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trails"])},
        "beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beach"])},
        "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
        "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night Life"])}
      }
    },
    "languages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "options": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
        "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])}
      }
    },
    "duration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "options": {
        "up-to-1h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 1 hour"])},
        "1h-to-4h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 4 hours"])},
        "4h-to-1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 hours to 1 day"])},
        "1d-and-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 day and +"])}
      }
    },
    "price": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Range"])}
    }
  },
  "affiliates": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliation Module"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])}
    },
    "content": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links & Documents"])},
      "affiliateLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your Affiliate Link to generate sells"])}
      },
      "discord": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Channel"])}
      }
    },
    "customers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clients"])},
      "list": {
        "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client name"])},
        "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your rate"])},
        "transferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred to you"])}
      }
    },
    "nextPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
      "payout": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daily at ", _interpolate(_named("time"))])}
    },
    "totalRevenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Revenues"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
    "pendingPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Amount"])},
    "sells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sells"])},
    "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "courses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])}
    }
  },
  "auth": {
    "messages": {
      "signedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've been signed out."])}
    }
  },
  "cards": {
    "actions": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Card"])}
    }
  },
  "products": {
    "navigation": {
      "punta-cana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punta Cana"])},
      "las-terrenas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Terrenas"])},
      "santo-domingo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santo Domingo"])}
    },
    "card": {
      "actions": {
        "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])}
      }
    },
    "inclusions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusions"])}
    },
    "duration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])}
    },
    "booking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "location": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your hotel"])}
      },
      "people": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])}
      },
      "price": {
        "perPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per person :"])},
        "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub total :"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount :"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total :"])}
      }
    }
  },
  "cart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "item": {
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "actions": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
      }
    },
    "bottom": {
      "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "actions": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Checkout"])}
      }
    }
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contact information of the person in charge."])},
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide your full name"])}
          }
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide an email"])},
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])}
          }
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
          "rules": {
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid phone number"])}
          }
        }
      },
      "consent": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to use the information above to create an account on the platform for future use and purchases."])}
      }
    },
    "goToPayment": {
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to payment"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to modify my contact information"])}
    },
    "completeOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your order"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you complete your order, a customer service representative will contact you to confirm your booking. You can cancel and get a refund up to 24h before the activity."])}
    },
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your booking by providing a payment method."])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*** Your payment is processed by Stripe. We do not keep any credit card information as we are PCI compliant."])}
    },
    "totals": {
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "actions": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
      "useOldCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use **** ", _interpolate(_named("lastFour"))])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])}
    },
    "thanks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking completed"])},
      "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your purchase has been completed successfully. We will contact you shortly to set you up and make sure you spend a wonderful time. Contact us at <a href='mailto:", _interpolate(_named("supportEmail")), "'>", _interpolate(_named("supportEmail")), "</a> if you have any problems or questions. Thanks to trust <strong>", _interpolate(_named("companyName")), "</strong>"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the website"])}
    }
  },
  "paypal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Paypal"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Paypal to transfer the funds you generated with you sales. Please give use the email account associated with your Paypal account. If you don't have any Paypal account, <a href='https://paypal.com' target='_blank'>create one first</a>."])},
    "form": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your PayPal email address"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid email address"])}
        }
      }
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal email account set successfully"])}
    },
    "actions": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    }
  },
  "datePicker": {
    "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-mm-dd"])},
    "dob": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])}
    }
  },
  "orders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "items": {
      "entities": {
        "booking": {
          "item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " minutes consultation with ", _interpolate(_named("name"))])}
        }
      },
      "date": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
      },
      "item": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])}
      },
      "amount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
      },
      "actions": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    },
    "status": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
    },
    "actions": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])}
    },
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "list": {
      "head": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "status": {
        "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
      }
    }
  },
  "menu": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "AffiliateView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate"])},
    "DashboardView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "OrdersView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "SettingsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "AnalyzerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizer"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
  },
  "topNavigationBar": {
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "joinCreators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crew"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "affiliateSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliates"])}
  },
  "imageUpload": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile picture"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become an affiliate"])},
    "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make ", _interpolate(_named("percent")), "% of all the revenue you generate."])}
  },
  "videos": {
    "list": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])}
    },
    "feature": {
      "actions": {
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])}
      }
    }
  },
  "settings": {
    "tabs": {
      "userProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
      "userAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "affiliates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliates"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])}
    },
    "userProfile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
      "updateForm": {
        "accountNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account hasn't been verified yet. Verify your email inbox to find the verification link."])},
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account email"])}
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your email"])},
          "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend verification"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account updated successfully"])}
        }
      },
      "updateEmailForm": {
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your email"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your account's email address"])},
        "currentEmail": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current email"])}
        },
        "emailReplacement": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])}
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email to complete the email update"])}
        }
      },
      "changePasswordForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "currentPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide your current password"])}
          }
        },
        "newPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide the new password you wish to set"])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        }
      },
      "resendEmailForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend verification email"])},
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent. Check your inbox."])},
          "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been verified successfully."])},
          "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification token expired. Resend the verification email from the account settings."])}
        }
      }
    },
    "userAccounts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite People"])},
      "inviteUserForm": {
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide an email"])},
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])}
          }
        },
        "role": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a role"])}
          },
          "items": {
            "creator": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])}
            }
          }
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation sent"])},
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])}
        }
      },
      "pendingTokenList": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending invites"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date invited"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invitation sent"])}
      }
    },
    "bank": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank & Transfers"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the section in which you can manage your bank account information and see the money you have made."])},
      "loadingBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading information..."])},
      "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunset Discounts does not process or keep your bank account information. <a href='https://stripe.com' target='_blank'>Stripe</a> takes care of processing our payments and transfers."])},
      "stripeBankForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your bank account"])},
        "addressLine1": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid address line"])}
          }
        },
        "addressLine2": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])}
        },
        "city": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid city"])}
          }
        },
        "state": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])}
        },
        "postalCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a valid postal code"])}
          }
        },
        "firstName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner : First name"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide the first name of the account holder"])}
          }
        },
        "lastName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner : Last name"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide the last name of the account holder"])}
          }
        },
        "dob": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your date of birth"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide your date of birth for the payment processor to verify and activate your bank account"])}
          }
        },
        "transit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transit"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30200"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid transit number."])}
          }
        },
        "institution": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["815"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid institution number."])}
          }
        },
        "account": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5566780"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid account number."])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account added successfully"])}
        }
      },
      "bankSlipBox": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Bank Account"])},
        "statuses": {
          "active": {
            "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-check"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
          },
          "disabled": {
            "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-close"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
          }
        },
        "actions": {
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update bank"])}
        }
      },
      "earnings": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])}
      }
    },
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add and update your credit card."])},
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a card"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your credit card"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "messages": {
        "success": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card has been added successfully."])},
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card has been updated successfully."])}
        }
      }
    },
    "booking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the section in which you can setup your booking parameters. You will see the list of people who paid to get an appointment with you. <strong>You have to manage your appointment yourself in your favorite calendar app for now</strong>."])},
      "list": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
        "table": {
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No booking requests yet."])}
        }
      }
    },
    "affiliates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate links & Earnings"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below are the different affiliate links that you can use to make money from LucidBox. Every money that your affiliate links generate will be identify in this section."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
        "podcast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podcast invitation link"])},
          "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["https://lucidbox.co/podcast?a=", _interpolate(_named("token"))])}
        },
        "messages": {
          "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])}
        }
      },
      "podcast": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podcast Invites"])},
        "table": {
          "headers": {
            "name": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
            },
            "email": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
            },
            "status": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
            }
          },
          "statuses": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
            "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked"])},
            "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showed up"])},
            "dropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropped"])}
          },
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invites yet"])}
        },
        "due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Due : ", _interpolate(_named("due")), "$"])},
        "paymentDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next payment : ", _interpolate(_named("date"))])}
      }
    }
  },
  "subscribeForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "name": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide your full name"])}
      }
    },
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide an email"])},
        "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])}
      }
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must set a password"])}
      }
    },
    "confirmPassword": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "rules": {
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The two passwords don't match"])}
      }
    },
    "gender": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "options": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
        "nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non binary"])}
      },
      "rules": {
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid option."])}
      },
      "noNonBinary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't accept mentally disabled people in our club."])}
    },
    "consent": {
      "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I agree to the <a href='", _interpolate(_named("terms_url")), "' target='_blank'>terms and conditions</a> and the <a href='", _interpolate(_named("privacy_url")), "' target='_blank'>privacy policy</a> when subscribing to Sunset Discounts Affiliate Program."])}
    },
    "actions": {
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a member? Sign In."])}
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account created successfully."])}
    }
  },
  "signInForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])}
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "actions": {
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my password."])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account? Subscribe."])}
    },
    "forgotPassword": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve account"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of your account"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to provide your email to retrieve your account"])},
          "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])}
        }
      },
      "actions": {
        "sendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve account"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "retrieveAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve your account"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "token": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover token"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to provide a valid numeric token."])}
        }
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a new password for your account."])}
        }
      },
      "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent to the following address :"])},
      "fillTheToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up the 6 digits token in the recover token field below."])},
      "actions": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve account"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're signed in."])}
    }
  },
  "footer": {
    "sitemap": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
      "puntaCana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Punta Cana"])},
      "lasTerrenas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Las Terrenas"])},
      "santoDomingo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions in Santo Domingo"])},
      "affiliate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Program"])}
    },
    "legal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and condition"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])}
    },
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["©", _interpolate(_named("year")), " Built by <a href='", _interpolate(_named("url")), "' class='wob' target='_blank'>ConsultOj Digital Services</a> - All rights reserved."])}
  },
  "tags": {
    "entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tech"])},
    "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
    "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancing"])},
    "entrepreneurship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneurship"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
    "dating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dating"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Development"])}
  },
  "contact": {
    "list": {
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your main email address"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number (optional)"])}
        }
      }
    },
    "joinCreators": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the Crew"])},
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide your name"])}
          }
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid email address"])}
          }
        },
        "about": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us why you would make a good mentor"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must talk a bit about yourself"])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your interest. We will contact you shortly."])}
        }
      }
    }
  },
  "transfers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transfers yet."])},
    "table": {
      "head": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
        "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "body": {
        "entity": {
          "booking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " minutes consultation."])}
        },
        "statuses": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
          "transferring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferring"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
        }
      }
    }
  }
}