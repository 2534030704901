<template>
  <v-app
    :class="$route.meta.top ? '' : 'top-pad'"
  >
    <AuthRegisterDialog/>
    <AuthSignInDialog/>
    <CartDialog/>
    <CheckoutProcessDialog/>
    <JoinCreatorsDialog/>
    <TopNavBar
      v-if="!hideNavigation"
    />
    <router-view/>
    <FooterFull/>
  </v-app>
</template>

<script>
import TopNavigationBar from '@/components/navigation/TopNavigationBar.vue'
import AuthRegisterDialog from '@/components/auth/AuthSubscribeDialog.vue'
import AuthSignInDialog from '@/components/auth/AuthSignInDialog.vue'
import JoinCreatorsDialog from '@/components/contact/JoinCreatorsDialog.vue'
import CheckoutProcessDialog from '@/components/checkout_old/CheckoutProcessDialog.vue'
import CartDialog from "@/components/cart/CartDialog.vue";
import TopNavBar from "@/components/navigation/TopNavBar.vue";
import {onMounted} from "vue";
import FooterFull from "@/components/navigation/FooterFull.vue";

export default {
  name: 'App',
  components:  {
    FooterFull,
    CartDialog,
    CheckoutProcessDialog,
    JoinCreatorsDialog,
    AuthSignInDialog,
    AuthRegisterDialog,
    TopNavBar
  },
  data () {
    return {
      hideNavigation: false
    }
  },
  mounted() {
    this.hideNavigation = this.$route.meta.hideNavigation ?? false
    window.scrollTo(0, 0)
    this.$store.dispatch('setDiscount')
  },
  watch: {
    "$route": function (value) {
      this.hideNavigation = value.meta.hideNavigation ?? false
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Yrsa:wght@300;400;500&family=Pacifico&display=swap');
@import '/src/style/app';
</style>
