<template>
  <v-form
    v-model="valid"
    class="pa-5"
    ref="signInForm"
  >
    <CTextField
      v-model="formValues.email"
      class="pb-2"
      :label="$t('signInForm.email.label')"
      :rules="rules.email"
      maxlength="255"
      required
      @keydown.enter="signIn"
    />
    <CTextFieldPassword
      v-model="formValues.password"
      class="pb-2"
      :label="$t('signInForm.password.label')"
      :rules="rules.password"
      maxlength="255"
      required
      @keydown.enter="signIn"
    />
    <v-btn-group
      class="mb-5"
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :enabled="!!valid"
        :label="$t('signInForm.actions.signIn')"
        :loading="loading"
        @click.prevent="signIn"
      />
      <CFormCancelButton
        v-if="cancelAction"
        :label="$t('subscribeForm.actions.cancel')"
        @cancel="$store.commit('authSignInShow', false)"
      />
    </v-btn-group>

    <a
      class="d-block my-2"
      href="javascript:void(0)"
      @click.prevent="forgotPassword"
    >
      {{ $t('signInForm.actions.forgotPassword') }}
    </a>
    <a
      class="d-none my-2"
      href="javascript:void(0)"
      @click.prevent="switchToSubscribe"
    >
      {{ $t('signInForm.actions.subscribe') }}
    </a>

  </v-form>
</template>

<script>
import CTextField from '@/components/fields/CTextField.vue'
import CTextFieldPassword from '@/components/fields/CTextFieldPassword.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'

export default {
  name: 'AuthSignIn',
  components: { CFormCancelButton, CButtonFormSubmit, CTextFieldPassword, CTextField },
  props: {
    cancelAction: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      valid: false,
      loading: false,
      formValues: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          (v) => !!v || this.$t('subscribeForm.email.rules.mandatory'),
          (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('subscribeForm.email.rules.valid')
        ],
        password: [
          (v) => !!v || this.$t('subscribeForm.password.rules.mandatory'),
        ]
      }
    }
  },
  methods: {
    forgotPassword () {
      this.$store.commit('authSignInShow', false)
      this.$emit('toggleForgotPassword', true)
    },
    switchToSubscribe () {
      this.$store.commit('authSignInShow', false)
      this.$router.push({ name: 'PlanSelectionView' })
    },
    async signIn () {
      this.loading = true

      await this.$store.dispatch('retrieveToken', this.formValues)
        .then(() => {
          const redirect = this.$route.query.redirect

          this.$store.commit('authSignInShow', false)
          this.$toast.success(this.$t('signInForm.messages.success'))

          if (redirect !== undefined) {
            this.$router.push(redirect)
          } else {
            this.$router.push({ name: 'AffiliateView' })
          }
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

a {
  font-size: $text-small;
}
</style>