<script setup lang="ts">
import { ref, defineProps, defineEmits, defineExpose } from 'vue'
import currency from '@/helpers/currency'
import i18n from "../../../i18n";
import router from "@/router";

const imagesUrl = process.env.VUE_APP_IMAGES_URL

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const currencies = {
  code: 'USD',
  symbol: '$',
  symbol_position: 'pre',
}

</script>

<template>
  <v-card
    width="100%"
    elevation="3"
    class="pa-0"
  >
    <div
      class="img"
      v-bind:style="{ 'background-image': 'url(' + imagesUrl + item.image + ')' }"
    />
    <div>
      <v-card-title>
        {{ item.meta.data.title }}
      </v-card-title>
      <v-card-subtitle
        class="no-wrap mb-2"
      >
        {{ item.meta.data.description.replace(/<\/?p>/g, "") }}
      </v-card-subtitle>
    </div>
    <v-card-actions
      class="pt-3 px-4 pb-3 justify-space-between"
    >
      <v-btn
        color="secondary"
        variant="tonal"
        @click.prevent="router.push({ name: 'ProductDetailsPage', params: { destination: item.destinations[0].city, uuid: item.uuid } })"
      >
        {{ i18n.global.t('products.card.actions.book') }}
      </v-btn>
      <div class="price">{{ currency.formatAmount(item.prices[0].price, currencies) }}</div>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.img {
  height: 200px;
  background-position: center;
  background-size: cover;
}
  .no-wrap {
     //white-space: break-spaces;
  }
  .price {
    font-weight: 500;
    font-size: 1.15em;
  }
</style>