<script setup>
import { ref, inject, onBeforeMount } from 'vue'
import i18n from '@/i18n'
import StripeBankForm from '@/modules/settings/components/BankTab/StripeBankForm.vue'
import BankSlipBox from '@/modules/settings/components/BankTab/BankSlipBox.vue'
import store from '@/store'
const http = inject('axios')
const toast = inject('toast')

onBeforeMount(() => {
  setTimeout(() => {
    fetchBank()
  }, 300)
})

let loading = ref(true)
let updateMode = ref(false)
let bankAccount = ref(null)

const setUpdateMode = (value) => {
  updateMode.value = value
}

const fetchBank = () => {
  loading.value = true
  bankAccount.value = null

  http.get('/bank')
      .then(response => {
        const data = response?.data.data
        if (data?.stripe_bank_id === null) {
          updateMode.value = true
        }
        bankAccount.value = data
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(() => { loading.value = false })
}

const onSuccess = () => {
  if (updateMode.value) {
    updateMode.value = false
  }
  store.dispatch('getUser')
  fetchBank()
}
</script>

<template>
  <v-container>
    <h1 class="pb-5">{{ i18n.global.t('settings.bank.title') }}</h1>
    <p class="mb-10">{{ i18n.global.t('settings.bank.description') }}</p>

    <div
      v-if="!loading && !bankAccount || updateMode"
      class="mb-10"
    >
      <h2>{{ i18n.global.t('settings.bank.stripeBankForm.title') }}</h2>
      <StripeBankForm
          class="mb-8"
          :update-mode="updateMode"
          :incomplete="bankAccount && !bankAccount.stripe_bank_id"
          @update="setUpdateMode"
          @success="onSuccess"
      />
    </div>
    <div
      v-else-if="!loading && bankAccount"
      class="mb-10"
    >
      <h2 class="mb-0">{{ i18n.global.t('settings.bank.bankSlipBox.title') }}</h2>
      <BankSlipBox
          class="mb-8"
          :bank-account="bankAccount"
          @update="setUpdateMode"
      />
    </div>
    <h2
      v-else
      class="loader mb-10"
    >
      {{ i18n.global.t('settings.bank.loadingBank') }}
      <v-progress-circular indeterminate/>
    </h2>
  </v-container>
</template>

<style lang="scss" scoped>
.loader {
  display: flex;
  flex-direction: column;

    .v-progress-circular {
      margin: 35px 0;
      align-self: center;
      justify-self: center
    }
}
</style>