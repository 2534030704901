<script setup lang="ts">
import router from "@/router";
import CartButton from '@/components/cart/CartButton.vue'
import { ref, computed, inject } from 'vue'
import Store from '@/store'
import requestResponses from '@/helpers/requestResponses'
import i18n from "@/i18n";

const toast = inject('toast')

const dialog = ref(false)
const imagesUrl = process.env.VUE_APP_IMAGES_URL
const route = router.currentRoute

const loggedIn = computed(() => {
  return Store.getters.isLoggedIn
})

const user = computed(() => {
  return Store.getters.getUser
})

const routes = computed(() => {
  const routes = router.getRoutes()
  const selected = []
  routes.sort((a, b) => {
    if (a.meta.order === undefined) return 1
    if (b.meta.order === undefined) return - 1

    return a.meta.order - b.meta.order
  })

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].meta.inAuthMenu) {
      selected.push(routes[i])
    }
  }

  return selected
})

const navigate = (routeName, hash = null) => {
  let obj = { name: routeName }

  if (hash) {
    obj.hash = hash
  }

  router.push(obj).catch(() => { console.log() })
  dialog.value = false
}

const signOut = () => {
  Store.dispatch('destroyToken')
    .then(() => {
      dialog.value = false
      toast.info(i18n.global.t('auth.messages.signedOut'))
    })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
}
</script>

<template>
  <v-app-bar
    :elevation="route.meta.transparentMenuBar ? 0 : 1"
    :flat="!route.meta.transparentMenuBar"
    :outlined="route.meta.transparentMenuBar"
    :class="route.meta.transparentMenuBar ? 'gradient-bg-white-trans' : ''"
  >
    <v-container
      class="d-flex justify-space-between align-center py-0 pl-0"
      fluid
    >
      <v-toolbar-items>
        <v-btn
          key="home"
          @click.prevent="router.push({ name: 'HomePage' })"
        >
          <img
            class="logo"
            alt="Sunset Discount Logo"
            :src="imagesUrl + '/sunsetdiscounts-logo.png'"
          />
        </v-btn>
      </v-toolbar-items>
      <v-chip
        v-if="Store.getters.discount.rate"
        size="small"
        color="secondary"
        variant="outlined"
      >
        {{ i18n.global.t('discounts.tenPending') }}
      </v-chip>
      <v-spacer></v-spacer>

      <div
        v-if="loggedIn && user"
      >
        <v-btn
          color="grey-darken-1"
          variant="tonal"
          icon
          @click.prevent="dialog = !dialog"
        >
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>
      </div>
      <div
        v-else
        class="d-flex align-center"
      >
        <a
          class="mr-3 outfit-regular"
          href="javascript:void(0)"
          @click.prevent="Store.commit('authSignInShow', true)"
        >
          {{ i18n.global.t('topNavigationBar.affiliateSignIn') }}
        </a>
        <CartButton/>
      </div>

      <v-dialog
        v-model="dialog"
        content-class="elevation-0"
        persistent
        fullscreen
      >
        <v-btn
          class="close-btn"
          color="secondary"
          elevation="0"
          variant="text"
          icon
          @click.prevent="dialog = false"
        >
          <v-icon
            size="large"
          >
            mdi-close
          </v-icon>
        </v-btn>

        <div
          class="menu"
        >
          <h1 class="logo-text mb-5">Menu</h1>
          <ol
            class="pa-0"
          >
            <li
              v-for="(route, i) in routes"
              :key="i"
              class="my-1"
            >
              <v-btn
                class="my-3 justify-start"
                color="secondary"
                elevation="0"
                size="large"
                block
                :variant="$route?.name !== route.name ? 'outlined' : 'flat'"
                @click="navigate(route.name)"
              >
                <v-icon
                  v-if="route.meta.icon"
                  class="mr-3"
                  size="large"
                >
                  {{ route.meta.icon }}
                </v-icon>
                <span>{{ $t('menu.' + route.name) }}</span>
              </v-btn>
            </li>
            <li
              class="my-1"
            >
              <v-btn
                class="my-3 justify-start"
                color="secondary"
                elevation="0"
                size="large"
                block
                variant="tonal"
                @click="signOut"
              >
                <v-icon
                  class="mr-3"
                  size="large"
                >
                  mdi-logout
                </v-icon>
                <span>{{ $t('menu.SignOut') }}</span>
              </v-btn>
            </li>
          </ol>
        </div>
      </v-dialog>
    </v-container>
  </v-app-bar>
</template>

<style scoped lang="scss">
@import 'src/style/breakpoints';
@import 'src/style/colors';
@import 'src/style/variables';

.v-container {
  height: 100%;
}

.logo {
  max-width: 40px;
}

.logo-text {
  color: $color-secondary;
}

:deep(.v-toolbar__content) {
  height: $app-bar-height !important;
}

.v-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    max-width: 350px;
    width: 350px;
    padding-left: 25px;
    padding-right: 25px;
    height: 100vh;
  }

  .menu-logo {
    align-self: center;
    max-width: 105px;
    margin-bottom: 15px;
  }

  :deep(.v-overlay__scrim) {
    background-color: $black;
    opacity: 96%;
  }
}

ol {
  display: block;
  width: 100%;
  margin: 0 auto;

  li {
    list-style: none;

    a, a:link, a:active, a:visited {
      text-decoration: none;
      color: $color-link;
    }
  }
}

ol.lined-up {

  li {
    display: inline-block;
  }
}

.close-btn {
  position: fixed;
  top: 10px;
  right: 15px;
}
</style>