<template>
  <v-btn
    :color="color"
    @click.prevent="click"
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: 'CActionButton',
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>