<template>
  <v-form
    v-model="valid"
    class="pa-5"
  >
    <CTextField
      v-model="user.email"
      class="mb-2"
      :label="$t('settings.userProfile.updateEmailForm.currentEmail.label')"
      disabled
    />
    <CTextField
      v-model="form.values.email"
      :label="$t('settings.userProfile.updateEmailForm.emailReplacement.label')"
      :rules="form.rules.email"
      required
    />
    <CTextFieldPassword
      v-model="form.values.password"
      :label="$t('settings.userProfile.updateEmailForm.password.label')"
      :rules="form.rules.password"
      required
    />

    <v-btn-group
        class="mt-5"
        divided
        variant="flat"
    >
      <CButtonFormSubmit
        :enabled="valid"
        :loading="loading"
        :label="$t('settings.userProfile.updateEmailForm.actions.submit')"
        @click.prevent="submit"
      />
      <CFormCancelButton
        :label="$t('settings.userProfile.updateEmailForm.actions.cancel')"
        @cancel="$emit('closeModal')"
      />
    </v-btn-group>
  </v-form>
</template>

<script>
import CTextField from '@/components/fields/CTextField.vue'
import CTextFieldPassword from '@/components/fields/CTextFieldPassword.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import requestResponses from '@/helpers/requestResponses'

export default {
  name: 'UpdateEmailForm',
  components: { CFormCancelButton, CButtonFormSubmit, CTextFieldPassword, CTextField },
  data () {
    return {
      valid: false,
      loading: false,
      form: {
        values: {
          email: null,
          password: null,
        },
        rules: {
          email: [
            (v) => !!v || this.$t('subscribeForm.email.rules.mandatory'),
            (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('subscribeForm.email.rules.valid')
          ],
          password: [
            (v) => !!v || this.$t('subscribeForm.password.rules.mandatory'),
          ]
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    async submit () {
      this.loading = true

      await this.$http.post('/user/update-email', this.form.values)
          .then(response => {
            this.$toast.info(this.$t('settings.userProfile.updateEmailForm.messages.success'))
            this.$store.commit('setUser', response.data.data)
            this.$emit('closeModal')
          })
          .catch(error => {
            this.$toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>