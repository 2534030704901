<script setup lang="ts">
import { ref, defineProps, computed, onMounted, inject } from 'vue'
import i18n from "@/i18n";
import CTextField from "@/components/fields/CTextField.vue";
import requestResponses from "@/helpers/requestResponses";
import router from "@/router";

interface PaypalObjectType {
  id: number,
  email: string
}

const props = defineProps({
  paypalAccount: {
    type: [Object<PaypalObjectType>, null]
  }
})

onMounted(() => {
  if (props.paypalAccount) {
    email.value = props.paypalAccount.email
  }
})

const email = ref('')

const rules = {
  email: [
    (v) => !!v || i18n.global.t('paypal.form.email.rules.mandatory'),
    (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.global.t('paypal.form.email.rules.mandatory')
  ]
}
const isEmailValid = computed(() =>
  rules.email.every((rule) => rule(email.value) === true)
);

const http = inject('axios')
const toast = inject('toast')
const loading = ref(false)

const submit = async () => {
  const pp = props.paypalAccount
  let h;
  loading.value = true

  if (props.paypalAccount) {
    h = http.put('/paypal/' + pp.id, {
      email: email.value
    })
  } else {
    h = http.post('/paypal', {
      email: email.value
    })
  }

  h.then(() => {
    toast.success(i18n.global.t('paypal.messages.success'))
    router.push({ name: 'AffiliateView' })
  })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
    .finally(() => { loading.value = false })
}
</script>

<template>
  <div
    class="content"
  >
    <h1 class="mb-4">{{ i18n.global.t('paypal.title') }}</h1>
    <p class="mb-5" v-html="i18n.global.t('paypal.details')"/>
    <CTextField
      v-model="email"
      class="pb-2"
      :label="i18n.global.t('paypal.form.email.label')"
      :rules="rules.email"
      maxlength="255"
      required
    />
    <v-btn
      color="secondary"
      flat
      size="large"
      :disabled="!isEmailValid"
      :loading="loading"
      @click.prevent="submit"
    >
      {{ i18n.global.t('paypal.actions.save') }}
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
.content {

  p {
    max-width: 640px;
  }
}
</style>