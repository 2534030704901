<script setup>
import { ref, inject, defineProps, defineEmits } from 'vue'
import CTextField from '@/components/fields/CTextField.vue'
import i18n from '@/i18n'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import requestResponses from '@/helpers/requestResponses'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import DatePickerInput from '@/components/fields/DatePickerInput.vue'

const props = defineProps({
  updateMode: {
    type: Boolean,
    default: false
  },
  incomplete: {
    type: Boolean,
    default: false
  }
})
const imagesUrl = process.env.VUE_APP_IMAGES_URL
const http = inject('axios')
const toast = inject('toast')
const smoothScroll = inject('smoothScroll')

let valid = ref(false)
let loading = ref(false)
let values = ref({
  transit: null,
  institution: null,
  account_number: null,
  first_name: '',
  last_name: '',
  dob: null,
  address_line_1: '',
  address_line_2: null,
  city: '',
  state: 'QC',
  postal_code: ''
})
const bankForm = ref(null)
const scrollToTop = () => {
  smoothScroll({
    scrollTo: bankForm.value
  })
}

const transitNumberError = ref(false)
const institutionNumberError = ref(false)
const accountNumberError = ref(false)

const rules = {
  transit : [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.transit.rules.mandatory')
  ],
  institution: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.institution.rules.mandatory')
  ],
  account: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.account.rules.mandatory')
  ],
  firstName: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.firstName.rules.mandatory')
  ],
  lastName: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.lastName.rules.mandatory')
  ],
  addressLine1: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.addressLine1.rules.mandatory')
  ],
  city: [
    (v) => !!v || i18n.global.t('settings.bank.stripeBankForm.city.rules.mandatory')
  ],
  postalCode: [
    (v) => !!v && /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || i18n.global.t('settings.bank.stripeBankForm.postalCode.rules.mandatory')
  ]
}

const formatPostalCode = () => {
  values.value.postal_code = values.value.postal_code.toUpperCase()
}

const emit = defineEmits(['success', 'update'])

const setDob = (v) => {
  values.value.dob = v
}


const submit = async () => {
  let url
  loading.value = true

  let data = values.value

  const routingNumber = values.value.transit == 0 && values.value.institution == 0 ? '110000000' : values.value.transit +'-'+ values.value.institution
  data.routing_number = routingNumber

  if(props.updateMode) {
    url = '/bank/update'
  } else {
    url = '/bank'
  }

  await http.post(url, data)
      .then(() => {
        toast.success(i18n.global.t('settings.bank.stripeBankForm.messages.success'))
        emit('success')
      })
      .catch(error => {
        transitNumberError.value = true
        institutionNumberError.value = true
        accountNumberError.value = true
        scrollToTop()
        toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
      })
      .finally(() => { loading.value = false })
}
</script>

<template>
  <v-form
    v-model="valid"
    ref="bankForm"
  >
    <img
      class="stripe-logo"
      :src="imagesUrl + '/stripe-logo.png'"
    />
    <v-alert
      class="mb-5 d-none"
      type="info"
      color="#020202"
    >
      <span v-html="i18n.global.t('settings.bank.disclaimer')" />
    </v-alert>
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <CTextField
          v-model="values.transit"
          class="pb-2"
          :label="i18n.global.t('settings.bank.stripeBankForm.transit.label')"
          :rules="rules.transit"
          :error="transitNumberError"
          hide-details
          density="comfortable"
          maxlength="5"
          placeholder="30200"
          prepend-inner-icon="mdi-bank-outline"
          required
          @focus="transitNumberError = false"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <CTextField
          v-model="values.institution"
          class="pb-2"
          :label="i18n.global.t('settings.bank.stripeBankForm.institution.label')"
          :rules="rules.institution"
          :error="institutionNumberError"
          hide-details
          density="comfortable"
          maxlength="3"
          placeholder="815"
          prepend-inner-icon="mdi-bank-outline"
          required
          @focus="institutionNumberError = false"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <CTextField
          v-model="values.account_number"
          class="pb-2"
          :label="i18n.global.t('settings.bank.stripeBankForm.account.label')"
          :rules="rules.accountNumber"
          :error="accountNumberError"
          hide-details
          density="comfortable"
          maxlength="12"
          placeholder="2558970"
          prepend-inner-icon="mdi-bank-outline"
          required
          @focus="accountNumberError = false"
        />
      </v-col>
    </v-row>

    <hr class="mb-8"/>

    <CTextField
      v-model="values.first_name"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.firstName.label')"
      :rules="rules.firstName"
      density="comfortable"
      maxlength="12"
      required
    />

    <CTextField
      v-model="values.last_name"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.lastName.label')"
      :rules="rules.lastName"
      density="comfortable"
      maxlength="12"
      required
    />

    <label
      class="lbl"
    >
      {{ i18n.global.t('settings.bank.stripeBankForm.dob.label') }} <span class="red--text"><strong>*</strong></span>
    </label>

    <DatePickerInput
      @update="setDob"
    />

    <hr class="my-8" />

    <CTextField
      v-model="values.address_line_1"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.addressLine1.label')"
      :rules="rules.addressLine1"
      density="comfortable"
      maxlength="255"
      required
    />

    <CTextField
      v-model="values.address_line_2"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.addressLine2.label')"
      density="comfortable"
      maxlength="255"
    />

    <CTextField
      v-model="values.city"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.city.label')"
      :rules="rules.city"
      density="comfortable"
      maxlength="255"
      required
    />

    <v-select
      v-model="values.state"
      class="pb-2"
      :disabled="true"
      :readonly="true"
      density="comfortable"
      variant="outlined"
    />

    <CTextField
      v-model="values.postal_code"
      class="pb-2"
      :label="i18n.global.t('settings.bank.stripeBankForm.postalCode.label')"
      :rules="rules.postalCode"
      density="comfortable"
      maxlength="6"
      required
      @keyup="formatPostalCode"
    />

    <v-btn-group
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :enabled="valid"
        :loading="loading"
        :label="i18n.global.t('settings.bank.stripeBankForm.actions.submit')"
        size="large"
        @click.prevent="submit"
      />
      <CFormCancelButton
        v-if="updateMode && !props.incomplete"
        label="Cancel"
        @click="emit('update', false)"
      />
    </v-btn-group>
  </v-form>
</template>

<style lang="scss" scoped>
.stripe-logo {
  max-width: 150px;
  margin-top: 12px;
  margin-bottom: 20px;
}

hr {
  margin-top: 24px;
  border-color: rgb(36, 36, 36);
}

.lbl {
  color: rgb(118, 118, 118);
}

.dob {
  display: flex;
  align-items: center;
  justify-content: start;

  input {
    max-width: 45px;
    padding: 4px;
    text-align: center;
    outline: none;

    &.year {
      max-width: 60px;
    }
  }

  .separator {
    font-size: 32px;
  }
}
</style>
