<script setup lang="ts">
import ContactInformationForm from '@/components/checkout/ContactInformationForm.vue'
import CartItemsList from '@/components/cart/CartItemsList.vue'
import { computed, inject, onBeforeUnmount, onMounted, provide, ref } from 'vue'
import Store from '@/store'
import currency from '../../helpers/currency'
import i18n from '@/i18n'
import StripeCardElement from '@/components/card/StripeCardElement.vue'
import router from "@/router";

onMounted(() => {
  if (!Store.getters.cartItems.length) {
    router.push({ name: 'ProductListPage' })
  }
})

const http = inject('axios')
const toast = inject('toast')

const subTotal = computed(() => Store.getters.cartSubTotal)
const total = computed(() => Store.getters.cartTotal)

provide('disabledItemDelete', true)

interface ContactInformationType {
  email: string,
  name: string,
  phone?: string,
  consent: boolean
}

const contactInformation = ref<ContactInformationType>({
  email: "",
  name: "",
  phone: "",
  consent: true
});

const contactInformationDisabled = ref<boolean>(false)

const disableContactInformation = () => {
  contactInformationDisabled.value = !contactInformationDisabled.value

  if (!contactInformationDisabled.value) {
    stripeCardElement.value.cancel()
  }
}

const updateContactInformation = (data) => {
  contactInformation.value = data
}

// Discount
const discountCode = computed(() => {
  return Store.getters.discount.code
})

const discountAmount = computed(() => {
  if (!Store.getters.discount.rate) {
    return 0
  }

  return Store.getters.discount.rate / 100 * subTotal.value
})

const payButtonDisabled = computed(() => {
  return !contactInformationDisabled.value
})

const requiredFields = computed(() => {
  return contactInformation.value.email && contactInformation.value.name
})

const isLoading = ref<boolean>(false)
const stripeCardElement = ref(null)


// 01. First step of the order is to call the BE to complete the payment intent with the provided credit card
const processPayment = async () => {
  isLoading.value = true

  await stripeCardElement.value.confirmCardPayment(
    (paymentIntentId) => {
      completeOrder(paymentIntentId)
    },
    (error) => {
      toast.error(error)
      isLoading.value = false
    }
  )
}

// 02. Create the order in the DB an associate it with the user
const completeOrder = (paymentIntentId) => {
  isLoading.value = true

  http.post('/order', {
    payment_intent_id: paymentIntentId,
    email: contactInformation.value.email,
    name: contactInformation.value.name,
    phone: contactInformation.value.phone,
    consent: contactInformation.value.consent,
    affiliate: discountCode.value,
    cart_items: Store.getters.cartItems.map(item => ({
      uuid: item.product.uuid,
      quantity: item.quantity,
      booking: item.booking
    }))
  })
    .then(() => {
      Store.dispatch('clearCart')
      Store.commit('setDiscount', null)
      localStorage.removeItem('discount')
      router.push({ name: 'CheckoutThankYouPageView' })
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { isLoading.value = false })
}
</script>

<template>
  <div
    class="wrap"
  >
    <v-row>
      <v-col
        class="content px-7"
        cols="12"
        md="7"
      >
        <div
          class="container"
        >
          <h1 class="mb-2">{{ i18n.global.t('checkout.title') }}</h1>

          <v-divider class="my-3" />

          <ContactInformationForm
            :disabled="contactInformationDisabled"
            @update="updateContactInformation"
          />

          <v-btn
            class="d-block mb-5"
            size="small"
            flat
            variant="tonal"
            :disabled="!requiredFields"
            @click.prevent="disableContactInformation"
          >
            <span>
              {{ contactInformationDisabled ? i18n.global.t('checkout.goToPayment.back') : i18n.global.t('checkout.goToPayment.go') }}
            </span>
          </v-btn>

          <div
            v-if="contactInformationDisabled"
            class="form-wrap"
          >
            <h2>{{ i18n.global.t('checkout.card.title') }}</h2>
            <p>{{ i18n.global.t('checkout.card.description') }}</p>

            <StripeCardElement
              :email="contactInformation.email"
              :name="contactInformation.name"
              :consent="contactInformation.consent"
              ref="stripeCardElement"
            />
          </div>
        </div>
      </v-col>
      <v-col
        class="content payment-section px-7"
        cols="12"
        md="5"
      >
        <div
          class="container"
        >
          <div class="form-wrap mb-4">
            <h2>{{ i18n.global.t('checkout.completeOrder.title') }}</h2>
            <p>{{ i18n.global.t('checkout.completeOrder.details') }}</p>
          </div>

          <v-divider />

          <CartItemsList/>

          <v-divider class="mt-4" />

          <div class="code mt-4">

            <v-text-field
              v-model="discountCode"
              label="Discount code"
              density="comfortable"
              variant="solo"
              flat
              readonly
            outlined
            >

            </v-text-field>
          </div>

          <div
            class="py-4"
          >
            <div
              class="total-line"
            >
              <span>{{ i18n.global.t('checkout.totals.sub') }}</span>
              <span>{{ currency.formatAmount(subTotal) }}</span>
            </div>
            <div
              class="total-line"
            >
              <span>{{ i18n.global.t('checkout.totals.discount') }}</span>
              <span>{{ currency.formatAmount(discountAmount) }}</span>
            </div>
            <div
              class="total-line"
            >
              <span>{{ i18n.global.t('checkout.totals.total') }}</span>
              <span>{{ currency.formatAmount(total) }}</span>
            </div>
          </div>

          <div
            class=""
          >
            <v-btn
              :color="payButtonDisabled ? 'grey' : 'secondary'"
              size="large"
              block
              elevation="1"
              :disabled="payButtonDisabled"
              :loading="isLoading"
              @click.prevent="processPayment"
            >
              {{ i18n.global.t('checkout.actions.pay') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/breakpoints";
@import "@/style/colors";

.wrap, .v-row {
  height: 100vh;

  @media (max-width: $breakpoint-sm-down) {
    height: inherit;
  }
}

.v-row {
  margin: 0;
}

.content {
  display: flex;
  justify-content: end;
  align-items: start;
  padding: 50px 15px 50px;

  @media (max-width: $breakpoint-sm-down) {
    justify-content: center;
  }

  .container {
    max-width: 580px;
    width: -webkit-fill-available;
  }
}

.payment-section {
  justify-content: start;
  background-color: #f5f5f5;
  border-left: 1px solid #dcdcdc;

  .container {
    max-width: 480px;
  }

  .total-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {

    }
  }

  @media (max-width: $breakpoint-sm-down) {
    justify-content: center;
    border-left: inherit;
    border-top: 1px solid #dcdcdc;

    .container {
      max-width: 580px;
    }
  }
}
</style>