<script setup lang="ts">
import i18n from "../../i18n";
import router from "@/router";

const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL
const companyName = process.env.VUE_APP_NAME

</script>

<template>
  <div
    class="wrap"
  >
    <div
      class="content"
    >
      <div
        class="header d-flex align-center space mb-2"
      >
        <v-icon
          class="mr-5"
          size="x-large"
        >
          mdi-check-circle-outline
        </v-icon>
        <h1>{{ i18n.global.t('checkout.thanks.title') }}</h1>
      </div>

      <v-divider/>

      <p class="mt-4" v-html="i18n.global.t('checkout.thanks.details', { supportEmail: supportEmail, companyName: companyName })" />

      <a href="javascript:void(0)" @click="router.push({ name: 'HomePage' })">{{ i18n.global.t('checkout.thanks.back') }}</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/breakpoints.scss";

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-left: 16px;
  padding-right: 16px;

  .content {
    max-width: 480px;
  }
}
</style>