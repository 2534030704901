/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// webpack.config.js

// Styles
import 'vuetify/styles'

// Modules
import { createVuetify } from 'vuetify'
import * as labs from 'vuetify/labs/components'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
  components: {
    ...components,
    ...labs
  },
  directives,
  ssr: true,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#31c1ed',
          secondary: '#ef2859',
          green: '#30d79d'
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
})
