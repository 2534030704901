import { createStore } from 'vuex'
import router from '@/router'
import axios from '@/plugins/axios'

const key = process.env.VUE_APP_API_URL ?? ""
const http = axios(key)

export default createStore({
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    retrieveAccountEmail: null,
    user: null,
    displayAnalyserFilters: false,
    cart: {
      show: false,
      items: [] as any[]
    },
    discount: {
      code: null,
      rate: null
    },
    globalDialogs: {
      authSubscribe: {
        show: false
      },
      authSignIn: {
        show: false,
        after: null
      },
      checkoutProcess: {
        show: false,
        entity: {
          entity: null,
          entityId: null,
          creator: null,
          content: {
            title: '',
            description: '',
            items: []
          },
          price: 0,
          prices: {},
          frequency: 'monthly',
          data: {},
          variants: []
        },
        currency: { code: 'usd', symbol: '$', symbol_position: 'pre' }
      },
      joinCreators: {
        show: false
      }
    }
  },
  getters: {
    authSubscribeShow (state) {
      return state.globalDialogs.authSubscribe.show
    },
    authSignInShow (state) {
      return state.globalDialogs.authSignIn.show
    },
    authSignInAfter (state) {
      return state.globalDialogs.authSignIn.after
    },
    cartShow (state) {
      return state.cart.show
    },
    cartItems (state) {
      return state.cart.items
    },
    cartSubTotal (state) {
      let subTotal = 0
      const items = state.cart.items

      for (let i = 0; i < items.length; i++) {
        subTotal += items[i].quantity * items[i].product.prices[0].price
      }

      return subTotal
    },
    cartTotal (state) {
      let subTotal = 0
      const items = state.cart.items

      for (let i = 0; i < items.length; i++) {
        subTotal += items[i].quantity * items[i].product.prices[0].price
      }

      if (state.discount.rate) {
        subTotal -= subTotal * (state.discount.rate / 100)
      }

      return subTotal
    },
    checkoutProcessShow (state) {
      return state.globalDialogs.checkoutProcess.show
    },
    checkoutProcessEntity (state) {
      return state.globalDialogs.checkoutProcess.entity
    },
    checkoutProcessCurrency (state) {
      return state.globalDialogs.checkoutProcess.currency
    },
    joinCreatorsShow (state) {
      return state.globalDialogs.joinCreators.show
    },
    isLoggedIn (state) {
      return !!state.accessToken
    },
    getUser (state) {
      return state.user
    },
    getRetrieveAccountEmail (state) {
      return state.retrieveAccountEmail
    },
    discount (state) {
      return state.discount
    }
  },
  mutations: {
    addToCart (state, value) {
      state.cart.items.push(value)

      localStorage.setItem('cart', JSON.stringify(state.cart.items))
    },
    removeFromCart (state, value) {
      const array = state.cart.items
      console.log(value)
      // const index = cart.findIndex(value => item.id === 1)
      array.splice(value, 1)
      state.cart.items = array

      localStorage.setItem('cart', JSON.stringify(array))
    },
    cartShow (state, value) {
      state.cart.show = value
    },
    authSubscribeShow (state, value) {
      state.globalDialogs.authSubscribe.show = value
    },
    authSignInShow (state, value) {
      state.globalDialogs.authSignIn.show = value
    },
    authSignInAfter (state, value) {
      state.globalDialogs.authSignIn.after = value
    },
    checkoutProcessShow (state, value) {
      state.globalDialogs.checkoutProcess.show = value
    },
    checkoutProcessEntity (state, value) {
      state.globalDialogs.checkoutProcess.entity = value
    },
    checkoutProcessCurrency (state, value) {
      state.globalDialogs.checkoutProcess.currency = value
    },
    joinCreatorsShow (state, value) {
      state.globalDialogs.joinCreators.show = value
    },
    retrieveToken (state, token) {
      state.accessToken = token
    },
    destroyToken (state) {
      state.accessToken = null
    },
    setUser (state, data) {
      state.user = data
    },
    setRetrieveAccountEmail (state, email) {
      state.retrieveAccountEmail = email
    },
    setDiscount (state, value) {
      state.discount = value === null ? { code: null, rate: null } : value
    }
  },
  actions: {
    async retrieveToken (context, credentials) {
      return await new Promise((resolve, reject) => {
        http.post('/auth/sign-in', credentials)
            .then((response) => {
              const token = response.data.access_token;
              const action = context.getters.authSignInAfter
              localStorage.setItem('accessToken', token)
              context.commit('retrieveToken', token)
              context.dispatch('getUser')
              if (action) {
                action()
                context.commit('authSignInAfter', null)
              }
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    async destroyToken (context) {
      if (context.state.accessToken) {

        const logout = () => {
          if (router.currentRoute.value.meta.requiresAuth) {
            router.push({ name: 'HomePage' })
              .then(() => {
                context.commit('setUser', null)
              })
          } else {
            context.commit('setUser', null)
          }
          localStorage.removeItem('accessToken')
          context.commit('destroyToken')
        }

        return await new Promise((resolve, reject) => {
          http.post('/auth/sign-out')
            .then((response) => {
              logout()
              resolve(response)
            })
            .catch((error) => {
              logout()
              reject(error)
            })
        })
      }
    },
    getUser (context) {
      return new Promise((resolve, reject) => {
        http.get('/user/me')
          .then((response) => {
            context.commit('setUser', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            context.dispatch('destroyToken')
            reject(error)
          })
      })
    },
    setDiscount ({ commit }, token) {
      const discount = localStorage.getItem('discount') ?? null
      const parsedDiscount = discount ? JSON.parse(discount) : null
      let t: string | null = null

      if (parsedDiscount) {
        t = parsedDiscount.token
      } else if (token) {
        t = token
      }

      if (t) {
        return new Promise((resolve, reject) => {
          http.post('/affiliate/invite', {
            token: t
          })
            .then((response) => {
              const data = response.data.data

              if (data.code === 200) {
                localStorage.setItem('discount', JSON.stringify(response.data.data))
                commit('setDiscount', { code: data.token, rate: data.rate })
              } else {
                localStorage.removeItem('discount')
                commit('setDiscount', null)
              }

              resolve(response)
            })
            .catch((error) => {
              localStorage.removeItem('discount')
              commit('setDiscount', null)
              reject(error)
            })
        })
      }
    },
    buildCartFromLocalStorage (context) {
      const cartItems = localStorage.getItem('cart')

      if (cartItems) {
        context.state.cart.items = JSON.parse(cartItems)
      }
    },
    clearCart (context) {
      localStorage.removeItem('cart')
      context.state.cart.items = []
    }
  },
  modules: {},
});
