<template>
  <div>
    <CDialogFrame
      :show="$store.getters.authSignInShow"
      :title="$t('signInForm.title')"
      @toggleDisplay="(v) => $store.commit('authSingInShow', v)"
    >
      <template v-slot:component>
        <AuthSignIn
          @toggleForgotPassword="(v) => showForgotPassword = v"
        />
      </template>
    </CDialogFrame>
    <CDialogFrame
      :show="showForgotPassword"
      :title="$t('signInForm.forgotPassword.title')"
    >
      <template v-slot:component>
        <ForgotPasswordForm
          @toggleDisplay="(v) => showForgotPassword = v"
          @success="resetPasswordSuccess"
        />
      </template>
    </CDialogFrame>
    <CDialogFrame
      :show="showRetrieveAccount"
      :title="$t('signInForm.retrieveAccount.title')"
    >
      <template v-slot:component>
        <RetrieveAccountForm
          @toggleDisplay="(v) => showRetrieveAccount = v"
        />
      </template>
    </CDialogFrame>
  </div>
</template>

<script>
import CDialogFrame from '@/components/dialogs/CDialogFrame.vue'
import AuthSignIn from '@/components/auth/AuthSignInForm.vue'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm.vue'
import RetrieveAccountForm from "@/components/auth/RetrieveAccountForm.vue";

export default {
  name: 'AuthSignInDialog',
  components: { RetrieveAccountForm, AuthSignIn, CDialogFrame, ForgotPasswordForm },
  data () {
    return {
      showForgotPassword: false,
      showRetrieveAccount: false
    }
  },
  methods: {
    resetPasswordSuccess (email) {
      this.showForgotPassword = false
      this.$store.commit('setRetrieveAccountEmail', email)
      this.showRetrieveAccount = true
    }
  }
}
</script>

<style scoped>

</style>