<template>
  <v-text-field
    v-model="value"
    :type="showPassword ? 'text' : 'password'"
    :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :rules="rules"
    :maxlength="maxlength"
    :error="error"
    autocomplete="new"
    variant="outlined"
    @keyup="parseValue"
    @click:append-inner="showPassword = !showPassword"
  >
    <template #label>
      {{ label }}&nbsp;<span v-if="required" class="red--text"><strong>*</strong></span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'CTextFieldPassword',
  props: {
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Array,
      required: false
    },
    maxlength: {
      type: String,
      default: '255'
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: '',
      showPassword: false
    }
  },
  methods: {
    parseValue () {
      this.$emit('onFill', this.value)
    }
  }
}
</script>

<style scoped>

</style>