<script setup>
import { ref, inject } from 'vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import CTextField from "@/components/fields/CTextField.vue"
import i18n from '@/i18n'
import CTextAreaField from "@/components/fields/CTextAreaField.vue";
import RecaptchaBox from "@/components/RecaptchaBox.vue";
import store from '@/store'

const http = inject('axios')
const toast = inject('toast')

const loading = ref(false)
const valid = ref(false)
const formValues = ref({
  name: '',
  email: '',
  about: '',
  recaptcha: null
})
const rules = {
  name: [
    (v) => !!v || i18n.global.t('contact.joinCreators.form.name.rules.mandatory')
  ],
  email: [
    (v) => !!v || i18n.global.t('contact.joinCreatorsf.form.email.rules.mandatory'),
    (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.global.t('contact.joinCreators.form.email.rules.mandatory')
  ],
  about: [
    (v) => !!v || i18n.global.t('contact.joinCreators.form.about.rules.mandatory')
  ]
}

const recaptcha = (value) => {
  formValues.value.recaptcha = value
}

const submit = async () => {
  loading.value = true

  await http.post('/contact/join-creators', formValues.value)
      .then(() => {
        submitted.value = true
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(() => { loading.value = false })
}
const close = () => {
  store.commit('joinCreatorsShow', false)
  setTimeout(() => {
    submitted.value = false
  }, 1000)
}

const submitted = ref(false)
</script>

<template>
  <div
    v-if="!submitted"
  >
    <v-form
      v-model="valid"
      class="pa-5"
      ref="joinCreators"
    >
      <CTextField
        v-model="formValues.name"
        class="pb-2"
        :label="i18n.global.t('contact.joinCreators.form.name.label')"
        :rules="rules.name"
        required
      />
      <CTextField
        v-model="formValues.email"
        class="pb-2"
        :label="i18n.global.t('contact.joinCreators.form.email.label')"
        :rules="rules.email"
        required
      />
      <CTextAreaField
        v-model="formValues.about"
        class="pb-2"
        :label="i18n.global.t('contact.joinCreators.form.about.label')"
        :rules="rules.about"
        maxlength="1000"
        counter
        required
      />
      <RecaptchaBox
        class="pb-9"
        @verify="recaptcha"
      />
      <v-btn-group
        divided
        variant="flat"
      >
        <CButtonFormSubmit
          :enabled="!!(valid && formValues.recaptcha)"
          :label="i18n.global.t('contact.joinCreators.form.actions.submit')"
          :loading="loading"
          @click.prevent="submit"
        />
        <CFormCancelButton
          :label="i18n.global.t('contact.joinCreators.form.actions.cancel')"
          @cancel="$store.commit('joinCreatorsShow', false)"
        />
      </v-btn-group>
    </v-form>
  </div>
  <div
    v-else
    class="pa-5"
  >
    <div
      class="submitted"
    >
      <v-icon
        class="mr-5"
        color="green"
        size="x-large"
      >
        mdi-check
      </v-icon>
      <h3>
        {{ i18n.global.t('contact.joinCreators.form.messages.success') }}
      </h3>
    </div>
    <v-btn-group
      class=""
      divided
      variant="flat"
    >
      <CFormCancelButton
        :label="$t('contact.joinCreators.form.actions.close')"
        @cancel="close"
      />
    </v-btn-group>
  </div>
</template>

<style scoped>
.submitted {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>