import { RouteRecordRaw } from 'vue-router'
import AffiliateDashboard from '../views/AffiliateDashboard.vue'
import AffiliateInviteLink from '../views/AffiliateInviteLink.vue'
import RegisterPage from '@/views/register/RegisterPage.vue'

export const routes: Array<RouteRecordRaw> = [
  { path: '/discount/:token', component: AffiliateInviteLink, name: 'AffiliateInviteLink', meta: { hideNavigation: true, top: true }},
  { path: '/affiliate/register', name: 'AffiliateRegisterPage', component: RegisterPage },
  {
    path: '/affiliate',
    name: 'AffiliateView',
    component: AffiliateDashboard,
    meta: {
      module: 'Affiliate',
      requiresAuth: true,
      inAuthMenu: true,
      icon: 'mdi-image-filter-hdr',
      order: 3
    }
  },
]
