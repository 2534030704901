<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  required: {
    type: Boolean,
    default: false
  }
})

</script>

<template>
  <v-textarea
    variant="outlined"
  >
    <template #label>
      {{ props.label }}&nbsp;<span v-if="props.required" class="red--text"><strong>*</strong></span>
    </template>
  </v-textarea>
</template>

<style scoped>

</style>