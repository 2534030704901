<script setup lang="ts">
import { computed } from 'vue'
import store from '@/store'
import i18n from '@/i18n'

const checkoutData = computed(() => {
  return store.getters.checkoutProcessEntity
})

const selectVariant = (item) => {
  const variants = store.state.globalDialogs.checkoutProcess.entity.variants

  for (let i in variants) {
    if (variants[i].minutes === item.minutes) {
      store.state.globalDialogs.checkoutProcess.entity.variants[i].selected = true
    } else {
      store.state.globalDialogs.checkoutProcess.entity.variants[i].selected = false
    }
  }
  store.state.globalDialogs.checkoutProcess.entity.content.title = i18n.global.t('checkout_old.bookings.title', { minutes: item.minutes })
  store.state.globalDialogs.checkoutProcess.entity.price = item.price
  store.state.globalDialogs.checkoutProcess.entity.data = { minutes: item.minutes }
}
</script>

<template>
  <div>
    <div
      class="header"
    >
      <div class="line"></div>
      <v-icon
        size="80"
      >
        mdi-calendar-export-outline
      </v-icon>
      <div class="line"></div>
    </div>

    <v-row
      class="options"
    >
      <v-col
        v-for="(item, i) in checkoutData.variants"
        :key="'timeSlots-' + i"
        class="text-center pa-2"
        cols="12"
      >
        <div
          class="box"
          :class="item.selected ? ' selected' : ''"
          @click="selectVariant(item)"
        >
          <span>{{ item.minutes }} min</span>
          <span class="amount">{{ item.price }}$</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 25px 0;
  text-align: center;

  .line {
    height: 1px;
    width: 30%;
    background-color: $grey-02;
  }

  span {
    margin-bottom: 5px;

    &.amount {
      font-size: 25px;
      font-weight: 500;
    }
  }
}

.options {
  margin: 30px 0 40px 0;

  .box {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-03;
    border-radius: 5px;
    padding: 25px 0;

    .amount {
      font-size: 25px;
      font-weight: 500;
    }

    &.selected {
      background-color: #360d04;
      border-color: #ff2f00;
    }

    &:hover {
      background-color: #360d04;
      border-color: #ff2f00;
      cursor: pointer;
    }
  }
}
</style>