<script setup lang="ts">
import { computed, defineProps } from 'vue'
import store from '@/store'
import i18n from '@/i18n'
import subscription from '@/helpers/subscription'

const checkoutData = computed(() => {
  return store.getters.checkoutProcessEntity
})

const props = defineProps({
  prices: {
    type: Array,
      required: true
  }
})

const price = computed(() => {
  const lang =  subscription.prices(checkoutData.value.prices, checkoutData.value.frequency === 'yearly')
  return i18n.global.t(lang.lang, lang.items)
})
</script>

<template>
  <div
    class="wrp"
  >
    <h2>
      <v-icon
        v-if="checkoutData.content.icon"
        class="mr-2"
        size="small"
      >
        {{ checkoutData.content.icon }}
      </v-icon>
      <span v-html="checkoutData.content.title" />
    </h2>

    <ul>
      <li v-if="checkoutData.frequency === 'yearly'"><v-icon class="mr-2">mdi-check</v-icon>{{ i18n.global.t('checkout.subscriptions.advantages.yearly[0]') }}</li>
      <li
        v-for="(item, i) in checkoutData.content.advantages"
        :key="'adv-' + i"
        :class="item.disabled ? 'disabled' : ''"
      >
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        <span>{{ item.text }}</span>
      </li>
    </ul>
    <div class="price" v-html="price"/>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.wrp {
  text-align: left;

  h2 {
    padding: 0;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 10px;
  }
}

ul {

  li {
    list-style: none;
    text-align: left;
    margin: 4px 0;

    &.disabled {
      color: $grey-02;
    }
  }
}

.price {
  text-align: left;
  font-size: 26px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 36px;
  margin-bottom: 8px;

  :deep(.nl) {
    display: block;
  }

  :deep(.small) {
    font-size: 15px;
    font-weight: normal;
  }

  .total {
    font-size: 28px;
  }

  .freq {
    font-size: 17px;
    font-weight: 300;
  }
}
</style>