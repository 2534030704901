<script setup>
import { defineProps, defineEmits } from 'vue'
import i18n from '@/i18n'

const props = defineProps({
  bankAccount: {
    type: Object,
    required: false
  }
})

const emit = defineEmits(['update'])
</script>

<template>
  <v-card>
    <v-card-title
      class="pt-3"
    >
      {{ props.bankAccount.bank_name }}
      <v-chip
        class="ml-2"
        :color="props.bankAccount.status === 'new' ? 'green' : 'red'"
        :prepend-icon="props.bankAccount.status === 'new' ? i18n.global.t('settings.bank.bankSlipBox.statuses.active.icon') : i18n.global.t('settings.bank.bankSlipBox.statuses.disabled.icon')"
        size="small"
      >
        {{ props.bankAccount.status === 'new' ? i18n.global.t('settings.bank.bankSlipBox.statuses.active.label') : i18n.global.t('settings.bank.bankSlipBox.statuses.disabled.label') }}
      </v-chip>
    </v-card-title>
    <v-card-subtitle>
      **** {{ props.bankAccount.last4 }}
    </v-card-subtitle>
    <v-card-actions
      class="pt-0"
    >
      <v-btn
        class="mt-4"
        @click="emit('update', true)"
      >
        {{ i18n.global.t('settings.bank.bankSlipBox.actions.update') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>