<script setup lang="ts">
import { ref, defineProps, computed, inject } from 'vue'
import i18n from "@/i18n";
import currency from "@/helpers/currency";
import Store from "@/store";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    required: true
  },
  isLastItem: {
    type: Boolean,
    default: false
  }
})

const disabledItemDelete = inject('disabledItemDelete')

const imagesUrl = process.env.VUE_APP_IMAGES_URL

const numberOfItems = computed(() => {
  return Store.getters.cartItems.length
})

const total = computed(() => {
  return props.item.quantity * props.item.product.prices[0].price
})

const confirming = ref(false)

const confirmDelete = () => {
  confirming.value = true
}
const cancelDelete = () => {
  confirming.value = false
}

const deleteItem = () => {
  Store.commit('removeFromCart', props.index)
  confirming.value = false

  if (!numberOfItems.value) {
    Store.commit('cartShow', false)
  }
}
</script>

<template>
  <div
    class="cart-item"
  >
    <div
      class="d-flex align-center"
    >
      <div
        class="image"
        v-bind:style="{ 'background-image': 'url(' + imagesUrl + item.product.images[0].url + ')' }"
      />

      <div
        class="body"
      >
        <div
          class="top"
        >
          <div
            class="title"
          >
            {{ item.product.meta.data.title }}
          </div>
          <div
            v-if="!disabledItemDelete"
            class="pr-0"
          >
            <v-btn
              size="25"
              icon
              flat
              color="red-accent-3"
              variant="tonal"
              @click.prevent="deleteItem"
            >
              <v-icon
                size="12"
              >
                mdi-close
              </v-icon>
            </v-btn>
            <div
              class="d-none"
            >
              <v-btn
                class="mr-1"
                color="red-accent-3"
                size="x-small"
                flat
                @click.prevent="deleteItem"
              >
                {{ i18n.global.t('cart.item.actions.remove') }}
              </v-btn>
              <v-btn
                size="x-small"
                flat
                @click.prevent="cancelDelete"
              >
                {{ i18n.global.t('cart.item.actions.cancel') }}
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="bot"
        >
          <div
            class="subtitle"
          >
            <span class="d-block">{{ i18n.global.t('cart.item.price') }}</span>
            <span class="d-block">{{ currency.formatAmount(item.product.prices[0].price) }}</span>
          </div>
          <div
            class="subtitle"
          >
            <span class="d-block">{{ i18n.global.t('cart.item.quantity') }}</span>
            <span class="d-block">{{ item.quantity }}</span>
          </div>
          <div
            class="subtitle"
          >
            <span class="d-block">{{ i18n.global.t('cart.item.total') }}</span>
            <span class="d-block font-weight-bold">{{ currency.formatAmount(total) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.cart-item {
  position: relative;
  padding: 15px 10px;
  width: 100%;

  .image {
    width: 75px;
    max-width: 75px;
    height: 75px;
    border-radius: 4px;
    margin-right: 15px;
    background-size: cover;
    background-position: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    flex-grow: 1;
    height: 75px;
    font-size: 16px;

    .top {
      display: flex;
      justify-content: space-between;
    }

    .bot{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .sub {
      overflow-x: hidden;
      color: $lighter-text;
      width: 100%;
      font-size: 13px;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 12px;
      line-height: 17px;
    }
  }
}
</style>