<script setup lang="ts">
import i18n from '@/i18n'
import router from '@/router'

const languages = [
  'en',
  'fr',
  'es'
]

const durations = [
  'up-to-1h',
  '1h-to-4h',
  '4h-to-1d',
  '1d-and-more'
]

const categories = [
  'trails',
  'beach',
  'water',
  'night'
]
</script>

<template>
  <v-card
    class="filters"
    variant="outlined"
  >
    <div
      class="section header outfit-regular"
    >
      {{ i18n.global.t('filters.destination.' + router.currentRoute.value.params.destination) }}
    </div>
    <div
      class="soon mx-4 mt-4"
    >
      {{ i18n.global.t('filters.soon') }}
    </div>
    <div
      class="section"
    >
      <v-card-title>
        {{ i18n.global.t('filters.categories.title') }}
      </v-card-title>
      <ul
        class="options mx-3"
      >
        <li
          v-for="(item, i) in categories"
          :key="'language-' + i"
        >
          <v-checkbox
            density="compact"
            hide-details
            disabled
            :label="i18n.global.t('filters.categories.options.' + item)"
          />
        </li>
      </ul>
    </div>
    <v-divider class="ma-4" />
    <div
      class="section"
    >
      <v-card-title>
        {{ i18n.global.t('filters.languages.title') }}
      </v-card-title>
      <ul
        class="options mx-3"
      >
        <li
          v-for="(item, i) in languages"
          :key="'language-' + i"
        >
          <v-checkbox
            density="compact"
            hide-details
            disabled
            :label="i18n.global.t('filters.languages.options.' + item)"
          />
        </li>
      </ul>
    </div>
    <v-divider class="ma-4" />
    <div
      class="section"
    >
      <v-card-title>
        {{ i18n.global.t('filters.duration.title') }}
      </v-card-title>
      <ul
        class="options mx-3"
      >
        <li
          v-for="(item, i) in durations"
          :key="'language-' + i"
        >
          <v-checkbox
            density="compact"
            hide-details
            disabled
            :label="i18n.global.t('filters.duration.options.' + item)"
          />
        </li>
      </ul>
    </div>
    <v-divider class="ma-4" />
    <div
      class="section mb-6"
    >
      <v-card-title>
        {{ i18n.global.t('filters.price.title') }}
      </v-card-title>
      <v-slider
        model-value="20"
        min="15"
        max="1000"
        disabled
        hide-details
        tick-size="2"
        class="mx-6"
      ></v-slider>
    </div>
  </v-card>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.filters {
  border-color: #bababa;

  .section {

  }

  .header {
    background-color: #e8e8e8;
    color: #4e4e4e;
    font-size: 18px;
    font-weight: 600;
    padding: 18px 16px;
  }

  .soon {
    font-size: 15px;
    font-style: italic;
    color: $grey-02;
  }

  ul.options {
    list-style: none;

    li {

    }
  }
}

.txt-price {
  color: #ccc;
  font-size: 14px;
}
</style>