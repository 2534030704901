import { RouteRecordRaw } from 'vue-router'
import SettingsView from '@/modules/settings/views/SettingsView.vue'
import SettingsAccountVerifyView from '@/modules/settings/views/SettingsAccountVerifyView.vue'
import CalendlyRedirectView from '@/modules/settings/views/CalendlyRedirectView.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      requiresAuth: true,
      module: 'settings',
      inAuthMenu: true,
      icon: 'mdi-cog'
    }
  },
  {
    path: '/settings/account/verify',
    name: 'SettingsAccountVerifyView',
    component: SettingsAccountVerifyView,
    meta: {
      requiresAuth: true,
      module: 'settings'
    }
  },
  {
    path: '/calendly/redirect',
    name: 'CalendlyRedirectView',
    component: CalendlyRedirectView,
    meta: {
      requiresAuth: true,
      module: 'settings'
    }
  }
]