<script setup lang="ts">
import { onMounted, computed } from 'vue'
import PaypalAccountForm from '@/components/paypal/PaypalAccountForm.vue'
import Store from '@/store'
import router from '@/router'

onMounted(() => {
  if (user.value.paypal_account) {
    router.push({ name: 'AffiliateView' })
  }
})

const user = computed(() => {
  return Store.getters.getUser
})
</script>

<template>
  <v-container
    class="wrapper"
  >
    <PaypalAccountForm
      :paypal-account="user.paypal_account"
    />
  </v-container>
</template>

<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
}
</style>