
export default {

  printSuccessMessage (response: any) {
    return response
  },

  messageBagToSingleMessage (messageBag: object|string) {
    let string = ""

    if (typeof messageBag === 'string') {
        return messageBag
    } else {
      for (const e in messageBag) {
        const array:[] = messageBag[e as keyof object]

        for (let i = 0; i < array.length; i++) {
          string += array[i] + ' '
        }
      }
      return string.substring(0, string.length - 1)
    }
  }
}