<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  size: {
    type: Number,
    default: 100
  },
  user: {
    type: Object,
    required: true
  },
  channel: {
    type: [Object, undefined],
    required: false
  },
  showName: {
    type: Boolean,
    default: true
  }
})

const imagesUrl = process.env.VUE_APP_CONTENT_URL
</script>

<template>
  <div
    class="user-box"
    :class="channel !== undefined ? 'cursor-pointer' : ''"
    @click="channel !== undefined ? $router.push({ name: 'ChannelView', params: { slug: channel.slug } }) : ''"
  >
    <div>
      <v-avatar
        :size="props.size"
        icon
      >
        <v-img
          :src="imagesUrl + props.user.picture_url"
          cover
        />
      </v-avatar>
      <div
        v-if="props.showName"
        class="text"
      >
        <p class="name">{{ props.user.name }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-box {
  display: flex;
  width: auto;

  &.cursor-pointer {
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    .text {
      margin-top: 3px;
      font-family: "Tilt Warp", sans-serif;
      line-height: 24px;

      .slug {
        font-size: 20px;
        font-weight: bold;
      }

      .name {
        font-size: 16px;
        font-weight: normal;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>