import { RouteRecordRaw } from 'vue-router'
import ProductListPage from '@/modules/products/views/ProductListPage.vue'
import ProductDetailsPage from '@/modules/products/views/ProductDetailsPage.vue'

const validDestinations = [
  'punta-cana',
  'las-terrenas',
  'santo-domingo'
];

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:destination',
    name: 'ProductListPage',
    component: ProductListPage,
    meta: {
      module: 'Products',
      icon: 'mdi-image-filter-hdr',
      order: 3
    },
    beforeEnter: async (to, from, next) => {
      const destination = String(to.params.destination ?? '');

      const isValid = destination ? validDestinations.includes(destination) : null;

      if (isValid) {
        next();
      } else {
        next({ name: 'PageNotFound' });
      }
    }
  },
  {
    path: '/:destination/:uuid',
    name: 'ProductDetailsPage',
    component: ProductDetailsPage,
    meta: {
      module: 'Products',
      icon: 'mdi-image-filter-hdr',
      order: 3
    }
  },
]
