import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import i18n from './i18n'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { ModuleRegistrar } from '@/modules/core'
import { Affiliate, Products, Settings } from '@/modules'
import 'video.js/dist/video-js.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { createMetaManager } from 'vue-meta'

const app = createApp(App)

// i18n
app.use(i18n)

// Plugins
import { registerPlugins } from '@/plugins'
registerPlugins(app)

// Modules Registration
const registrar = new ModuleRegistrar(router)
// TODO: Complete the modules logic so it doesn't just set routes
registrar.addRoutes(Affiliate.routes)
registrar.addRoutes(Products.routes)
registrar.addRoutes(Settings.routes)

app.component('VueDatePicker', VueDatePicker)

app.use(store)
app.use(router)
app.use(PrimeVue)
app.use(createMetaManager())
app.use(VueSmoothScroll, {
  duration: 650,
  offset: -100
})
await router.isReady()
app.mount("#app")
