<script setup>
import { defineProps, defineEmits } from 'vue'
import i18n from '@/i18n'

const props = defineProps({
  card: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['updateCard'])
const updateCard = () => {
  emit('updateCard')
}
</script>

<template>
  <v-card
    class="pa-0"
    variant="tonal"
  >
    <v-card-title
      class="mb-2"
    >
      {{ props.card.brand.toUpperCase() }}
    </v-card-title>
    <v-card-subtitle>
      **** **** **** {{ props.card.last4 }}
    </v-card-subtitle>
    <v-card-actions>
      <v-btn
        class="mt-1"
        @click="updateCard"
      >
        {{ i18n.global.t('cards.actions.update') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>

</style>
