<script setup lang="ts">
import { ref, inject, onMounted, computed } from 'vue'
import router from "@/router";
import ProductBookingCard from '@/modules/products/components/ProductBookingCard.vue'
import i18n from '@/i18n'

const http = inject('axios')
const toast = inject('toast')

const imagesUrl = process.env.VUE_APP_IMAGES_URL

onMounted(async () => {
  window.scrollTo(0, 0)

  await fetchProduct()
})

const destination = computed(() => {
  return router.currentRoute.value.params.destination
})

const loading = ref(false)
const product = ref(null)

const fetchProduct = async () => {
  loading.value = true

  await http.get('/product/' + router.currentRoute.value.params.uuid)
    .then(response => {
      product.value = response.data.data
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => { loading.value = false })
}

</script>

<template>
  <v-container
    class="wrapper pt-0"
  >
    <div
      class="navigation"
    >
      <a href="javascript:void(0)" @click="router.push({ name: 'HomePage' })">{{ i18n.global.t('menu.Home') }}</a> / <a href="javascript:void(0)" @click="router.push({ name: 'ProductListPage', params: { destination } })">{{ i18n.global.t('products.navigation.' + destination) }}</a>
    </div>

    <div
      v-if="product"
    >
      <div
        v-bind:style="{ 'background-image': 'url(' + imagesUrl + product.images[0].url + ')' }"
        class="header-image mb-5"
      />
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <h1>{{ product.meta.data.title }}</h1>
          <p v-html="product.meta.data.description" />

          <h2>{{ i18n.global.t('products.inclusions.title') }}</h2>
          <ul
            class="mb-4"
          >
            <li
              v-for="(item, i) in product.inclusions"
              :key="i"
            >
              <v-icon
                size="sm"
              >
                mdi-{{ item.icon }}
              </v-icon>
              <span class="text">{{ i18n.global.t('inclusions.' + item.label) }}</span>
            </li>
          </ul>
          <h2>{{ i18n.global.t('products.duration.title') }}</h2>
          <p>{{ i18n.global.t('durations.' + product.duration.label) }}</p>
          <v-row
            class="my-5"
          >
            <v-col
              v-for="(image, i) in product.images"
              :key="i"
              class="pa-2"
              cols="12"
              sm="4"
            >
              <div
                class="images-section"
                v-bind:style="{ 'background-image': 'url(' + imagesUrl + image.url + ')' }"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <ProductBookingCard :item="product" />
        </v-col>
      </v-row>
    </div>
    <div
      v-else
    >
      <v-skeleton-loader
        type="image"
        class="skeleton-header"
      />

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-skeleton-loader
            class="mt-5"
            type="article"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-skeleton-loader
            class="mt-5"
            type="card"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

h1 {
  font-size: 42px;
  margin-bottom: 10px;
  line-height: 50px;
}

.navigation {
  margin: 10px 0;

  a, a:link, a:active, a:visited {
    text-decoration: none;
    color: $lighter-text;
    font-size: 15px;
  }
}

:deep(.skeleton-header) {

  .v-skeleton-loader__bone, .v-skeleton-loader__image {
    height: 280px;
  }
}

.header-image {
  width: 100%;
  height: 280px;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.images-section {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

ul {
  list-style-type: none;

  li {
    padding: 2px 0;
    color: $lighter-text;

    .v-icon {
      color: $lighter-text;
    }
  }
}

.text {
  padding-left: 10px;
  font-size: 15px;
}
</style>