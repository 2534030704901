<script setup lang="ts">
import { ref, inject, onMounted } from 'vue'
import i18n from '@/i18n'

const http = inject('axios')
const toast = inject('toast')

onMounted(async () => {
  await fetchBookings()
})

const loading = ref(true)
const bookings = ref([])

const headers = [
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.name.label'),
    key: 'name'
  },
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.email.label'),
    key: 'email'
  },
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.status.label'),
    key: 'status',
    align: 'center'
  }
]

const statuses = [
  {
    value: 'pending',
    title: i18n.global.t('_globals.statuses.pending')
  },
  {
    value: 'booked',
    title: i18n.global.t('_globals.statuses.booked')
  },
  {
    value: 'done',
    title: i18n.global.t('_globals.statuses.done')
  },
  {
    value: 'canceled',
    title: i18n.global.t('_globals.statuses.canceled')
  }
]

const fetchBookings = async () => {
  loading.value = true

  await http.get('/booking/request')
    .then(response => {
      bookings.value = response.data.data
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { loading.value = false })
}

const icon = (status) => {
  switch (status) {
    case "pending": return 'mdi-calendar-clock-outline';
    case "booked": return 'mdi-calendar-lock';
    case "done": return 'mdi-calendar-multiple-check';
    case "canceled": return 'mdi-calendar-remove';
  }
}

const updateStatus = async (id, status) => {
  await http.patch('/booking/request/' + id, {
    status
  })
    .catch(error => {
      toast.error(error.response.data.message)
    })
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="bookings"
    :loading="loading"
  >
    <template v-if="!loading" v-slot:no-data>
      {{ i18n.global.t('settings.booking.list.table.noData') }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-select
        v-model="item.props.title.status"
        :items="statuses"
        :prepend-inner-icon="icon(item.props.title.status)"
        variant="outlined"
        density="compact"
        hide-details
        @update:model-value="updateStatus(item.props.title.id, item.props.title.status)"
      />
    </template>
  </v-data-table>
</template>

<style scoped lang="scss">
.item-option {

  &:hover {
    cursor: pointer;
  }
}
</style>