<script setup lang="ts">

</script>

<template>
  <v-card
    flat
    rounded
    variant="outlined"
  >
    <ul>
      <li
        class="pt-0"
      >
        <v-icon
          color="grey-darken-2"
          size="small"
        >
          mdi-calendar
        </v-icon>
        <span>
          Cancel up to 24h before the event
        </span>
      </li>
      <li>
        <v-icon
          color="grey-darken-2"
          size="small"
        >
          mdi-truck
        </v-icon>
        <span>
          Transport service to pick you at your hotel
        </span>
      </li>
      <li
        class="pb-0"
      >
        <v-icon
          color="grey-darken-2"
          size="small"
        >
          mdi-lock
        </v-icon>
        <span>
          Security and piece of mind guaranteed
        </span>
      </li>
    </ul>
  </v-card>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.v-card {
  border-color: $card-outline;
  color: $grey-02;
  font-size: 15px;
  padding: 22px 7px;
}

ul {
  li {
    display: flex;
    align-items: start;
    padding: 7px 15px;

    .v-icon {
      margin-right: 12px;
    }

    span {
      line-height: 20px;
    }
  }
}
</style>