import Toast, { useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const useIt = useToast()

export default {
  plugin: Toast,
  useIt,
  options: {
    position: 'bottom-right',
    transition: 'Vue-Toastification__fade',
    maxToasts: 5,
    hideProgressBar: true,
    timeout: 5300
  }
}