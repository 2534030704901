<template>
  <vue-recaptcha :sitekey="key" ref="recaptcha" @verify="verify" :language="$i18n.locale" />
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: "RecaptchaBox",
  components: { VueRecaptcha },
  data() {
    return {
      key: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
    }
  },
  methods: {
    verify(value) {
      this.$emit('verify', value)
    }
  }
}
</script>

<style scoped>

</style>