<script setup>
import { computed } from 'vue'
import store from '@/store'
import CDialogFrame from '@/components/dialogs/CDialogFrame.vue'
import CheckoutProcess from '@/components/checkout_old/CheckoutProcess.vue'

const show = computed(() => {
  return store.getters.checkoutProcessShow
})
</script>

<template>
  <CDialogFrame
    :show="show"
  >
    <template v-slot:component>
      <CheckoutProcess/>
    </template>
  </CDialogFrame>
</template>

<style scoped>

</style>
