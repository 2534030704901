/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
// Plugins
import vuetify from './vuetify'
// import gtm from './gtm'
import toast from './toast'
import axios from './axios'
import { loadStripe } from '@stripe/stripe-js'

// Types
import type { App } from 'vue'

export function registerPlugins (app: App) {
  app.use(vuetify)
  app.use(toast.plugin, toast.options)
  app.config.globalProperties.$toast = toast.useIt
  app.provide('toast', toast.useIt)
  app.use({
    install: (app: App) => {
      const api = process.env.VUE_APP_API_URL ?? ""
      const http = axios(api)
      // Vue 2 Way
      app.config.globalProperties.$http = http
      // Vue 3 Way (need both until no Option API components are used in the app)
      app.provide('axios', http)
    }
  })
  app.use({
    install: async (app: App) => {
      const key = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? ""
      const stripe = await loadStripe(key)
      app.provide('stripe', stripe)
    }
  })
  if (process.env.VUE_APP_ENV === 'production') {
    // app.use(gtm)
  }
}
