<script setup lang="ts">
import { computed } from 'vue'
import i18n from '@/i18n'
import currency from '@/helpers/currency'
import timeFormats from '@/helpers/timeFormats'
import store from '@/store'
import UserAvatar from '@/components/user/UserAvatar.vue'

const checkoutData = computed(() => {
  return store.getters.checkoutProcessEntity
})
const checkoutCurrency = computed(() => {
  return store.getters.checkoutProcessCurrency
})
const totalDuration = computed(() => {
  let total = 0

  for (let i = 0; i < checkoutData.value.content.items.length; i++) {
    total += parseInt(checkoutData.value.content.items[i].duration)
  }

  return timeFormats.secondsToHms(total)
})
</script>

<template>
  <div>
    <div
      class="avatar"
    >
      <UserAvatar
        :user="checkoutData.creator"
        :size="32"
      />
    </div>
    <v-divider class="my-5" />
    <h2
      class="mb-4"
    >
      <v-icon
        class="mr-4"
        size="small"
      >
        mdi-school
      </v-icon>
      <div>
        <span>{{ checkoutData.content.title }}</span>
      </div>
    </h2>

    <p>{{ checkoutData.content.description }}</p>

    <div
      class="totals"
    >
      <p>
        <span>{{ i18n.global.t('checkout.totalTime') }}</span>
        <span class="time">{{ totalDuration }}</span>
      </p>
      <p>
        <span>{{ i18n.global.t('checkout.price') }}</span>
        <span class="price">{{ currency.formatAmount(parseFloat(checkoutData.price), checkoutCurrency) }}</span>
      </p>
    </div>

    <div
      class="items"
    >
      <div
        v-for="(item, i) in checkoutData.content.items"
        :key="'data-item-' + i"
        class="box"
      >
        <span class="title">{{ item.title }}</span>
        <span class="description">{{ item.description }}</span>
        <div class="duration">
          <span>{{ timeFormats.secondsToHms(item.duration) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

h2 {
  display: flex;
  align-items: start;
  margin-bottom: 22px;
  padding-bottom: 0;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.items {
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid $grey-01;
  border-bottom: 1px solid $grey-01;
  padding: 5px 0;

  .box {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .title {
      font-weight: bold;
    }

    .description {
      color: $lighter-text;
    }

    .time {
      margin-top: 2px;
      font-size: 13px;
    }
  }
}

.totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  p {
    margin-bottom: 8px;

    span {
      display: block;
    }
    .price {
      font-weight: 500;
    }
  }
}

.order-info {

  span {
    font-weight: bold;
  }
}
</style>