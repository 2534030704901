<script setup>
import { ref } from 'vue'
import CreateInviteTokenForm from '@/modules/settings/components/AccountsAdminTab/CreateInviteTokenForm.vue'
import PendingInviteTokens from '@/modules/settings/components/AccountsAdminTab/PendingInviteTokens.vue'

const invitesList = ref(null)
const fetchInvites = async () => {
  await invitesList.value.fetchInvites()
}
</script>

<template>
  <v-container>
    <h1 class="mb-10">{{ $t('settings.userAccounts.title') }}</h1>

    <CreateInviteTokenForm
      class="mb-8"
      @after-submit="fetchInvites"
    />
    <PendingInviteTokens
      ref="invitesList"
    />
  </v-container>
</template>

<style scoped>

</style>