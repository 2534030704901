import store from '@/store'

type currency = {
  code: string,
  symbol: string,
  symbol_position: string
}

export default {

  formatAmount (amount: number, currency?: currency) {
    const c = currency ?? store.getters.checkoutProcessCurrency

    if (c.symbol_position === 'post') {
      return amount.toFixed(2) + c.symbol
    } else {
      return c.symbol + amount.toFixed(2)
    }
  }
}