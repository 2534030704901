<template>
  <v-form
    v-model="valid"
    class="pa-5"
    ref="forgotPasswordForm"
  >
    <CTextField
      class="mb-2"
      :label="$t('signInForm.forgotPassword.email.label')"
      :rules="rules.email"
      maxlength="255"
      required
      @onFill="(v) => updateFormData('email', v)"
    />

    <RecaptchaBox
      class="pb-5"
      @verify="recaptcha"
    />

    <v-btn-group
      class="mt-2"
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :label="$t('signInForm.forgotPassword.actions.sendCode')"
        :enabled="!!(valid && formValues.recaptcha)"
        :loading="loading"
        @click.prevent="submit"
      />
      <CFormCancelButton
        :label="$t('signInForm.forgotPassword.actions.cancel')"
        @cancel="$emit('toggleDisplay', false)"
      />
    </v-btn-group>
  </v-form>
</template>

<script>
import CTextField from '@/components/fields/CTextField.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import RecaptchaBox from '@/components/RecaptchaBox.vue'

import requestResponses from '@/helpers/requestResponses'

export default {
  name: 'ChangePasswordForm',
  components: { RecaptchaBox, CButtonFormSubmit, CFormCancelButton, CTextField },
  data () {
    return {
      valid: false,
      loading: false,
      formValues: {
        email: '',
        recaptcha: null
      },
      rules: {
        email: [
          (v) => !!v || this.$t('signInForm.forgotPassword.email.rules.mandatory'),
          (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('signInForm.forgotPassword.email.rules.valid')
        ],
      }
    }
  },
  methods: {
    updateFormData (field, value) {
      this.formValues[field] = value
    },
    recaptcha(value) {
      this.formValues.recaptcha = value
    },
    async submit () {
      this.loading = true

      this.$http.post('/auth/forgot-password', this.formValues)
        .then(() => {
          this.$emit('success', this.formValues.email)
        })
        .catch(error => {
          this.$toast.error(requestResponses.printSuccessMessage(error.response.data.message))
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>