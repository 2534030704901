<template>
  <v-container>
    <v-alert
      v-if="!user.email_verified_at"
      class="mb-7"
      icon="mdi-alert"
    >
      {{ $t('settings.userProfile.updateForm.accountNotVerified') }}
    </v-alert>

    <h1 class="mb-10">{{ $t('settings.userProfile.title') }}</h1>

    <div
      class="d-none flex-column justify-center align-center"
    >
      <v-avatar
          class="my-0"
          size="140"
          variant="flat"
      >
        <v-img
          :src="profilePicture"
          cover
        >
        </v-img>
      </v-avatar>

      <ImageUpload
        class="mt-4 mb-10"
        path="images/user-pictures/"
        prefix="u_pic_"
        :old_file="avatar.old_picture_url"
        @update="setPicture"
      />
    </div>

    <v-form
      v-model="form.valid"
    >
      <CTextField
        class="mb-2"
        :label="$t('settings.userProfile.updateForm.name.label')"
        :rules="form.rules.name"
        maxlength="255"
        :fill-value="user.name"
        @onFill="(v) => updateFormData('name', v)"
      />

      <CTextField
        v-model="user.email"
        class="mb-2"
        :label="$t('settings.userProfile.updateForm.email.label')"
        :append-inner-icon="!user.email_verified_at ? 'mdi-email-alert' : ''"
        disabled
        hide-details
      />

      <div
        class="links-group"
      >
        <a
          class="d-block mb-7"
          href="javascript:void(0)"
          @click="displayUpdateEmailForm = true"
        >
          {{ $t('settings.userProfile.updateForm.actions.updateEmail') }}
        </a>
        <a
          v-if="!user.email_verified_at"
          class="d-block mb-7"
          href="javascript:void(0)"
          @click="displayResendVerificationEmailForm = true"
        >
          {{ $t('settings.userProfile.updateForm.actions.resendEmail') }}
        </a>
      </div>


      <CActionButton
        :label="$t('settings.userProfile.changePasswordForm.title')"
        elevation="0"
        @click="displayChangePasswordDialog = true"
      />

      <v-divider
        class="my-4"
      />

      <CButtonFormSubmit
        :label="$t('settings.userProfile.updateForm.actions.submit')"
        :enabled="!!(form.valid && formHasChanged)"
        :loading="form.loading"
        size="large"
        @click.prevent="update"
      />
    </v-form>

    <!-- Change password dialog -->
    <CDialogFrame
      :show="displayChangePasswordDialog"
      :title="$t('settings.userProfile.changePasswordForm.title')"
      @toggleDisplay="(v) => displayChangePasswordDialog = v"
    >
      <template v-slot:component>
        <ChangePassword
          @closeModal="displayChangePasswordDialog = false"
        />
      </template>
    </CDialogFrame>

    <!-- Update email address dialog -->
    <CDialogFrame
      :show="displayUpdateEmailForm"
      :title="$t('settings.userProfile.updateEmailForm.title')"
      @toggleDisplay="(v) => displayUpdateEmailForm = v"
    >
      <template v-slot:component>
        <UpdateEmailForm
          @closeModal="displayUpdateEmailForm = false"
        />
      </template>
    </CDialogFrame>

    <!-- Resend verification email dialog -->
    <CDialogFrame
        :show="displayResendVerificationEmailForm"
        :title="$t('settings.userProfile.resendEmailForm.title')"
        @toggleDisplay="(v) => displayResendVerificationEmailForm = v"
    >
      <template v-slot:component>
        <ResendVerificationEmailForm
          @closeModal="displayResendVerificationEmailForm = false"
        />
      </template>
    </CDialogFrame>
  </v-container>
</template>

<script>
import CDialogFrame from '@/components/dialogs/CDialogFrame.vue'
import ChangePassword from '@/modules/settings/components/UserProfileTab/ChangePasswordForm.vue'
import CTextField from '@/components/fields/CTextField.vue'
import CActionButton from '@/components/buttons/CActionButton.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import requestResponses from '@/helpers/requestResponses'
import UpdateEmailForm from "@/modules/settings/components/UserProfileTab/UpdateEmailForm.vue";
import ResendVerificationEmailForm
  from "@/modules/settings/components/UserProfileTab/ResendVerificationEmailForm.vue";
import ImageUpload from "@/components/images/ImageUpload.vue";

export default {
  name: 'UserProfile',
  components: {
    ImageUpload,
    ResendVerificationEmailForm,
    UpdateEmailForm, CButtonFormSubmit, CActionButton, CTextField, ChangePassword, CDialogFrame },
  data () {
    return {
      form: {
        valid: false,
        loading: false,
        rules: {
          name: [
            (v) => !!v || this.$t('subscribeForm.name.rules.mandatory')
          ],
        },
        values: {
          name: null,
          picture_url: null
        }
      },
      displayChangePasswordDialog: false,
      displayUpdateEmailForm: false,
      displayResendVerificationEmailForm: false,
      defaultAvatarPicture: process.env.VUE_APP_IMAGES_URL + '/placeholder-user-picture.png',
      avatar: {
        old_picture_url: null,
        imageLoadingPlaceholder: false,
      },
      updateValid: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    formHasChanged () {
      for (const i in this.form.values) {
        if (this.user && this.user[i] && this.user[i] !== this.form.values[i]) {
          return true
        }
      }
      return false
    },
    profilePicture() {
      let url = this.defaultAvatarPicture

      if (this.pictureUrl) {
        url = this.pictureUrl
      }

      return url
    },
    pictureUrl: {
      get: function () {
        return this.form.values.picture_url
      },
      set: function (value) {
        this.form.values.picture_url = value
      }
    }
  },
  mounted () {
    if (this.user) {
      this.form.values.name = this.user.name
    }
  },
  methods: {
    updateFormData (field, value) {
      this.form.values[field] = value
    },
    async update () {
      this.form.loading = true

      await this.$http.patch('/user', this.form.values)
        .then(response => {
          this.$store.commit('setUser', response.data.data)
          this.$toast.success(requestResponses.printSuccessMessage(this.$t('settings.userProfile.updateForm.messages.success')))
        })
        .catch(error => {
          this.$toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
        })
        .finally(() => { this.form.loading = false })
    },
    setPicture (data) {
      if (null !== data) {
        this.avatar.old_picture_url = data
        this.form.values.picture_url = data
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-container {
  //display: flex;
  // flex-direction: column;
  // align-items: center;
}

.links-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>