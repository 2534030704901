<script setup lang="ts">
import { defineProps, computed } from 'vue'
import CartItem from '@/components/cart/CartItem.vue'
import Store from '@/store'

const props = defineProps({
  disabledItemDeletion: {
    type: Boolean,
    default: false
  }
})

const items = computed(() => {
  return Store.getters.cartItems
})
</script>

<template>
  <div
    v-for="(item, i) in items"
    :key="i"
  >
    <CartItem :item="item" :index="i" :is-last-item="i === items.length - 1" />
  </div>
</template>

<style scoped lang="scss">

</style>