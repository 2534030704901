import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Store from '@/store'

import PageNotFound from '@/views/PageNotFound.vue'

import HomePage from '@/views/HomePage.vue'
import CheckoutPage from '@/views/checkout/CheckoutPage.vue'
import CheckoutThankYouPage from '@/views/checkout/CheckoutThankYouPage.vue'
import SignInView from '@/views/guest/SignInView.vue'
import RegisterBankPage from '@/views/register/RegisterBankPage.vue'
import TermsPage from '@/views/TermsPage.vue'
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue'

import i18n from '@/i18n'

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/checkout', name: 'CheckoutPageView', component: CheckoutPage, meta: { hideNavigation: true, top: true } },
  { path: '/checkout/thank-you', name: 'CheckoutThankYouPageView', component: CheckoutThankYouPage, meta: { hideNavigation: true, top: true } },
  { path: '/signin', name: 'SignInView',  component: SignInView, meta: { hideNavigation: true, top: true } },
  { path: '/register/bank', name: 'RegisterBankView', component: RegisterBankPage, meta: { requiresAuth: true } },
  { path: '/legal/terms', name: 'TermsPage', component: TermsPage },
  { path: '/legal/privacy', name: 'PrivacyPolicyPage', component: PrivacyPolicyPage },
  { path: '/404-not-found', name: 'PageNotFound', component: PageNotFound },
  { path: '/:pathMatch(.*)*', redirect: '/404-not-found' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const locale = to.meta.locale ?? null

  if (locale) {
    i18n.global.locale.value = 'en'
  }

  next()
})

router.beforeEach((to, from, next) => {
  const requiresAuthMeta = to.matched.some(record => record.meta.requiresAuth) || null
  const defaultRedirection = {
    name: 'ProductListPage',
    query: { redirect: to.fullPath }
  }

  window.scroll(0, 0)

  // Redirect parameter logic
  if (to.query.redirect) {
    if (Store.getters.isLoggedIn) {
      next({ path: to.query.redirect.toString() })
    } else {
      next(defaultRedirection)
    }
  }

  const hasAccess = (module: any, user: any) => {
      next()
  }

  if (requiresAuthMeta && !Store.getters.isLoggedIn) {
    next(defaultRedirection)
  } else if (!requiresAuthMeta && Store.getters.isLoggedIn) {
    next({ name: 'AffiliateView' })
  } else if (Store.getters.isLoggedIn && Store.getters.getUser === null) {
    Store.dispatch('getUser')
      .then(() => {
        const user = Store.getters.getUser
        const module = to?.meta?.module

        next()
      })
      .catch(() => {
        Store.dispatch('destroyToken')
          .then(() => {
            next(defaultRedirection)
          })
          .catch(() => {
            next(defaultRedirection)
          })

      })
  } else {
    next()
  }
})

export default router;
