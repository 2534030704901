<script setup lang="ts">
import { ref, defineProps, computed, inject } from 'vue'
import i18n from '@/i18n'
import DatePickerInput from '@/components/fields/DatePickerInput.vue'
import GoogleMapsAddressFinder from '@/components/fields/GoogleMapsAddressFinder.vue'
import currency from '@/helpers/currency'
import Store from '@/store'
import SecureBadgeForBooking from "@/components/security/SecureBadgeForBooking.vue";

const toast = inject('toast')

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const peopleNumberOptions = Array.from({ length: 30 }, (_, i) => i + 1);

interface LocationType {
  address: string;
  coordinates: { lat: number; lng: number }
}

// Required values
const date = ref<string | null>(null)
const location = ref<LocationType | null>(null);
const quantity = ref<number>(1);

const updateDate = (value) => {
  date.value = value
}
const clearAll = () => {
  date.value = null
  location.value = null
  quantity.value = 1
}

const handlePlaceSelected = (place: { address: string; coordinates: { lat: number; lng: number } }) => {
  location.value = place;
};

const subTotal = computed(() => {
  return props.item.prices[0].price * quantity.value
})

const discount = computed(() => {
  return Store.getters.discount.rate
})

const totalPrice = computed(() => {
  if (Store.getters.discount.rate) {
    return subTotal.value - (subTotal.value * (Store.getters.discount.rate / 100))
  }

  return subTotal.value
})

const disabled = computed(() => {
  return !quantity.value || !date.value || !location.value
})

const processing = ref<boolean>(false)

const addToCart = () => {
  processing.value = true

  Store.commit('addToCart', {
    product: props.item,
    booking: { date: date.value, pickup_location: location },
    quantity: quantity.value
  })

  clearAll()

  toast.info('Item added to cart')

  processing.value = false
}
</script>

<template>
  <v-card
    class="card-outlined pt-3"
    variant="outlined"
  >
    <v-card-title
      class="px-5"
    >
      {{ i18n.global.t('products.booking.title') }}
    </v-card-title>

    <DatePickerInput
      class="px-5"
      label="Date"
      @update="updateDate"
      @clear="clearDate"
    />

    <GoogleMapsAddressFinder
      class="px-5 py-4"
      :label="i18n.global.t('products.booking.location.title')"
      @place-selected="handlePlaceSelected"
    />

    <v-select
      v-model="quantity"
      class="px-5 pb-7"
      :items="peopleNumberOptions"
      :label="i18n.global.t('products.booking.people.title')"
      variant="outlined"
      hide-details
    ></v-select>

    <v-divider/>

    <div
      class="mt-2"
    >
      <v-card-title
        class="px-5"
      >
        {{ item.meta.data.title }}
      </v-card-title>
      <div
        class="px-5 pb-5"
      >
        <div
          class="d-flex justify-space-between"
        >
          <span class="subtitle mb-1">{{ i18n.global.t('products.booking.price.perPerson') }}</span>
          <span class="subtitle">{{ currency.formatAmount(item.prices[0].price) }}</span>
        </div>

        <div
          class="d-flex justify-space-between"
        >
          <span class="subtitle mb-1">{{ i18n.global.t('products.booking.price.subTotal') }}</span>
          <span class="subtitle">{{ currency.formatAmount(subTotal) }}</span>
        </div>

        <div
          v-if="discount"
          class="d-flex justify-space-between"
        >
          <span class="subtitle mb-1">{{ i18n.global.t('products.booking.price.discount') }}</span>
          <span class="subtitle">{{ discount }}%</span>
        </div>

        <div
          class="d-flex justify-space-between"
        >
          <span class="subtitle mb-1">{{ i18n.global.t('products.booking.price.total') }}</span>
          <span class="subtitle font-weight-bold">{{ currency.formatAmount(totalPrice) }}</span>
        </div>
      </div>
    </div>

    <SecureBadgeForBooking
      class="mx-5 mb-4"
    />

    <v-divider/>

    <v-card-actions
      class="justify-end"
    >
      <v-btn
        variant="tonal"
        color="secondary"
        :disabled="disabled || processing"
        @click.prevent="addToCart"
      >
        <v-icon
          class="mr-1"
        >
          mdi-cart-plus
        </v-icon>
        {{ i18n.global.t('_globals.actions.addToCart') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.no-wrap {
  white-space: break-spaces;
}
</style>