<template>
  <v-btn
    color="secondary"
    elevation="0"
    :disabled="!enabled"
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: 'CButtonFormSubmit',
  props: {
    label: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>