export default {
  "_globals": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])},
    "noVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo non disponible"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
    "actions": {
      "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au Panier"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regarder"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])}
    },
    "alerts": {
      "bank": {
        "unset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte bancaire n'est pas configuré. Vous devez configurer votre compte bancaire pour recevoir les transfers générés par vos ventes."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer mon compte bancaire"])}
      }
    },
    "statuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédulé"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété"])},
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])}
    }
  },
  "discounts": {
    "tenPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-10% en attente"])}
  },
  "inclusions": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport aller-retour"])},
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement de sécurité requis"])},
    "equipment-snorkeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement de snorkeling"])},
    "equipment-skydiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement de parachutisme"])},
    "equipment-boating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement de navigation"])},
    "equipment-wakeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement de wakeboard"])},
    "landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paysages magnifiques"])},
    "landscape-beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages"])},
    "landscape-beach-cave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages et grottes"])},
    "landscape-beach-cave-farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plage, grotte et ferme"])},
    "landscape-natural-swimming-pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscine naturelle"])},
    "landscape-saona-island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paysage de l'île de Saona"])},
    "food-buffet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffet (barbecue, salade et plats typiques dominicains)"])},
    "food-organic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffet de produits locaux biologiques"])},
    "food-open-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bière, rhum et sodas (bar ouvert)"])},
    "cinema-4d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation cinéma en 4D"])},
    "monuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monuments, musée et architecture"])},
    "recommend-wheelchair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non accessible en fauteuil roulant"])},
    "recommend-back-pain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non recommandé pour les voyageurs ayant des problèmes de dos"])},
    "recommend-pregnant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non recommandé pour les femmes enceintes"])},
    "recommend-heart-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de problèmes cardiaques ou d'autres affections médicales graves"])},
    "fitness-moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voyageurs doivent avoir un niveau de forme physique modéré"])}
  },
  "durations": {
    "hour-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 heure"])},
    "hour-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 heures"])},
    "hour-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 heures"])},
    "hour-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 heures"])},
    "hour-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 heures"])},
    "hour-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 heures"])},
    "hour-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 heures"])},
    "hour-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 heures"])},
    "hour-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9 heures"])},
    "hour-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 heures"])},
    "hour-range-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 à 3 heures"])},
    "hour-range-3-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 à 5 heures"])},
    "hour-range-5-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 à 8 heures"])},
    "hour-range-8-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 à 12 heures"])}
  },
  "home": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions & activités à prix imbattables"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez des aventures inoubliables en République dominicaine, conçues spécialement pour vous, avec des prix imbattables, un service de confiance et des souvenirs qui durent toute une vie."])},
      "go": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])}
      }
    },
    "what": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On prend soin de tout"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À chaque étape, nous garantissons une expérience fluide. De la réservation à votre retour, notre équipe s’occupe des moindres détails pour que vous puissiez vous détendre et profiter. Avec des partenaires de confiance, des prix transparents et un service exceptionnel, votre satisfaction est notre priorité. Confiez-nous tout et savourez l’instant."])},
      "incentives": {
        "prices": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Price Guaranteed"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We ensure unmatched value with transparent pricing, trusted partners, and the best deals available."])}
        },
        "peace": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paix d'esprit"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détendez-vous et profitez, sachant que nous gérons chaque détail pour une expérience sans souci."])}
        },
        "team": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une équipe à l'écoute"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe dévouée est là pour vous, offrant soutien et assistance à chaque étape."])}
        }
      }
    },
    "where": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des expériences incroyables d'un bout à l'autre de l'île"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Punta Cana à Las Terrenas, explorez des paysages époustouflants, une culture vibrante et des aventures inoubliables conçues pour vous."])},
      "destinations": {
        "punta": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échappez aux complexes hôteliers et explorez des lieux incroyables"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punta Cana"])}
        },
        "terrenas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les paysages les plus époustouflants de toute l'île"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Terrenas"])}
        },
        "santo": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez des rues animées aux magnifiques plages"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Domingue"])}
        },
        "sosua": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdez-vous dans la nature la plus envoûtante que l'île a à offrir"])},
          "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosúa (bientôt)"])}
        }
      }
    },
    "why": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi choisir Sunset Discounts?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunset Discounts propose des offres imbattables sur des excursions et visites passionnantes, garantissant aux clients un excellent rapport qualité-prix. Avec une réservation simplifiée et un service exceptionnel, nous rendons chaque aventure facile, mémorable et abordable."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])}
    }
  },
  "filters": {
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres disponibles bientôt"])},
    "destination": {
      "punta-cana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Punta Cana"])},
      "las-terrenas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Las Terrenas"])},
      "santo-domingo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Santo Domingo"])}
    },
    "categories": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "options": {
        "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trails"])},
        "beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages"])},
        "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eau"])},
        "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie de nuit"])}
      }
    },
    "languages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "options": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
        "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])}
      }
    },
    "duration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "options": {
        "up-to-1h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à 1 heure"])},
        "1h-to-4h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 à 4 heures"])},
        "4h-to-1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 heures à 1 journée"])},
        "1d-and-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 journée et +"])}
      }
    },
    "price": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])}
    }
  },
  "affiliates": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module d'affiliation"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])}
    },
    "content": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens & Documents"])},
      "affiliateLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilise ton lien d'affilié pour générer des ventes"])}
      },
      "discord": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur Discord"])}
      }
    },
    "customers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client"])},
      "list": {
        "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
        "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voter taux"])},
        "transferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transféré à vous"])}
      }
    },
    "nextPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
      "payout": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Journalier à ", _interpolate(_named("time"))])}
    },
    "totalRevenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des revenus"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
    "pendingPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à transférer"])},
    "sells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes"])},
    "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts"])},
    "courses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations"])}
    }
  },
  "auth": {
    "messages": {
      "signedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été déconnecté."])}
    }
  },
  "cards": {
    "actions": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la carte"])}
    }
  },
  "products": {
    "card": {
      "actions": {
        "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])}
      }
    },
    "inclusions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusions"])}
    },
    "duration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])}
    },
    "booking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "location": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre hotel"])}
      },
      "people": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de personnes"])}
      },
      "price": {
        "perPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price par personne :"])},
        "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total :"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabais :"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total :"])}
      }
    }
  },
  "cart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
    "item": {
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "actions": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])}
      }
    },
    "bottom": {
      "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "actions": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer votre commande"])}
      }
    }
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de contact"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de la personnes en charge."])},
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre nom complet"])}
          }
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre adresse de courriels"])},
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci n'est pas une adresse de courriels valide"])}
          }
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
          "rules": {
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un numéro de téléphone valide"])}
          }
        }
      },
      "consent": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite utiliser les informations ci-dessus pour créer un compte sur la plateforme pour une utilisation future et des achats."])}
      }
    },
    "goToPayment": {
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder au paiement"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes informations"])}
    },
    "completeOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminez votre commande"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois votre commande terminée, un représentant du service client vous contactera pour confirmer votre réservation. Vous pouvez annuler et obtenir un remboursement jusqu'à 24 heures avant l'activité."])}
    },
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nComplétez votre réservation en fournissant un mode de paiement."])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*** \nVotre paiement est traité par Stripe. Nous ne conservons aucune information de carte de crédit car nous sommes conformes aux normes PCI."])}
    },
    "totals": {
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-Total"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabais"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "actions": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre"])},
      "useOldCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utiliser **** ", _interpolate(_named("lastFour"))])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])}
    },
    "thanks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking completed"])},
      "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre achat a été effectué avec succès. Nous vous contacterons bientôt pour vous installer et nous assurer que vous passiez un moment merveilleux. Contactez-nous à <a href='mailto:", _interpolate(_named("supportEmail")), "'>", _interpolate(_named("supportEmail")), "</a> si vous avez des problèmes ou des questions. Merci de faire confiance à <strong>", _interpolate(_named("companyName")), "</strong>"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au site web"])}
    }
  },
  "paypal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de Paypal"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Paypal pour vous transférer les fonds que vous avez générés grâce à vos ventes. Veuillez utiliser le compte de messagerie associé à votre compte Paypal. Si vous n'avez pas de compte Paypal, <a href='https://paypal.com' target='_blank'>créez-en un d'abord</a>."])},
    "form": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse courriel de votre PayPal"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez entrer une adresse courriel valide"])}
        }
      }
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse Paypal a été configurée avec succès"])}
    },
    "actions": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])}
    }
  },
  "datePicker": {
    "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aaaa-mm-jj"])},
    "dob": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])}
    }
  },
  "orders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats"])},
    "items": {
      "entities": {
        "booking": {
          "item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consultation de ", _interpolate(_named("minutes")), " minutes avec ", _interpolate(_named("name"))])}
        }
      },
      "date": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
      },
      "item": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])}
      },
      "amount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
      },
      "actions": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    },
    "status": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])}
    },
    "actions": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])}
    },
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
    "list": {
      "head": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
      },
      "status": {
        "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
        "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursé"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
      }
    }
  },
  "menu": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "AffiliateView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliation"])},
    "DashboardView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "OrdersView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats"])},
    "SettingsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "AnalyzerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzer"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
  },
  "topNavigationBar": {
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
    "joinCreators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crew"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "affiliateSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliés"])}
  },
  "imageUpload": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo de profil"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become an affiliate"])},
    "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faites ", _interpolate(_named("percent")), " de tous les revenus que vous générez."])}
  },
  "videos": {
    "list": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])}
    },
    "feature": {
      "actions": {
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])}
      }
    }
  },
  "settings": {
    "tabs": {
      "userProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Profil"])},
      "userAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "affiliates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliations"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de crédit"])}
    },
    "userProfile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Profil"])},
      "updateForm": {
        "accountNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte n'a toujours pas été vérifié. Vous trouverez votre lien de confirmation dans vos courriels."])},
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel du compte"])}
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
          "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon adresse courriel"])},
          "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le courriel de vérification"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte à été mis à jour"])}
        }
      },
      "updateEmailForm": {
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon adresse courriel"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'adresse courriel de mon compte"])},
        "currentEmail": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse actuelle"])}
        },
        "emailReplacement": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])}
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter votre boîte de courriels pour compléter le changement"])}
        }
      },
      "changePasswordForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
        "currentPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre mot de pass actuel"])}
          }
        },
        "newPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir un nouveau de mot de passe"])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        }
      },
      "resendEmailForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier votre courriel à nouveau"])},
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel envoyé. Consultez votre boîtes de courriels."])},
          "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été vérifié avec succès."])},
          "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeton de vérification est expiré. Envoyer le courriel de vérification de nouveau en passant par la page 'Paramètres'."])}
        }
      }
    },
    "userAccounts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter des utilisateurs"])},
      "inviteUserForm": {
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir une adresse de courriel valide."])},
            "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci n'est pas une adresse de courriel valide."])}
          }
        },
        "role": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez choisir un role"])}
          },
          "items": {
            "creator": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur"])}
            }
          }
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation envoyée"])},
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])}
        }
      },
      "pendingTokenList": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations en attente"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date invité"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune invitation"])}
      }
    },
    "bank": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque et Transferts"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette onglet vous permet de gérer les informations relatives à votre compte bancaire. Vous pouvez également voir les revenus que vous avez généré."])},
      "loadingBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement.."])},
      "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucidbox ne garde aucune information relative à votre compte bancaire. <a href='https://stripe.com' target='_blank'>Stripe</a> s'occupe de tous les paiements et transfers."])},
      "stripeBankForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter votre compte bancaire"])},
        "addressLine1": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 1"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir votre adresse"])}
          }
        },
        "addressLine2": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 2"])}
        },
        "city": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir une ville valide"])}
          }
        },
        "state": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])}
        },
        "postalCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un code postal valide"])}
          }
        },
        "firstName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire du compte : Prénom"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir le prénom du titulaire du compte"])}
          }
        },
        "lastName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire du compte : Nom de famille"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir le nom de famille du titulaire du compte"])}
          }
        },
        "dob": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre date de naissance"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre date de naissance pour que Stripe puisse vérifier votre compte"])}
          }
        },
        "transit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Transit"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30200"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un numéro de transit valide."])}
          }
        },
        "institution": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Institution"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["815"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un numéro d'institution valide."])}
          }
        },
        "account": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
          "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5566780"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un folio valide."])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de banque ajouté avec succès"])}
        }
      },
      "bankSlipBox": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte de banque"])},
        "statuses": {
          "active": {
            "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-check"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])}
          },
          "disabled": {
            "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-close"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vérifié"])}
          }
        },
        "actions": {
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le compte"])}
        }
      },
      "earnings": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus"])}
      }
    },
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de crédit"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de votre carte de crédit."])},
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une carte"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la carte"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      },
      "messages": {
        "success": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a été ajoutée avec succès."])},
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a été mise à jour avec succès."])}
        }
      }
    },
    "booking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette section sert à configurer les paramètres liés à la prise de rendez-vous. Vous trouverez la liste de ceux qui ont payés pour un rendez-vous avec vous. <strong>Vous devez, pour l'instant, gérer vous même vos rendez-vous dans l'application de calendrier de votre choix</strong>."])},
      "list": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes de rendez-vous"])},
        "table": {
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête pour le moment"])}
        }
      }
    },
    "affiliates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens d'affiliés & Revenus"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverai ci-dessous les liens d'affiliés que vous pouvez utiliser pour faire de l'argent avec LucidBox. Les revenus générés par vos liens seront affichés dans cette section."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens"])},
        "podcast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations au Podcast"])},
          "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["https://lucidbox.co/podcast?a=", _interpolate(_named("token"))])}
        },
        "messages": {
          "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])}
        }
      },
      "podcast": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations au Podcast"])},
        "table": {
          "headers": {
            "name": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
            },
            "email": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])}
            },
            "status": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
            }
          },
          "statuses": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
            "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booké"])},
            "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présent"])},
            "dropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])}
          },
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun invité"])}
        },
        "due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du : ", _interpolate(_named("due")), "$"])},
        "paymentDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prochain paiement : ", _interpolate(_named("date"))])}
      }
    }
  },
  "subscribeForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "name": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre nom complet"])}
      }
    },
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre adresse de courriels"])},
        "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci n'est pas une adresse de courriels valide"])}
      }
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "rules": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez saisir un mot de passe"])}
      }
    },
    "confirmPassword": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
      "rules": {
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux mots de passe ne sont pas identiques"])}
      }
    },
    "gender": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "options": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
        "nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non binaire"])}
      },
      "rules": {
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teci n'est pas une option valide."])}
      },
      "noNonBinary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't accept mentally disabled people in our club."])}
    },
    "consent": {
      "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'accepte les <a href='", _interpolate(_named("terms_url")), "' target='_blank'>conditions d'utilisation</a> et la <a href='", _interpolate(_named("privacy_url")), "' target='_blank'>politique de confidentialité</a> de Lucidbox."])}
    },
    "actions": {
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà inscrit ? Connectez-vous."])}
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé avec succès."])}
    }
  },
  "signInForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre courriel"])}
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
    },
    "actions": {
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai oublié mon mot de passe."])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte ? Inscrivez-vous."])}
    },
    "forgotPassword": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération du compte"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse courriel de votre compte"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre adresse courriel pour pouvoir récupérer votre compte."])},
          "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci n'est pas une adresse de courriel valide"])}
        }
      },
      "actions": {
        "sendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le code"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer le compte"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    },
    "retrieveAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération du compte"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de courriels"])},
      "token": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton de récupération"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir un jeton numérique valide."])}
        }
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "rules": {
          "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir un nouveau mot de passe pour votre compte."])}
        }
      },
      "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un courriel a été envoyé à l'adresse suivante :"])},
      "fillTheToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir le jeton à 6 chiffres dans le champ ci-dessous."])},
      "actions": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer le compte"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    },
    "messages": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté"])}
    }
  },
  "footer": {
    "sitemap": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
      "puntaCana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Punta Cana"])},
      "lasTerrenas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Las Terrenas"])},
      "santoDomingo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excursions à Santo Domingo"])},
      "affiliate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affilipro"])}
    },
    "legal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Légal"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
    },
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["©", _interpolate(_named("year")), " Fabriqué par <a href='", _interpolate(_named("url")), "' class='wob' target='_blank'>ConsultOj Digital Services</a> - All rights reserved."])}
  },
  "tags": {
    "entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divertissement"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tech"])},
    "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
    "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancing"])},
    "entrepreneurship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprenariat"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
    "dating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dating"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement"])}
  },
  "contact": {
    "list": {
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel principale"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre # de téléphone (optionel)"])}
        }
      }
    },
    "joinCreators": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deviens un mentor"])},
      "form": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir votre nom"])}
          }
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir une adresse courriel valide"])}
          }
        },
        "about": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous pourquoi vous feriez un bon mentor"])},
          "rules": {
            "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous décrire un minimum"])}
          }
        },
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre intérêt. Nous vous contacterons dès que possible."])}
        }
      }
    }
  },
  "transfers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune vente"])},
    "table": {
      "head": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
        "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
      },
      "body": {
        "entity": {
          "booking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consultation de ", _interpolate(_named("minutes")), " minutes."])}
        },
        "statuses": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuvé"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
          "transferring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En transfer"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échoué"])}
        }
      }
    }
  }
}