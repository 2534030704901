<script setup lang="ts">
import { ref, onMounted, inject } from 'vue'
import router from '@/router'

const http = inject('axios')

const authCode = ref()
const accessToken = ref()

onMounted(async () => {
  authCode.value = router.currentRoute.value.query.code ?? null

  if (!authCode.value) {
    await router.push({ name: 'SettingsView', query: { tab: 'booking' } })
  } else {
    await storeAuthorizationCode()
  }
})

const storeAuthorizationCode = async () => {
  await http.post('/booking/settings/access-token', {
    code: authCode.value
  })
    .then(response => {
      accessToken.value = response.data.data
    })
    .then(error => {
      console.log(error)
    })
}
</script>

<template>
  <v-container>
    {{ accessToken }}
  </v-container>
</template>

<style scoped lang="scss">

</style>