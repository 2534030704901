<script setup>
import { ref, inject, computed } from 'vue'
import requestResponses from '@/helpers/requestResponses'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import i18n from '@/i18n'
import store from '@/store'
import currency from '@/helpers/currency'
import router from '@/router'
import timeFormats from '@/helpers/timeFormats'
import CheckoutStepCourses from '@/components/checkout_old/steps/CheckoutStepCourses.vue'
import CheckoutStepBookings from '@/components/checkout_old/steps/CheckoutStepBookings.vue'
import CheckoutStepSubscription from '@/components/checkout_old/steps/CheckoutStepSubscription.vue'
import CardSetupWrapper from '@/components/card/CardSetupWrapper.vue'
import subscription from "@/helpers/subscription";

const http = inject('axios')
const toast = inject('toast')

const step = ref(1)
const stepCompleteData = ref({
  title: "",
  message: ""
})
const paymentMethodSet = ref(false)

const checkoutData = computed(() => {
  return store.getters.checkoutProcessEntity
})
const checkoutCurrency = computed(() => {
  return store.getters.checkoutProcessCurrency
})
const totalDuration = computed(() => {
  let total = 0

  for (let i = 0; i < checkoutData.value.content.items.length; i++) {
    total += parseInt(checkoutData.value.content.items[i].duration)
  }

  return timeFormats.secondsToHms(total)
})
const prices = computed(() => {
  const pr = [];

  for (let i in checkoutData.value.prices) {
    if (checkoutData.value.frequency === 'yearly' && !checkoutData.value.prices[i].yearly) {
      break
    }
    pr.push(checkoutData.value.prices[i])
  }

  return pr
})

const confirmPurchase = () => {
  step.value = 2
}
const cancel = () => {
  store.commit('checkoutProcessShow', false)
}

const buyLoading = ref(false)
const buy = async (subscription = false) => {
  if (subscription) {
    return await subscribe()
  }

  buyLoading.value = true

  http.post('/payment/checkout', {
    entity: checkoutData.value.entity,
    entityId: checkoutData.value.entityId,
    price: checkoutData.value.price,
    data: checkoutData.value.data ?? null,
    creatorId: checkoutData.value.creator ? checkoutData.value.creator.id : null
  })
    .then(() => {
      postSuccessActions()
    })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
    .finally(() => { buyLoading.value = false })
}
const subscribe = async () => {
  buyLoading.value = true

  await http.post('/subscription/subscribe', {
    entity: checkoutData.value.entity ?? 'subscription',
    entityId: checkoutData.value.entityId,
    frequency: checkoutData.value.frequency
  })
    .then(() => {
      postSuccessActions()
    })
    .catch(error => {
      toast.error(requestResponses.messageBagToSingleMessage(error.response.data.message))
    })
    .finally(() => { buyLoading.value = false })
}

const postSuccessActions = () => {
  let redirectRouteName = null
  toast.success(i18n.global.t('checkout_old.messages.success'))

  if (checkoutData.value.entity === 'course') {
    redirectRouteName = 'CourseItemView'
    store.commit('checkoutProcessShow', false)
    store.dispatch('getUser')
      .then(() => {
        router.push({ name: redirectRouteName, params: { id: checkoutData.value.entityId } })
      })
  } else if (checkoutData.value.entity === 'booking') {
    step.value = 3
    stepCompleteData.value = {
      title: 'Demande de réservation envoyée avec succès',
      message: 'Nous allons te contacter pour que tu puisses choisir le moment idéal pour ta consultation.'
    }
  } else if (checkoutData.value.entity === 'subscription') {
    store.commit('checkoutProcessShow', false)
    store.dispatch('getUser')
      .then(() => {
        router.push({ name: checkoutData.value.redirects.success })
      })
  }
}

const cancelLoading = ref(false)

const price = computed(() => {
  const lang =  subscription.prices(checkoutData.value.prices, checkoutData.value.frequency === 'yearly')
  return i18n.global.t(lang.lang, lang.items)
})
</script>

<template>
  <div
    class="pa-5"
  >
    <div
      v-if="step === 1"
    >
      <CheckoutStepCourses
        v-if="checkoutData.entity === 'course'"
      />
      <CheckoutStepBookings
        v-else-if="checkoutData.entity === 'booking'"
      />
      <CheckoutStepSubscription
        v-else-if="checkoutData.entity === 'subscription'"
        :prices="prices"
      />
    </div>
    <div
      v-else-if="step === 2"
    >
      <h2>
        <v-icon
          v-if="checkoutData.entity === 'course'"
          class="mr-4"
          size="small"
        >
          mdi-school
        </v-icon>
        <v-icon
          v-if="checkoutData.entity === 'booking'"
          class="mr-4"
          size="small"
        >
          mdi-calendar
        </v-icon>
        <div>
          <v-icon
            v-if="checkoutData.content.icon"
            class="mr-2"
            size="small"
          >
            {{ checkoutData.content.icon }}
          </v-icon>
          <span v-html="checkoutData.content.title" />
        </div>
      </h2>

      <p class="mb-4" v-html="checkoutData.content.description" />

      <div
        v-if="checkoutData.entity === 'course'"
        class="totals my-2"
      >
        <p>
          <span>{{ i18n.global.t('checkout_old.totalTime') }}</span>
          <span class="time">{{ totalDuration }}</span>
        </p>
      </div>
      <div
        class="order-info mb-6"
      >
        <div>
          <span>{{ checkoutData.entity === 'subscription' ? i18n.global.t('checkout_old.package') : i18n.global.t('checkout_old.total') }}</span>
          <div
            class="price"
          >
            <span v-if="checkoutData.entity === 'subscription'" class="total" v-html="price" />
            <span v-else>{{ currency.formatAmount(parseFloat(checkoutData.price), checkoutCurrency) }}</span>
          </div>
        </div>

        <div
          v-if="checkoutData.entity === 'course'"
          class="wallet"
        >
          <div>
            {{ i18n.global.t('checkout_old.wallet') }}
          </div>
          <div>
            <v-icon
              class="mr-1"
              color="green-accent-4"
              size="small"
            >
              mdi-wallet-plus
            </v-icon>
            <span>
              {{ currency.formatAmount(parseFloat(checkoutData.price * 0.025), checkoutCurrency) }}
            </span>
          </div>
        </div>
      </div>

      <p v-if="!paymentMethodSet" class="no-card">
        {{ i18n.global.t('checkout_old.noCard') }}
      </p>

      <CardSetupWrapper
        @isSet="(value) => { paymentMethodSet = value }"
        @cancel="cancel"
      />
    </div>
    <div
      v-else-if="step === 3"
      class="step-completed"
    >
      <v-icon
        color="green"
        size="80"
      >
        mdi-calendar-check
      </v-icon>
      <h3 v-html="stepCompleteData.title" />
      <p class="mb-10" v-html="stepCompleteData.message" />
    </div>
    <v-btn-group
      v-if="step === 1 || (step === 2 && paymentMethodSet)"
      class="mt-6"
      divided
      variant="flat"
    >
      <v-btn
        v-if="step === 1"
        size="large"
        @click="confirmPurchase"
      >
        {{ i18n.global.t('checkout_old.actions.confirm') }}
      </v-btn>
      <v-btn
        v-else-if="step === 2 && paymentMethodSet"
        color="secondary"
        variant="tonal"
        size="large"
        :loading="buyLoading"
        @click.prevent="() => { buy(checkoutData.entity === 'subscription') }"
      >
        {{ checkoutData.entity === 'subscription' ? i18n.global.t('checkout_old.actions.subscribe') : i18n.global.t('checkout_old.actions.buy') }}
      </v-btn>
      <CFormCancelButton
        :label="i18n.global.t('_globals.actions.cancel')"
        size="large"
        :disabled="cancelLoading"
        @cancel="cancel"
      />
    </v-btn-group>
    <v-btn-group
      v-else-if="step === 3"
    >
      <CFormCancelButton
        :label="i18n.global.t('_globals.actions.close')"
        @cancel="cancel"
      />
    </v-btn-group>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";

h2 {
  display: flex;
  align-items: start;
  margin-bottom: 22px;
  padding-bottom: 0;
}

.items {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  border-top: 1px solid $grey-01;
  border-bottom: 1px solid $grey-01;
  padding: 5px 0;

  .box {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-bottom: 7px;

    .title {
      font-weight: bold;
    }

    .description {
      color: $lighter-text;
    }

    .time {
      margin-top: 2px;
      font-size: 13px;
    }
  }
}

.price {
  text-align: right;

  :deep(.nl) {
    display: block;
  }
  :deep(.small) {
    font-size: 14px;
    font-weight: normal;
  }

  .total {
    font-size: 28px;
    font-weight: 700;
  }

  .freq {
    font-size: 17px;
    font-weight: 300;
    margin-left: 5px;
  }
}

.totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  p {
    margin-bottom: 8px;

    span {
      display: block;
    }
    .price {
      font-weight: 500;
    }
  }

  .minutes {
    font-weight: 500;
    font-size: 30px;
  }
}

.step-completed {
  text-align: center;

  .v-icon {
    margin: 25px 0 25px 0;
  }
}

.order-info {

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
  }

  .wallet {
    font-size: 13px;
  }
}

.no-card {
  font-weight: 500;
}
</style>
