<script setup lang="ts">
import { ref } from 'vue'
import router from '@/router'
import i18n from '@/i18n'

const imagesUrl = process.env.VUE_APP_IMAGES_URL

const incentives = [
  {
    icon: 'mdi-airplane',
    title: i18n.global.t('home.what.incentives.prices.title'),
    description: i18n.global.t('home.what.incentives.prices.description')
  },
  {
    icon: 'mdi-lock',
    title: i18n.global.t('home.what.incentives.peace.title'),
    description: i18n.global.t('home.what.incentives.peace.description')
  },
  {
    icon: 'mdi-heart',
    title: i18n.global.t('home.what.incentives.team.title'),
    description: i18n.global.t('home.what.incentives.team.description')
  }
]

const destinations = [
  {
    disabled: false,
    slug: 'punta-cana',
    image: '/punta-cana-card.jpg',
    title: i18n.global.t('home.where.destinations.punta.title'),
    destination: i18n.global.t('home.where.destinations.punta.destination')
  },
  {
    disabled: false,
    slug: 'las-terrenas',
    image: '/las-terrenas-card.jpg',
    title: i18n.global.t('home.where.destinations.terrenas.title'),
    destination: i18n.global.t('home.where.destinations.terrenas.destination')
  },
  {
    disabled: false,
    slug: 'santo-domingo',
    image: '/santo-domingo-card.jpg',
    title: i18n.global.t('home.where.destinations.santo.title'),
    destination: i18n.global.t('home.where.destinations.santo.destination')
  },
  {
    disabled: true,
    slug: 'sosua',
    image: '/sosua-card.jpg',
    title: i18n.global.t('home.where.destinations.sosua.title'),
    destination: i18n.global.t('home.where.destinations.sosua.destination')
  }
]

const selectedDestination = ref<string>('punta-cana')

const goTo = (destination, disabled) => {
  if (!disabled) {
    router.push({ name: 'ProductListPage', params: { destination } })
  }
}

const goToFacebook = () => {
  window.open('https://www.facebook.com/profile.php?id=61557617916465', '_blank')
}
</script>

<template>
<div
  class="wrp"
>
  <div
    class="header"
    v-bind:style="{ 'background-image': 'url(' + imagesUrl + '/header-bg.jpg)' }"
  >

      <div
        class="box"
      >
        <h1>{{ i18n.global.t('home.header.title') }}</h1>
        <p class="mb-7">{{ i18n.global.t('home.header.sub') }}</p>
        <div
          class="d-flex align-center justify-center"
        >
          <v-select
            :items="destinations.slice(0, -1)"
            item-title="destination"
            item-value="slug"
            :item-disabled="item => item.disabled"
            :label="i18n.global.t('home.header.go.label')"
            v-model="selectedDestination"
            hide-details
            density="comfortable"
            variant="solo-filled"
          />
          <v-btn
            class="ml-2 btn-custom-height"
            size="large"
            @click.prevent="router.push({ name: 'ProductListPage', params: { destination: selectedDestination } })"
          >
            Go
          </v-btn>
        </div>
      </div>

  </div>
  <div
    class="section what y-padding"
  >
    <v-container
      class="wrapper"
    >
      <v-row
        class="mb-8"
      >
        <v-col
          class="pb-0"
          cols="12"
          sm="6"
        >
          <h2>{{ i18n.global.t('home.what.title') }}</h2>
        </v-col>
        <v-col
          class="pt-0 pt-sm-4 pb-0"
          cols="12"
          sm="6"
        >
          <p class="black">{{ i18n.global.t('home.what.description') }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(item, i) in incentives"
          :key="'incentive-' + i"
          cols="12"
          sm="4"
        >
          <v-card
            class="pa-5"
            elevation="17"
            variant="elevated"
          >
            <v-icon
              class="mb-4"
              size="x-large"
            >
              {{ item.icon }}
            </v-icon>
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div
    class="section where y-padding"
  >
    <v-container
      class="wrapper py-0"
    >
      <div
        class="box"
      >
        <h2>{{ i18n.global.t('home.where.title') }}</h2>
        <p>{{ i18n.global.t('home.where.description') }}</p>
      </div>
      <v-row>
        <v-col
          v-for="(item, i) in destinations"
          :key="'destination-' + i"
          cols="12"
          sm="3"
        >
          <div
            class="destination-box"
            :class="item.disabled ? 'disabled' : ''"
            @click.prevent="goTo(item.slug, item.disabled)"
          >
            <div
              class="image"
              v-bind:style="{ 'background-image': 'url(' + imagesUrl +  item.image + ')' }"
            />
            <div class="title outfit-regular">{{ item.title }}</div>
            <p>{{ item.destination }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div
    class="section why"
  >
    <v-row
      class="ma-0"
    >
      <v-col
        class="image"
        cols="12"
        sm="6"
        v-bind:style="{ 'background-image': 'url(' + imagesUrl + '/why-girl-swimming.jpg)' }"
      >

      </v-col>
      <v-col
        class="text"
        cols="12"
        sm="6"
      >
        <h2>{{ i18n.global.t('home.why.title') }}</h2>
        <p>{{ i18n.global.t('home.why.description') }}</p>
        <v-btn
          flat
          size="large"
          color="secondary"
          @click.prevent="goToFacebook"
        >
          {{ i18n.global.t('home.why.action') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<style scoped lang="scss">
@import "@/style/breakpoints.scss";
@import "@/style/colors.scss";

.wrp {
  width: 100%;

  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    line-height: 58px;

    @media (max-width: $breakpoint-sm-down) {

    }

    @media (max-width: $breakpoint-xs-down) {

    }

    &.fixed {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 34px;
    margin-bottom: 12px;
    line-height: 42px;

    @media (max-width: $breakpoint-sm-down) {

    }

    @media (max-width: $breakpoint-xs-down) {

    }
  }

  h3 {
    font-size: 25px;
    margin-bottom: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $black;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  h3,
  h4,
  h5 {
    font-weight: normal;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7vw;
    padding-bottom: 7vw;
    background-color: #ffe8cb;
    background-position: center;
    background-size: cover;

    @media(max-width: $breakpoint-sm-down) {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    .box {
      max-width: 572px;
      margin: 0 auto;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;

      h1 {
        color: $color-white;
        text-shadow: 1px 1px 1px #333;
      }

      p {
        color: #f1f1f1;
        text-shadow: 0px 0px 20px #333;
      }

      @media(max-width: $breakpoint-xs-down) {
        padding-bottom: 20px
      }
    }

    .left {
      display: flex;
      justify-content: end;
      align-items: center;
      padding-top: 6vw;
      padding-bottom: 6vw;


    }

    .image {
      background-position: left;
      background-size: cover;

      @media(max-width: $breakpoint-xs-down) {
        height: 300px;
      }
    }

    .btn-custom-height {
      height: calc(var(--v-btn-height, 40px) + 6px);
    }
  }

  .what {
    background: rgb(246,246,246);
    background: -moz-linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f6",endColorstr="#ffffff",GradientType=1);

    p.black {
      color: $black;
    }
  }

  .where {
    padding-bottom: 75px !important;

    .box {
      max-width: 480px;

      p {
        margin-bottom: 45px;
      }
    }

    .destination-box {

      &:hover {
        cursor: pointer;

        p {
          color: $color-link-hover;
        }

        .image {
          filter: grayscale(100%);
        }
      }

      &.disabled {
        cursor: inherit;

        p {
          color: inherit;
        }

        .image {
          filter: grayscale(100%);
        }
      }

      .image {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 20px 20px 20px 0;
        background-size: cover;

        &.filter-gray {
          filter: grayscale(100%);
        }

        @media(max-width: $breakpoint-xs-down) {
          aspect-ratio: 3 / 2;
        }
      }

      div.title {
        margin-top: 7px;
        margin-bottom: 2px;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-style: italic;
      }
    }
  }

  .why {
    width: 100%;
    padding-top: 4vw;
    padding-bottom: 75px;

    .image {
      background-size: cover;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .text {
      padding: 6vw 50px;
    }

    @media(max-width: $breakpoint-xs-down) {
      padding-top: 25px;

      .image {
        height: 300px;
        border-radius: inherit;
      }

      .text {
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }

  .v-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 18px;
    height: 100%;
  }

  .y-padding {
    padding-top: 4vw;
    padding-bottom: 4vw;

    @media (max-width: $breakpoint-xs-down) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
</style>