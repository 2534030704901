<script setup lang="ts">
import { ref, defineEmits, defineProps } from 'vue'
import i18n from '@/i18n'
import CTextField from "@/components/fields/CTextField.vue";

interface ContactInformationType {
  email: string,
  name: string,
  phone: string|null,
  consent: boolean
}

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})

const data = ref<ContactInformationType>({
  email: '',
  name: '',
  phone: null,
  consent: true
})

const rules = {
  name: [
    (v) => !!v || i18n.global.t('checkout.contact.form.name.rules.mandatory')
  ],
  email: [
    (v) => !!v || i18n.global.t('checkout.contact.form.email.rules.mandatory'),
    (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.global.t('checkout.contact.form.email.rules.valid')
  ],
  phone: [
    (v) => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(v) || i18n.global.t('checkout.contact.form.phone.rules.valid')
  ]
}

const emit = defineEmits(['update'])
</script>

<template>
  <div
    class="form-wrap"
  >
    <h2>{{ i18n.global.t('checkout.contact.title') }}</h2>
    <p>{{ i18n.global.t('checkout.contact.description') }}</p>

    <CTextField
      v-model="data.email"
      class="pb-2"
      :label="i18n.global.t('checkout.contact.form.email.label')"
      :rules="rules.email"
      :disabled="props.disabled"
      required
      @blur="emit('update', data)"
    />

    <CTextField
      v-model="data.name"
      class="pb-2"
      :label="i18n.global.t('checkout.contact.form.name.label')"
      :rules="rules.name"
      :disabled="props.disabled"
      required
      @blur="emit('update', data)"
    />

    <CTextField
      v-model="data.phone"
      class="pb-0"
      :label="i18n.global.t('checkout.contact.form.phone.label')"
      :rules="rules.phone"
      :disabled="props.disabled"
      @blur="emit('update', data)"
    />

    <v-checkbox
      v-model="data.consent"
      class="mb-4"
      :disabled="props.disabled"
      hide-details
      :label="i18n.global.t('checkout.contact.consent.account')"
    />
  </div>
</template>

<style scoped lang="scss">

</style>