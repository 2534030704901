<script setup lang="ts">
import { ref } from 'vue'
import AuthSignIn from '@/components/auth/AuthSignInForm.vue'
import CDialogFrame from '@/components/dialogs/CDialogFrame.vue'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm.vue'
import RetrieveAccountForm from '@/components/auth/RetrieveAccountForm.vue'

import store from '@/store'

const imagesUrl = process.env.VUE_APP_IMAGES_URL

const showForgotPassword = ref(false)
const showRetrieveAccount = ref(false)

const resetPasswordSuccess = (email) => {
  showForgotPassword.value = false
  store.commit('setRetrieveAccountEmail', email)
  showRetrieveAccount.value = true
}
</script>

<template>
  <div
    class="full-wrapper"
  >
    <div
      class="background"
    >
      <div class="blurred-sphere-1"></div>
      <div class="blurred-sphere-2"></div>
    </div>
    <v-container
      class="container"
    >
      <div
        class="menu-logo"
      >
        <img
          class="menu-logo"
          :src="imagesUrl + '/lb-logo-bw.png'"
        />
        <h2>
          {{ $t('signInForm.title') }}
        </h2>
      </div>

      <AuthSignIn
        class="sign-in"
        :cancel-action="false"
        @toggleForgotPassword="(v) => showForgotPassword = v"
      />

      <CDialogFrame
        :show="showForgotPassword"
        :title="$t('signInForm.forgotPassword.title')"
      >
        <template v-slot:component>
          <ForgotPasswordForm
            @toggleDisplay="(v) => showForgotPassword = v"
            @success="resetPasswordSuccess"
          />
        </template>
      </CDialogFrame>

      <CDialogFrame
        :show="showRetrieveAccount"
        :title="$t('signInForm.retrieveAccount.title')"
      >
        <template v-slot:component>
          <RetrieveAccountForm
            @toggleDisplay="(v) => showRetrieveAccount = v"
          />
        </template>
      </CDialogFrame>
    </v-container>
  </div>
  </template>

<style scoped lang="scss">
@import "@/style/breakpoints.scss";

.full-wrapper {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 475px;
    padding: 50px 0;

    @media (max-width: $breakpoint-xs-down) {
      display: block;
      height: inherit;
    }
  }
  .background {
    position: fixed;
    z-index: 0;

    .blurred-sphere-1 {
      position: relative;
      width: 1200px;
      height: 1200px;
      background: radial-gradient(circle, rgb(166, 16, 16) 0%, rgba(255,0,0,0) 70%),
      radial-gradient(circle, rgba(0,0,255,1) 0%, rgba(0,0,255,0) 70%);
      filter: blur(200px);
      border-radius: 50%;
      margin-top: -600px;
      margin-left: -600px;
    }

    .blurred-sphere-2 {
      position: relative;
      width: 1200px;
      height: 1200px;
      background: radial-gradient(circle, rgb(166, 16, 16) 0%, rgba(255,0,0,0) 70%),
      radial-gradient(circle, rgba(0,0,255,1) 0%, rgba(0,0,255,0) 70%);
      filter: blur(275px);
      border-radius: 50%;
      margin-bottom: -600px;
      right: -600px;
    }
  }
}

.menu-logo {
  text-align: center;
  margin-bottom: 10px;

  img {
    max-width: 100px;
  }
}

.sign-in {
  z-index: 2;
}
</style>