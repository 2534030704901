import currency from '@/helpers/currency'

export default {

  hasAccess(subscriptionLabel: any, userSubscriptions: any[], userRoleId?: number): boolean 
  {
    if (userRoleId && userRoleId >= 2) {
      return true
    }
console.log(userSubscriptions)
    for (let i = 0; i < userSubscriptions.length; i++) {
      if (userSubscriptions[i].label.includes(subscriptionLabel.toLowerCase())) {
        return true
      }
    }

    return false
  },

  prices(subscriptionPrices: any[], yearly: boolean): object
  {
    if (yearly) {
      return { lang: 'subscriptions.prices.yearly', items: { amount: currency.formatAmount(subscriptionPrices.find(x => x.yearly == true).price) } }
    }

    const monthPrices = subscriptionPrices.filter((x) => {
      return x.yearly === 0
    })

    const iteration = monthPrices.find(x => x.iterations !== null)

    if (iteration) {
      const firstAmount = currency.formatAmount(iteration.price)
      const secondAmount = currency.formatAmount(monthPrices.find(x => x.iterations == null).price)
      const iterations = iteration.iterations
      const lang = iterations > 1 ? 'subscriptions.prices.iterations' : 'subscriptions.prices.iteration'

      return { lang: lang, items: { firstAmount, secondAmount, iterations }}
    } else {
      const amount = currency.formatAmount(subscriptionPrices[0].price)
      return { lang: 'subscriptions.prices.monthly', items: { amount  }}
    }
  }
}