<template>
  <div
    class="vi"
  >
    Verify account...
  </div>
</template>

<script>
export default {
  name: 'SettingsAccountVerify',
  mounted () {
    const token = this.$route.query.token

    if (token !== undefined) {
      this.verify(token)
    } else {
      this.$router.push({ name: 'SettingsView' })
    }
  },
  methods: {
    verify (token) {
      this.$http.post('/user/verify', { token })
          .then(response => {
            const verified = !!response.data.data

            if (verified) {
              this.$store.dispatch('getUser')
                  .then(() => {
                    this.$toast.success(this.$t('settings.userProfile.resendEmailForm.messages.verified'))
                  })
            } else {
              this.$toast.error(this.$t('settings.userProfile.resendEmailForm.messages.notVerified'))
            }
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
          .finally(() => { this.$router.push({ name: 'SettingsView' }) })
    }
  }
}
</script>

<style scoped>
.vi {
  padding: 25px 0;
}
</style>