<script setup lang="ts">
import { inject, onMounted, ref, defineProps, defineEmits } from 'vue'
import i18n from '@/i18n'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'

const props = defineProps({
  updateMode: {
    type: Boolean,
    default: false
  },
  autoInit: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  if (props.updateMode) {
    initiateSetup()
  }
})

const http = inject('axios')
const toast = inject('toast')
const stripe = inject('stripe')

const loading = ref(false)

const stripeInstance = ref(null)
const elements = ref(null)
const token = ref(null)
const clientSecret = ref(null)

onMounted(async () => {
  stripeInstance.value = stripe
})

const initiateSetup = async () => {
  loading.value = true

  await http.post('/payment/setup/initiate')
    .then(response => {
      const secret = response.data.data.clientSecret
      clientSecret.value = secret
      token.value = response.data.data.token // Use to identify the setup intent to be completed after card input
      createCreditCardForm(secret)
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { loading.value = false })
}

const completeLoading = ref(false)

const completeSetup = async () => {
  completeLoading.value = true

  elements.value.submit()

  const { error } = await stripeInstance.value.confirmSetup({
    elements: elements.value,
    clientSecret: clientSecret.value,
    redirect: 'if_required'
  })

  if (error === undefined) {
    await http.post('/payment/setup/complete', {
      token: token.value
    })
      .then(() => {
        emit('success')
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(() => { completeLoading.value = false })
  } else {
    completeLoading.value = false
    toast.error(error.message)
  }
}

const createCreditCardForm = (clientSecret) => {
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'night',
      labels: 'floating'
    }
  }

  elements.value = stripeInstance.value.elements(options);
  const paymentElement = elements.value.create('payment');
  paymentElement.mount('#payment-element')
}

const cancelLoading = ref(false)

const cancel = async () => {
  cancelLoading.value = true

  await http.post('/payment/setup/cancel', {
    token: token.value
  })
    .then(() => {
      elements.value = null
      token.value = null
      clientSecret.value = null
      document.getElementById("payment-element").innerHTML = ""
      emit('cancel')
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { cancelLoading.value = false })
}

const emit = defineEmits(['success', 'cancel'])
</script>

<template>
  <v-container
    class="pa-0"
  >
    <div id="payment-element">
      <!-- Stripe will create form elements here -->
    </div>
    <div
      v-if="!token && !clientSecret && !loading"
    >
      <v-btn
        class=""
        flat
        size="large"
        @click="initiateSetup"
      >
        {{ i18n.global.t('settings.card.actions.add') }}
      </v-btn>
    </div>
    <div
      v-else-if="token && clientSecret"
    >
      <v-btn-group
        class="mt-6"
        divided
        variant="flat"
      >
        <CButtonFormSubmit
          :label="i18n.global.t('settings.card.actions.submit')"
          :enabled="!!(token && clientSecret)"
          :loading="completeLoading"
          @click.prevent="completeSetup"
        />
        <v-btn
          :loading="cancelLoading"
          @click.prevent="cancel"
        >
          {{ i18n.global.t('settings.card.actions.cancel') }}
        </v-btn>
      </v-btn-group>
    </div>
    <div
      v-if="loading"
      class="py-12 text-center"
    >
      <v-progress-circular indeterminate/>
    </div>
  </v-container>
</template>

<style scoped lang="scss">

</style>