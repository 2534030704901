<template>
  <label>
    <v-checkbox
      v-model="value"
      class="mr-4"
      :rules="rules"
      hide-details
      @click="(e) => parseValue(e)"
    />
    <span v-html="label" />
  </label>
</template>

<script>
export default {
  name: 'CCheckboxConsent',
  props: {
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      value: false
    }
  },
  methods: {
    parseValue (e) {
      if (e.target._value) {
        this.$emit('onCheck', true)
      } else {
        this.$emit('onCheck', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/variables.scss";

label {
  display: flex;
  align-items: center;
  color: $lighter-text;

  span {
    font-size: $text-small;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>