<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['cancel'])

const cancel = () => {
  emit('cancel')
}
</script>

<template>
  <v-btn
      @click.prevent="cancel"
  >
    {{ props.label }}
  </v-btn>
</template>
