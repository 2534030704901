<template>
  <v-container
    class="d-flex align-center justify-center flex-column"
  >
    <h1 class="mb-1">404</h1>
    <p>Page not found</p>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>
.v-container {
  height: 50vh;
}
</style>