
export default {

  formatDate (date: string) {
    return new Date(date).toUTCString().substr(0, 16)
  },

  secondsToHms (seconds: number) {
    const date = new Date(seconds * 1000).toISOString().slice(11, 19);

    return date.slice(0, 3) === '00:' ? date.slice(3) : date
  },

  dateStringToEpoch (date: string) {
    const format = new Date(date).getTime() / 1000

    return Math.floor(format)
  }
}