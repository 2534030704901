<script setup lang="ts">
import { ref, inject, computed, defineEmits, onMounted } from 'vue'
import CreditCardForm from '@/components/card/CreditCardForm.vue'
import CardSlipBox from '@/components/card/CardSlipBox.vue'
import store from '@/store'
import i18n from '@/i18n'

onMounted(() => {
  emit('isSet', !!card.value)
})

const toast = inject('toast')

const card = computed(() => {
  const user = store.getters.getUser
  return user.card && user.card.last4 ? user.card : null
})

const updateMode = ref(false)

const updateCard = (value = true) => {
  updateMode.value = value
  if (!value) {
    emit('cancel')
  }
  emit('isSet', !value && !!card.value)
}

const success = () => {
  const message = updateMode.value ? i18n.global.t('settings.card.messages.success.update') : i18n.global.t('settings.card.messages.success.add')
  toast.success(message)
  updateMode.value = false
  store.dispatch('getUser')
  emit('isSet', true)
}

const emit = defineEmits(['isSet', 'cancel'])
</script>

<template>
  <div>
    <CardSlipBox
      v-if="card && !updateMode"
      :card="card"
      @updateCard="updateCard"
    />
    <CreditCardForm
      v-else
      :update-mode="updateMode"
      @success="success"
      @cancel="updateCard(false)"
    />
  </div>
</template>
