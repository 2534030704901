<script setup lang="ts">
import { inject, onBeforeUnmount, ref, watch, computed, defineEmits, defineExpose, defineProps } from 'vue'
import { loadStripe } from "@stripe/stripe-js";
import Store from "@/store";

const http = inject('axios')
const toast = inject('toast')

const props = defineProps({
  email: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  consent: {
    type: Boolean,
    required: true
  }
})

const key = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(key)

const cardElement = ref(null)
const stripe = ref(null)
const elements = ref(null)
const card = ref(null)
const clientSecret = ref(null)
const token = ref(null)
const isLoading = ref(false)

onBeforeUnmount(async () => {
  // Cancel the payment intent
  /*
  if (token.value) {
    await cancel()
  }
  */
  // Clean up the element when the component is destroyed
  if (card.value) {
    card.value.unmount()
  }
})

const init = async () => {
  isLoading.value = true

  await http.post('/payment/intent/create', {
    amount: amount.value * 100,
    customer: {
      email: props.email,
      name: props.name,
      consent: props.consent
    }
  })
    .then(response => {
      const secret = response.data.data.clientSecret
      clientSecret.value = secret
      token.value = response.data.data.token
      createCreditCardForm(secret)
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { isLoading.value = false })
}

const amount = computed(() => Store.getters.cartTotal)

// Watch for changes in `amount` and trigger `init` when it’s first set
watch(
  amount,
  async (newValue) => {
        if (!token.value && newValue) {
          await init()
        }
  },
  { immediate: true }
)

const cancel = async () => {
  await http.delete('/payment/intent/delete/' + token.value)
}

const emit = defineEmits(['initiated', 'setConfirmFunction'])

const createCreditCardForm = async (clientSecret) => {
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        borderRadius: '4px',      // Adjusts the border radius
        borderColor: '#ccc',   // Sets the border color
        borderWidth: '1px',       // Sets the border width
        boxShadow: 'none',        // Removes the border shadow
        boxShadowHover: 'none',   // Removes shadow on hover
        boxShadowFocus: 'none'    // Removes shadow on focus
      }
    }
  }

  // Load Stripe and create Elements instance
  stripe.value = await stripePromise
  elements.value = stripe.value.elements(options)

  // Create Card element and mount it
  card.value = elements.value.create('payment')
  card.value.mount(cardElement.value)
}

const confirmCardPayment = async (onSuccess, onError) => {
  if (!stripe.value || !elements.value) {
    onError && onError('Stripe is not initiated')
    return;
  }

  try {
    const { error, paymentIntent } = await stripe.value.confirmPayment({
      elements: elements.value,
      redirect: 'if_required'
    });

    if (error) {
      onError && onError(error.message)
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      onSuccess && onSuccess(token.value)
    } else {
      // Handle other statuses, if needed
      onError && onError('Payment failed or requires additional action.')
    }
  } catch (e) {
    onError && onError(e.message)
  }
}

defineExpose({ confirmCardPayment, cancel })
</script>

<template>
  <div>
    <div ref="cardElement" class="card-element"></div>
    <v-skeleton-loader v-if="!token" type="image" />
  </div>
</template>

<style scoped lang="scss">

</style>