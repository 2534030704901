<script setup lang="ts">
import { ref, defineProps, defineEmits, inject, computed, onMounted } from 'vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import i18n from '@/i18n'
import currency from '@/helpers/currency'
import timeFormats from "../../../helpers/timeFormats";

const http = inject('axios')
const toast = inject('toast')

const props = defineProps({
  subscription: {
    type: Object,
    required: true
  }
})

const revokeLoading = ref(false)
const revoke = async () => {
  revokeLoading.value = true

  await http.post('/subscription/revoke', {
    id: props.subscription.id
  })
    .then(() => {
      toast.success(i18n.global.t('subscriptions.revoke.messages.success'))
      success()
      close()
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { revokeLoading.value = false })
}

const emit = defineEmits(['close', 'success'])

const close = () => {
  emit('close')
}
const success = () => {
  emit('success')
}

const amount = computed(() => {
  if (props.subscription.yearly_charged) {
    return currency.formatAmount(props.subscription.subscription.prices[0].price)
  } else {
    return 0
  }
})

const noCancellationTill = computed(() => {
  return props.subscription.no_cancellation_till
})
const noCancellationFromEpoch = computed(() => {
  return timeFormats.dateStringToEpoch(noCancellationTill.value) + 86400
})
const today = computed(() => {
  // Time from which cancelling is available
  const time = new Date().getTime() / 1000
  return Math.floor(time)
})
</script>

<template>
  <div
    class="pa-4"
  >
    <v-card-title
      class="px-0 mb-2 title"
    >
      {{ i18n.global.t('subscriptions.revoke.title') }}
    </v-card-title>

    <p
      v-if="noCancellationTill"
    >
      {{ i18n.global.t('subscriptions.revoke.cannot', { date: timeFormats.formatDate(noCancellationTill) }) }}
    </p>

    <p v-else v-html="props.subscription.can_refund ? i18n.global.t('subscriptions.revoke.refund', { payment_amount: amount }) : i18n.global.t('subscriptions.revoke.noRefund')" />

    <v-btn-group
      class="mt-6"
      divided
      variant="flat"
    >
      <v-btn
        color="secondary"
        variant="tonal"
        size="large"
        :loading="revokeLoading"
        :disabled="today < noCancellationFromEpoch"
        @click.prevent="revoke"
      >
        {{ i18n.global.t('_globals.actions.confirm') }}
      </v-btn>
      <CFormCancelButton
        :label="i18n.global.t('_globals.actions.cancel')"
        size="large"
        @cancel="close"
      />
    </v-btn-group>
  </div>
</template>

<style scoped lang="scss">
.title {
  font-size: 25px;
}

p {
  margin: 0;
}
</style>