<script setup lang="ts">
import { ref, onMounted, defineEmits, defineProps } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: 'Search for an address'
  }
})

// Define the emit type
const emit = defineEmits<{
  (e: 'place-selected', place: { address: string; coordinates: { lat: number; lng: number } }): void;
}>();

const inputRef = ref<HTMLInputElement | null>(null);

// Function to load Google Maps script
function loadGoogleMaps(apiKey: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(); // Already loaded
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Failed to load Google Maps script"));
      document.head.appendChild(script);
    }
  });
}

onMounted(async () => {
  try {
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_KEY;
    await loadGoogleMaps(apiKey);

    if (window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.value as HTMLInputElement, {
        types: ['establishment'],
        componentRestrictions: { country: 'do' }, // Dominican Republic
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry) {
          emit('place-selected', {
            address: place.formatted_address || '',
            coordinates: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
    }
  } catch (error) {
    console.error("Error loading Google Maps:", error);
  }
});
</script>

<template>
  <div>
    <v-text-field
      id="address-input"
      variant="outlined"
      :placeholder="props.label"
      ref="inputRef"
      hide-details
    />
  </div>
</template>

<style scoped lang="scss">

</style>