<script setup lang="ts">
import { ref, inject, onMounted, defineEmits } from 'vue'
import i18n from '@/i18n'

const http = inject('axios')
const toast = inject('toast')

onMounted(async () => {
  await fetchInvitations()
})

const loading = ref(false)
const invitations = ref([])

const headers = [
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.name.label'),
    key: 'name'
  },
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.email.label'),
    key: 'email'
  },
  {
    title: i18n.global.t('settings.affiliates.podcast.table.headers.status.label'),
    key: 'status',
    align: 'start'
  }
]

const earningsTotal = () => {
  const results = invitations.value
  let total = 0
  const per = 25

  for (const i in results) {
    if (results[i].status === 'show') {
      total += per
    }
  }

  emit('onLoaded', total)
}

const fetchInvitations = async () => {
  loading.value = true

  await http.get('/podcast')
    .then(response => {
      invitations.value = response.data.data
      earningsTotal()
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
    .finally(() => { loading.value = false })
}

const emit = defineEmits(['onLoaded'])
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="invitations"
  >
    <template v-slot:no-data>
      {{ i18n.global.t('settings.affiliates.podcast.table.noData') }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div
        v-if="item.props.title.status === 'pending'"
      >
        <v-icon
          class="mr-1"
          size="small"
        >
          mdi-calendar-clock-outline
        </v-icon>
        <span>{{ i18n.global.t('settings.affiliates.podcast.table.statuses.' + item.props.title.status) }}</span>
      </div>
      <div
        v-else-if="item.props.title.status === 'booked'"
      >
        <v-icon
          color="info"
          class="mr-1"
          size="small"
        >
          mdi-calendar-outline
        </v-icon>
        <span>{{ i18n.global.t('settings.affiliates.podcast.table.statuses.' + item.props.title.status) }}</span>
      </div>
      <div
        v-else-if="item.props.title.status === 'show'"
      >
        <v-icon
          color="success"
          class="mr-1"
          size="small"
        >
          mdi-calendar-check
        </v-icon>
        <span>{{ i18n.global.t('settings.affiliates.podcast.table.statuses.' + item.props.title.status) }}</span>
      </div>
      <div
        v-else-if="item.props.title.status === 'dropped'"
      >
        <v-icon
          color="#999"
          class="mr-1"
          size="small"
        >
          mdi-calendar-remove-outline
        </v-icon>
        <span>{{ i18n.global.t('settings.affiliates.podcast.table.statuses.' + item.props.title.status) }}</span>
      </div>
    </template>
  </v-data-table>
</template>

<style scoped lang="scss">

</style>