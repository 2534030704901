<template>
  <v-form
    v-model="valid"
    class="pa-5"
    ref="subscribeForm"
  >
    <CTextField
      v-model="formValues.name"
      class="pb-2"
      :label="$t('subscribeForm.name.label')"
      :rules="rules.name"
      required
    />
    <CTextField
      v-model="formValues.email"
      class="pb-2"
      :label="$t('subscribeForm.email.label')"
      :rules="rules.email"
      required
      :value="$route.query.email ?? formValues.email"
    />
    <CTextFieldPassword
      v-model="formValues.password"
      class="pb-2"
      :label="$t('subscribeForm.password.label')"
      :rules="rules.password"
      required
    />
    <CSelectField
      v-model="formValues.gender"
      ref="gender"
      :items="options.gender"
      :label="$t('subscribeForm.gender.label')"
      :rules="rules.gender"
      required
    />
    <CCheckboxConsent
      class="pb-5"
      :label="$t('subscribeForm.consent.label', { terms_url: '/legal/terms', privacy_url: '/legal/privacy' })"
      :rules="rules.consent"
      @onCheck="(v) => updateFormData('consent', v)"
    />
    <RecaptchaBox
      class="pb-9"
      @verify="recaptcha"
    />
    <v-btn-group
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :enabled="!!(valid && formValues.consent && formValues.recaptcha)"
        :label="$t('subscribeForm.actions.subscribe')"
        :loading="loading"
        @click.prevent="submit"
      />
      <CFormCancelButton
        v-if="!isInvitation"
        :label="$t('subscribeForm.actions.cancel')"
        @cancel="$store.commit('authSubscribeShow', false)"
      />
    </v-btn-group>

    <a
      v-if="!isInvitation"
      class="d-block mt-6"
      href="javascript:void(0)"
      @click.prevent="switchToSignIn"
    >
      {{ $t('subscribeForm.actions.login') }}
    </a>

  </v-form>
</template>

<script>
import CTextField from '@/components/fields/CTextField.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CCheckboxConsent from '@/components/fields/CCheckboxConsent.vue'
import RecaptchaBox from '@/components/RecaptchaBox.vue'
import CTextFieldPassword from '@/components/fields/CTextFieldPassword.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'
import CSelectField from '@/components/fields/CSelectField.vue'

export default {
  name: 'AuthRegister',
  components: { CSelectField, CFormCancelButton, CTextFieldPassword, RecaptchaBox, CCheckboxConsent, CButtonFormSubmit, CTextField },
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    isInvitation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      loading: false,
      formValues: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: null,
        consent: false,
        recaptcha: null,
        token: null,
        affiliateToken: null
      },
      rules: {
        name: [
          (v) => !!v || this.$t('subscribeForm.name.rules.mandatory')
        ],
        email: [
          (v) => !!v || this.$t('subscribeForm.email.rules.mandatory'),
          (v) => /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('subscribeForm.email.rules.valid')
        ],
        password: [
          (v) => !!v || this.$t('subscribeForm.password.rules.mandatory'),
        ],
        confirmPassword: [
          (v) => !!v || this.$t('subscribeForm.confirmPassword.rules.noMatch'),
        ],
        gender: [
          (v) => (!!v && v !== 'n') || this.$t('subscribeForm.gender.rules.noMatch')
        ],
        consent: [
          (v) => !!v || ''
        ]
      },
      options: {
        gender: [
          {
            text: this.$t('subscribeForm.gender.options.male'),
            value: "m"
          },
          {
            text: this.$t('subscribeForm.gender.options.female'),
            value: "f"
          }
        ]
      }
    }
  },
  computed: {
    matchPasswords () {
      return this.formValues.password === this.formValues.confirmPassword
    }
  },
  mounted () {
    const at = JSON.parse(localStorage.getItem('discount'))

    if (this.isInvitation) {
      this.formValues.email = this.$route.query.email
      this.formValues.token = this.$route.query.token
    }
    if (at) {
      this.formValues.affiliateToken = at.token
    }
  },
  methods: {
    switchToSignIn () {
      this.$store.commit('authSubscribeShow', false)
      setTimeout(() => {
        this.$store.commit('authSignInShow', true)
      }, 200)
    },
    updateFormData (field, value) {
      if (field === 'gender' && value === 'n') {
        this.$toast.error(this.$t('subscribeForm.gender.noNonBinary'))
      } else {
        this.formValues[field] = value
      }
    },
    recaptcha(value) {
      this.formValues.recaptcha = value
    },
    async submit () {
      this.loading = true

      await this.$http.post('/auth/subscribe', this.formValues)
        .then(response => {
          localStorage.removeItem('discount')

          this.$toast.success(this.$t('subscribeForm.messages.success'))

          this.onSuccess({ email: response.data.data.email, password: this.formValues.password })
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

a {
  font-size: $text-small;
}
</style>