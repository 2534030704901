<template>
  <v-dialog
    v-model="showDialog"
    class="pa-0 ma-0 align-center justify-center"
    max-width="500"
    persistent
  >
    <v-card
      class="content"
    >
      <div
        class="d-flex justify-space-between align-center"
      >
        <v-card-title
          v-if="title"
          class="pt-6"
        >
          {{ title }}
        </v-card-title>

        <v-btn
          v-if="closeButton"
          class="mr-3"
          icon
          variant="text"
          size="small"
          @click="close"
        >
          <v-icon
            small
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <slot name="component" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CDialogFrame',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    closeButton: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      imagesUrl: process.env.VUE_APP_IMAGES_URL
    }
  },
  computed: {
    showDialog: {
      set: function (value) {
        this.$emit('toggleDisplay', value)
      },
      get: function () {
        return this.show
      }
    }
  },
  methods: {
    close () {
      this.$emit('toggleDisplay', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/breakpoints.scss';
@import '@/style/colors.scss';

.v-dialog {

  .v-card-title {
    font-size: 26px;
  }

  @media (max-width: $breakpoint-xs-down) {
    width: 100%;
    max-width: 100%;

    .v-card {
      border-radius: 0 !important;
    }

    :deep(.v-overlay__content) {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }

    .content {
      margin: 0;
      height: 100vh;
      justify-content: start;
    }
  }

  .v-card-actions {
    justify-content: end;
  }

  &:deep(.v-overlay__scrim) {
    background-color: $black !important;
    opacity: 90%;
  }
}
</style>