<script setup lang="ts">
import { computed } from 'vue'
import Store from '@/store'
import CloseButton from '@/components/buttons/CloseButton.vue'
import i18n from "@/i18n";
import currency from "@/helpers/currency";
import router from '@/router'
import CartItemsList from "@/components/cart/CartItemsList.vue";

const items = computed(() => {
  return Store.getters.cartItems
})

const subTotal = computed(() => {
  return Store.getters.cartSubTotal
})

const goToCheckoutPage = () => {
  Store.commit('cartShow', false)
  router.push({ name: 'CheckoutPageView' })
}
</script>

<template>
  <div>
    <div
      class="d-flex justify-space-between align-center"
    >
      <v-card-title
        class="py-4"
      >
        <v-icon
          size="small"
        >
          mdi-cart
        </v-icon>
        {{ i18n.global.t('cart.title') }}
      </v-card-title>
      <CloseButton
        class="mr-1"
        size="small"
        @click.prevent="Store.commit('cartShow', false)"
      />
    </div>

    <v-divider/>

    <div
      class="cart-body"
    >
      <CartItemsList/>
    </div>

    <div
      class="cart-bottom pa-4"
    >
      <div
        class="subtotal"
      >
        <span>{{ i18n.global.t('cart.bottom.subTotal') }}</span>
        <span class="font-weight-bold">{{ currency.formatAmount(subTotal) }}</span>
      </div>
      <div
        class="d-flex justify-end"
      >
        <v-btn
          class="mt-3"
          flat
          link
          color="secondary"
          variant="tonal"
          @click.prevent="goToCheckoutPage"
        >
          {{ i18n.global.t('cart.bottom.actions.checkout') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/style/breakpoints.scss";
@import "@/style/colors.scss";

.cart-body {
  overflow-y: auto;
  max-height: 50vh;
  padding-bottom: 12px;

  @media (max-width: $breakpoint-xs-down) {
    overflow-y: inherit;
    max-height: inherit;
  }
}

.cart-bottom {
  border-top: 1px solid #d3d3d3;

  .subtotal {
    display: flex;
    justify-content: space-between;
  }
}
</style>