<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, watch } from 'vue'
import CDialogFrame from "@/components/dialogs/CDialogFrame.vue";
import i18n from '@/i18n'

const props = defineProps({
  label: {
    type: String,
    default: i18n.global.t('datePicker.clickHere')
  }
})

const show = ref(false)
const date = ref(null)

const update = () => {
  show.value = false
}

watch(() => date.value, (value) => {
  const newDate = new Date(value).toISOString().slice(0, 10)
  emit('update', newDate)
})

const dateFormatted = computed(() => {
  return date.value ? new Date(date.value).toISOString().slice(0, 10) : null
})

const getLocale = computed(() => {
  return 'en'
})

const emit = defineEmits(['update', 'clear'])
</script>

<template>
  <div>
    <v-text-field
      v-model="dateFormatted"
      variant="outlined"
      :placeholder="props.label"
      :readonly="true"
      hide-details
      @click="show = true"
    />

    <CDialogFrame
      :show="show"
      @toggle="show = false"
    >
      <template v-slot:component>
        <v-locale-provider
          :locale="getLocale"
        >
          <v-date-picker
            v-model="date"
            class="d-picker"
            :input-placeholder="i18n.global.t('datePicker.inputPlaceholder')"
            input-mode="calendar"
            :landscape="true"
            :title="label"
            @click:cancel="show = false"
            @click:save="update"
          />
        </v-locale-provider>
      </template>
    </CDialogFrame>
  </div>
</template>

<style scoped lang="scss">
:deep(.d-picker) {
  width: 100% !important;
}
</style>