<script setup lang="ts">
import router from '@/router'
import i18n from '@/i18n'
const imagesUrl = process.env.VUE_APP_IMAGES_URL
const email = process.env.VUE_APP_CONTACT_EMAIL
const phone = process.env.VUE_APP_CONTACT_PHONE
</script>

<template>
  <v-footer>
    <v-container
      class="wrapper py-7 px-0"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column py-5 py-md-3"
        >
          <h3 class="title mb-3">
            Sunset Discounts
          </h3>
          <img class="d-none" :src="imagesUrl + '/lb-logo-bw.png'" />
          <span class="contact"><a :href="'mailto:' + email">{{ email }}</a></span>
          <span class="contact"><a :href="'tel:' + phone">{{ phone }}</a></span>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-5 py-md-3"
        >
          <h3 class="title">{{ i18n.global.t('footer.sitemap.title') }}</h3>
          <ul>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'ProductListPage', params: { destination: 'punta-cana' } })">{{ i18n.global.t('footer.sitemap.puntaCana') }}</a></li>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'ProductListPage', params: { destination: 'las-terrenas' } })">{{ i18n.global.t('footer.sitemap.lasTerrenas') }}</a></li>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'ProductListPage', params: { destination: 'santo-domingo' } })">{{ i18n.global.t('footer.sitemap.santoDomingo') }}</a></li>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'AffiliateRegisterPage' })">{{ i18n.global.t('footer.sitemap.affiliate') }}</a></li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-5 py-md-3"
        >
          <h3 class="title">{{ i18n.global.t('footer.legal.title') }}</h3>
          <ul>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'TermsPage' })">{{ i18n.global.t('footer.legal.terms') }}</a></li>
            <li><a href="javascript:void(0)" @click.prevent="router.push({ name: 'PrivacyPolicyPage' })">{{ i18n.global.t('footer.legal.privacy') }}</a></li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          class="mt-5"
        >
          <div class="copyright" v-html="i18n.global.t('footer.copyright', { year: new Date().getFullYear(), url: 'https://consultoj.com' })" />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

.v-footer {
  background-color: #1c1c1c;
  color: $color-white;

  :deep(a,
  a:link,
  a:active,
  a:visited) {
    color: #f1fffe !important;
    text-decoration: none;

    &:hover {
      color: $color-link-hover;
    }
  }

  img {
    max-width: 50px;
    margin-bottom: 15px;
  }

  .title {
    color: $color-white;
    font-size: 22px;
    font-weight: 500;
  }

  .contact {
    margin: 3px 0;
    color: $color-white;
  }

  .disabled {
    color: $grey-02;
  }

  .copyright {
    font-size: 12px;
  }

  ul {
    margin-top: 15px;

     li {
      list-style: none;
       margin: 3px 0;
     }
  }
}
</style>