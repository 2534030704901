<template>
  <CDialogFrame
    :show="show"
    :title="$t('subscribeForm.title')"
    @toggleDisplay="toggleDialog"
  >
    <template v-slot:component>
      <AuthSubscribe/>
    </template>
  </CDialogFrame>
</template>

<script>
import CDialogFrame from '@/components/dialogs/CDialogFrame.vue'
import AuthSubscribe from '@/components/auth/AuthSubscribeForm.vue'

export default {
  name: 'AuthRegisterDialog',
  components: { AuthSubscribe, CDialogFrame },
  computed: {
    show () {
      return this.$store.getters.authSubscribeShow
    }
  },
  methods: {
    toggleDialog (value) {
      this.$store.commit('authSubscribeShow', value)
    }
  }
}
</script>

<style scoped>

</style>