<script setup lang="ts">
import { computed, onMounted } from 'vue'

onMounted(() => {
  window.scrollTo(0, 0)
})

const email = process.env.VUE_APP_CONTACT_EMAIL

const lang = computed(() => {
  return 'fr'
})
</script>

<template>
  <v-container
    class="wrapper"
  >
    <div
      v-if="lang === 'fr'"
    >
      <h1>Politique de Confidentialité de Sunset Discounts</h1>

      <p>La protection de votre vie privée est importante pour nous. Cette <strong>Politique de Confidentialité</strong> explique comment Sunset Discounts ("nous," "notre" ou "nos") collecte, utilise et partage vos informations personnelles lorsque vous utilisez notre application de coaching (l'"Application"). Veuillez lire cette Politique de Confidentialité attentivement.</p>

      <h2>1. Informations que nous collectons</h2>

      <p>Nous pouvons collecter les types d'informations suivants lorsque vous utilisez l'Application :</p>
      <ul>
        <li>Informations Personnelles : Votre nom, adresse e-mail et d'autres coordonnées que vous fournissez.</li>
        <li>Informations d'Utilisation : Des informations sur la manière dont vous utilisez l'Application, y compris vos interactions avec le contenu et les fonctionnalités.</li>
        <li>Informations sur l'Appareil : Des informations sur votre appareil, telles que le type, le modèle et le système d'exploitation.</li>
        <li>Les informations relatives aux cartes de crédit et aux comptes bancaires sont collectés par notre processeur de paiement.</li>
      </ul>

      <h2>2. Comment nous utilisons vos informations</h2>

      <p>Nous pouvons utiliser vos informations aux fins suivantes :</p>
      <ul>
        <li>Fournir et améliorer les fonctionnalités et le contenu de l'Application.</li>
        <li>Répondre à vos demandes et fournir un support client.</li>
        <li>Vous envoyer des mises à jour, des promotions et du matériel marketing, si vous avez donné votre accord.</li>
      </ul>

      <h2>3. Partage des informations</h2>

      <p>Nous pouvons partager vos informations avec :</p>
      <ul>
        <li>Des prestataires de services tiers qui nous aident à fournir les services de l'Application.</li>
        <li>Les autorités légales lorsque la loi l'exige ou pour protéger nos droits.</li>
      </ul>

      <h2>4. Vos choix</h2>

      <p>Vous avez des choix concernant vos informations personnelles :</p>
      <ul>
        <li>Vous pouvez accéder, corriger ou supprimer vos informations personnelles en nous contactant.</li>
        <li>Vous pouvez refuser de recevoir du matériel marketing en suivant les instructions fournies.</li>
      </ul>

      <h2>5. Sécurité</h2>

      <p>Nous prenons des mesures pour protéger vos informations personnelles, mais aucune méthode de transmission sur Internet n'est totalement sécurisée. Nous ne pouvons garantir la sécurité de vos données.</p>

      <h2>6. Modifications de cette Politique de Confidentialité</h2>

      <p>Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre. La dernière version sera publiée sur l'Application, et votre utilisation continue de l'Application constitue votre acceptation de la Politique de Confidentialité mise à jour.</p>

      <h2>7. Contactez-nous</h2>

      <p>Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité, veuillez nous contacter à <a :href="'mailto:' + email">{{ email }}</a>.</p>
    </div>
    <div
      v-else
    >
      <h1>Privacy Policy for Sunset Discounts</h1>

      <p>Protecting your privacy is important to us. This <strong>Privacy Policy</strong> explains how Sunset Discounts ("we," "us," or "our") collects, uses, and shares your personal information when you use our coaching app (the "App"). Please read this Privacy Policy carefully.</p>

      <h2>1. Information We Collect</h2>

      <p>We may collect the following types of information when you use the App:</p>
      <ul>
        <li>Personal Information: Your name, email address, and other contact information you provide.</li>
        <li>Usage Information: Information about how you use the App, including your interactions with the content and features.</li>
        <li>Device Information: Information about your device, such as the type, model, and operating system.</li>
        <li>Credit card and bank account information is collected by our payment processor.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>

      <p>We may use your information for the following purposes:</p>
      <ul>
        <li>Providing and improving the App's features and content.</li>
        <li>Responding to your inquiries and providing customer support.</li>
        <li>Sending you updates, promotions, and marketing materials, if you have opted in.</li>
      </ul>

      <h2>3. Information Sharing</h2>

      <p>We may share your information with:</p>
      <ul>
        <li>Third-party service providers who assist us in delivering the App's services.</li>
        <li>Legal authorities when required by law or to protect our rights.</li>
      </ul>

      <h2>4. Your Choices</h2>

      <p>You have choices regarding your personal information:</p>
      <ul>
        <li>You can access, correct, or delete your personal information by contacting us.</li>
        <li>You can opt out of receiving marketing materials by following the provided instructions.</li>
      </ul>

      <h2>5. Security</h2>

      <p>We take steps to protect your personal information, but no method of transmission over the internet is completely secure. We cannot guarantee the security of your data.</p>

      <h2>6. Changes to This Privacy Policy</h2>

      <p>We may update this Privacy Policy from time to time. The latest version will be posted on the App, and your continued use of the App constitutes your acceptance of the updated Privacy Policy.</p>

      <h2>7. Contact Us</h2>

      <p>If you have questions or concerns about this Privacy Policy, please contact us at <a :href="'mailto:' + email">{{ email }}</a>.</p>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
@import "@/style/colors.scss";

h1, h2, h3 {
  padding-bottom: 15px;
}

h3 {
  font-size: 22px;
  margin: 0;
}

p, em, li {
  color: $lighter-text;
}

ul {
  padding-left: 30px;
  padding-bottom: 15px;
}

ol {
  padding-left: 15px;
  padding-bottom: 15px;
}

li {
  padding-bottom: 2px;
}
</style>