<script setup lang="ts">
import { computed, onMounted } from 'vue'
import Store from "@/store";
import i18n from "@/i18n";

onMounted(() => {
  Store.dispatch('buildCartFromLocalStorage')
})

const numberOfItems = computed(() => {
   return Store.getters.cartItems.length
})

const showCart = () => {
  return !!numberOfItems.value
}
</script>

<template>
  <v-badge
    v-if="numberOfItems"
    :content="numberOfItems"
    color="secondary"
    overlap
    offset-x="5"
    offset-y="5"
  >
    <v-btn
      class="mr-2"
      color="grey-darken-1"
      variant="tonal"
      icon
      @click.prevent="Store.commit('cartShow', true)"
      :title="i18n.global.t('topNavigationBar.cart')"
    >
      <v-icon>
        mdi-cart
      </v-icon>
    </v-btn>
  </v-badge>
  <v-btn
    v-else
    class="mr-2"
    color="grey-darken-1"
    icon
    @click.prevent="showCart"
    :title="i18n.global.t('topNavigationBar.cart')"
  >
    <v-icon>
      mdi-cart
    </v-icon>
  </v-btn>
</template>

<style scoped lang="scss">

</style>