<template>
  <v-select
    :items="items"
    item-title="text"
    item-value="value"
    variant="outlined"
    :required="required"
    @update:modelValue="parseValue"
  >
    <template #label>
      {{ label }}&nbsp;<span v-if="required" class="red--text"><strong>*</strong></span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CSelectField',
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      value: []
    }
  },
  methods: {
    parseValue (v) {
      this.$emit('onSelect', v)
    }
  }
}
</script>

<style scoped>

</style>