<template>
  <v-form
    class="pa-5"
  >
    <RecaptchaBox
      class="pb-10"
      @verify="recaptcha"
    />

    <v-btn-group
      divided
      variant="flat"
    >
      <CButtonFormSubmit
        :label="$t('settings.userProfile.resendEmailForm.actions.submit')"
        :enabled="!!values.recaptcha"
        :loading="loading"
        @click.prevent="submit"
      />
      <CFormCancelButton
        :label="$t('settings.userProfile.resendEmailForm.actions.cancel')"
        @cancel="$emit('closeModal')"
      />
    </v-btn-group>
  </v-form>
</template>

<script>
import RecaptchaBox from '@/components/RecaptchaBox.vue'
import CButtonFormSubmit from '@/components/buttons/CButtonFormSubmit.vue'
import CFormCancelButton from '@/components/buttons/CFormCancelButton.vue'

export default {
  name: 'ResendVerificationEmailForm',
  components: { CFormCancelButton, CButtonFormSubmit, RecaptchaBox },
  data () {
    return {
      loading: false,
      values: {
        recaptcha: null
      }
    }
  },
  methods: {
    recaptcha (value) {
      this.values.recaptcha = value
    },
    async submit () {
      this.loading = true

      this.$http.post('/user/set-verification', this.values)
          .then(() => {
            this.$toast.info(this.$t('settings.userProfile.resendEmailForm.messages.success'))
            this.$emit('closeModal')
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>