<template>
  <div>
    <v-btn
      :loading="loading"
      :disabled="disabled"
      @click.prevent="upload"
    >
      <v-icon class="mr-2" left>
        mdi-cloud-upload
      </v-icon>
      {{ $t('imageUpload.cta') }}
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="imageUploaded"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  props: {
    path: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    old_file: {
      type: String,
      required: false,
    },
    thumbnailMaxDimension: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      file: undefined,
      loading: false
    }
  },
  methods: {
    upload() {
      this.$refs.uploader.click()
    },
    imageUploaded(e) {
      this.file = e.target.files[0]
      this.loading = true

      if(this.file) {
        if(this.file.type !== 'image/jpeg' && this.file.type !== 'image/jpg' && this.file.type !== 'image/png') {
          this.loading = false
          this.file = undefined
          this.$toast('<i class="fas fa-exclamation-triangle mr-3"></i>' + this.text.file.wrongFormat, {
            color: 'red accent-3',
          })
        } else if(this.file.size > 10000000) {
          this.loading = false
          this.$toast('<i class="fas fa-exclamation-triangle mr-3"></i>' + this.text.file.tooHeavy, {
            color: 'red accent-3',
          })
        } else {
          const fd = new FormData()

          this.$emit('loadingState', true)

          fd.append('image', this.file, this.file.name)
          fd.append('path', this.path)
          fd.append('prefix', this.prefix)

          // Sets the un required
          if(this.old_file) {
            fd.append('old_file', this.old_file)
          }
          if(this.thumbnailMaxDimension) {
            fd.append('thumbnailMaxDimension', this.thumbnailMaxDimension)
          }

          this.$http.post('/image/upload', fd)
              .then(response => {
                let filePath = response.data

                this.loading = false
                this.$emit('loadingState', false)
                this.$emit('update', filePath)
              })
              .catch(error => {
                this.loading = false
                this.$emit('loadingState', false)
                this.$emit('update', null)
                this.$toast.error(error.response.data.message)
              })
        }
      } else {
        this.$emit('update', null)
      }
    }
  }
}
</script>

<style scoped>

</style>