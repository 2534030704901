const roles: any = {
  viewer: 1,
  affiliate: 2,
  expert: 3,
  moderator: 4,
  administrator: 5,
  super: 6
}

export default {

  hasAccess (userRoleId: number, featureRole: string) {
    return userRoleId >= roles[featureRole];
  },

  roleKeyFromValue (roleId: number) {
    for (const key in roles) {
      if (roles[key] === roleId) {
        return key
      }
    }
  }
}