<script setup>
import { ref, inject, onMounted, defineExpose } from 'vue'
import roles from '@/helpers/roles'
const http = inject('axios')
const toast = inject('toast')

onMounted(() => {
  fetchInvites()
})

let loading = ref(false)
let data = ref([])

const fetchInvites = async () => {
  data.value = []
  loading.value = true

  await http.get('/user-invite/all')
      .then(response => {
        data.value = response.data.data ?? null
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(() => { loading.value = false })
}

defineExpose({ fetchInvites })
</script>

<template>
  <v-container
    class="px-0"
  >
    <h2>{{ $t('settings.userAccounts.pendingTokenList.title') }}</h2>

    <v-table
      :fixed-header="true"
    >
      <thead>
      <tr>
        <th class="text-left">
          {{ $t('settings.userAccounts.pendingTokenList.email') }}
        </th>
        <th class="text-left">
          {{ $t('settings.userAccounts.pendingTokenList.role') }}
        </th>
        <th class="text-left">
          {{ $t('settings.userAccounts.pendingTokenList.created_at') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-if="loading"
      >
        <td colspan="3" class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </td>
      </tr>
      <tr
        v-for="(item, i) in data"
        :key="'invite-' + i"
      >
        <td>
          {{ item.email }}
        </td>
        <td>
          {{ roles.roleKeyFromValue(item.role_id).toUpperCase() }}
        </td>
        <td>
          {{ item.created_at }}
        </td>
      </tr>
        <tr
          v-if="!data.length && !loading"
        >
          <td colspan="3">
            {{ $t('settings.userAccounts.pendingTokenList.noData') }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<style scoped>

</style>