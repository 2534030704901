<script setup>
import AuthRegister from '@/components/auth/AuthSubscribeForm.vue'
import { useRoute } from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'
import { inject } from 'vue'
import router from "@/router";

const toast = inject('toast')

const route = useRoute()
const imagesUrl = process.env.VUE_APP_IMAGES_URL

const success = ({ email, password }) => {
  Store.dispatch('retrieveToken', {
    email, password
  })
    .then(() => {
      router.push({ name: 'RegisterBankView' })
    })
    .catch(error => {
      toast.error(error.response.data.message)
    })
}
</script>

<template>
  <v-container
    class="wrp"
    fluid
  >
    <v-row>
      <v-col
        class="img"
        v-bind:style="{ 'background-image': 'url(' + imagesUrl + '/register-bg.jpg)' }"
        cols="12"
        sm="4"
        md="6"
      />
      <v-col
        class="form"
        cols="12"
        sm="8"
        md="6"
      >
        <div
          class="form-container"
        >
          <h1 class="mx-5">{{ i18n.global.t('register.title') }}</h1>
          <p class="mx-5 mb-3">{{ i18n.global.t('register.details', { percent: 10 }) }}</p>
          <AuthRegister
            :on-success="success"
            :is-invitation="!!(route.query.email && route.query.token)"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
@import '@/style/breakpoints';

.wrp {
  height: 100%;
  padding: 0;

  .v-row {
    padding: 0;
    margin: 0;
    height: 100%;

    .form {
      display: flex;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;

      .form-container {
        max-width: 600px;
      }

      @media (min-width: $breakpoint-sm-up) {
        align-items: center;
      }
    }

    .img {
      padding: 0;
      margin: 0;
      background-color: #d5d5d5;
      background-size: cover;
      background-position: center;
    }
  }
}
</style>