<script setup lang="ts">
import { ref, defineProps, inject, onMounted } from "vue";
import ProductCard from "@/modules/products/components/ProductCard.vue";
import router from '@/router'

const http = inject('axios')

const props = defineProps({
  rowsCol: {
    type: Number,
    default: 12
  },
  rowsSm: {
    type: Number,
    default: 6
  },
  rowsMd: {
    type: Number,
    default: 6
  },
})

onMounted(async () => {
  await fetchList()
})

const fetchList = async () => {
  await http.get('/product?destination=' + router.currentRoute.value.params.destination)
    .then(response => {
      products.value = response.data.data
    })
}

const products = ref(null)
</script>

<template>
  <v-row
    v-if="products"
  >
    <v-col
      v-for="(product, i) in products"
      :key="i"
      :cols="props.rowsCol"
      :sm="props.rowsSm"
      :md="props.rowsMd"
    >
      <ProductCard :item="product" />
    </v-col>
  </v-row>
  <v-row
    v-else
  >
    <v-col
      v-for="i in 4"
      :key="i"
      :cols="props.rowsCol"
      :sm="props.rowsSm"
      :md="props.rowsMd"
    >
      <v-skeleton-loader
        type="image"
      />
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.v-skeleton-loader {
  aspect-ratio: 1 / 1;
}

:deep(.v-skeleton-loader__bone, .v-skeleton-loader__image) {
  height: 100%;
}
</style>